<div class="message_container" [ngSwitch]="messageType">
  <ng-container  *ngSwitchCase="MessageType.notFound">
      <img src="../../../assets/images/notFound.svg">
      <p class="message">
        {{ 'alerts.notFound.text' | translate }}
      </p>
  </ng-container>

  <ng-container class="alert_message" *ngSwitchCase="MessageType.httpError">
    <div class="api_error_handler">
      <mat-icon class="api_error_icon">error_outline</mat-icon>
      <p class="api_error_text">
        {{ 'alerts.apiError.text' | translate }}
      </p>
      <button-text (click)="onRetryClick()" [isLoading]="isProcessing" [disabled]="isProcessing"
        class="api_error_button" mat-button color="primary">
        {{ 'shared.actions.tryAgain.label' | translate }}
      </button-text>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="MessageType.noDatasheetFound">
      <img class="img_home" src="../../../assets/images/home/ilustração-s-editados-recentemente.svg">
      <span class="message">
        {{ 'alerts.noDatasheetFound.text' | translate }}
      </span>
  </ng-container>

  <ng-container *ngSwitchCase="MessageType.copyMoveNotFound">
    <img src="../../../assets/images/copyMove404.svg">
      <p class="message">
        {{ 'alerts.copyOrMoveNotFound.text' | translate }}
      </p>
  </ng-container>

  <ng-container  *ngSwitchCase="MessageType.noIsaItems">
    <img src="../../../assets/images/notFound.svg">
    <p class="message">
      {{ 'alerts.noIsaItem.text' | translate }}
    </p>
</ng-container>


  <ng-container *ngSwitchDefault>
    {{ 'alerts.unknownMessage.text' | translate }}
  </ng-container>
</div>
