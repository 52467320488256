import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { IsaMetadata } from 'src/app/models/isaMetadata';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { IsaService } from 'src/app/services/isa.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { MessageType } from 'src/app/models/enums/message-type';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';

@Component({
  selector: 'app-isa-collections-dialog',
  templateUrl: './isa-collections-dialog.component.html',
  styleUrls: ['./isa-collections-dialog.component.scss']
})
export class IsaCollectionsDialogComponent implements OnInit {

  public isacollection: any[] = [];
  public isaShapes: any[] = [];
  public actualPage:number = 1;
  public isLoading: boolean = false;
  MessageType = MessageType;
  public totalItems: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _datasheetService : DatasheetService,
    private _dialogRef: MatDialogRef<IsaCollectionsDialogComponent>,
    private _translate: TranslateService,
    private _localStorageService: LocalStorageService,
    private _isaService: IsaService,
    private _snack: SnackbarService,
    private _confirmationService: ConfirmationService,
  ) {

  }
  ngOnInit(): void {
    this.isaIntegration();
  }


  getIsaItemName(item: any) {
    return this._isaService.getIsaItemName( item );
  }

  onAssociate(sheet:any) {
    console.log(sheet);
  }

  getShape( id : string) {
    const shape = this.isaShapes.find( shape => shape.id === id )
    if( shape ) {
      return shape.coverReference;
    }
    return '';
  }

  onAssociateClick( item: any, kanbam: string,  collection: any ) {

    const currentCompany = JSON.parse(
      this._localStorageService.get(LocalStorageKeys.USER_CURRENT_COMPANY)
    );
    const companyid = currentCompany.id;
    const companyName = currentCompany.name;

    let images = this._isaService.handleImages(item);

    const isaVariants: { id: string; name: string }[] = item.variants || [];
    
    const isaMeta : IsaMetadata = {
      username: item.assignee?item.assignee.name : "",
      modelId: parseInt(item.id),
      kanbam,
      companyid,
      companyName,
      collection: collection.name,
      modelName: this.getIsaItemName(item),
      season: collection.season,
      description: item.description,
      shape: this.getShape(item.shapeId),
      images,
      variationNames: isaVariants.map(itm => itm['name'])
    }

    this._datasheetService.setIsaMeta(isaMeta);
    this._dialogRef.close();
  }

  onImageError(event: any) {
    event.target.src = '/assets/images/homeImgPlaceholder.svg';
  }

  setSelectedStep( steps: any[] ) {
    return steps[0];
  }

  isaIntegration() {
    this.isLoading = true;
    let offset = this.actualPage -1;
    this._isaService.getIsaAllCollections(this._localStorageService.getAuthorizationToken(), offset).subscribe(
      (res) => {
        console.log(res);
        this.totalItems = res.data.paginatedCollections.pageCount;
        this._isaService.getIsaShapes(this._localStorageService.getAuthorizationToken()).subscribe(
          (reshape) => {
            console.log('xapes', reshape.data.shapes);
            this._isaService.setIsaShapes(reshape.data.shapes);
            this.isacollection = res.data.paginatedCollections.collections;
            this.isaShapes =  reshape.data.shapes;
            this.isLoading = false
        },
        (error) => {
          this._snack.failMessage(this._translate.instant('propertyEditorPage.isaShapeError'));
          console.error(error.error.errors[0].message);
          this._isaService.notifyIsacollection([]);
          }
        );

      this._isaService.notifyIsacollection(res.data.paginatedCollections.collections);
    },
    (error) => {
      this._snack.failMessage(this._translate.instant('propertyEditorPage.IsaCollectionError'));
      console.error(error.error.errors[0].message);
      this._isaService.notifyIsacollection([]);
      }
    );

  }

  onPageChange(page: number) {
    this.isacollection =[];
    this.isLoading = true;
    this.actualPage = page;
    this.isaIntegration()
  }

}
