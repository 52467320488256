import { Field } from "./field";
import { Image } from "./image";

export interface Erp {
  external_id: string,
  type?: 'finished_product'|'raw_material'|'activity'|'group'|'measure',
}

export interface Reference {
  uid: string,
  variation_name?: string,
  id_idea: string,
  local: boolean
  fields: Field[],
  uri?: string,
  images?: Image[],
  variant?: string,
  erp?: Erp
}

export interface DefaultReference {
  material : Reference,
  activity : Reference,
  measure : Reference,
  generic : Reference,
}

export const defaultMaterial: Reference = {
  uid: '',
  fields: [
    {
      name: "code",
      value: "",
      read_only: false
    },
    {
      name: "amount",
      value: 0,
      read_only: false
    },
    {
      name: "color",
      value: "",
      read_only: false
    },
    {
      name: "cost",
      value: 0.0,
      read_only: false
    },
    {
      name: "desc",
      value: "",
      read_only: false
    },
    {
      name: "group",
      value: "",
      read_only: false
    },
    {
      name: "obs",
      value: "",
      read_only: false
    },
    {
      name: "supplier",
      value: "",
      read_only: false
    },
    {
      name: "total",
      value: 0.0,
      read_only: true,
      formula: "{.Custo Unitário} * {.Qtd}"
    },
    {
      name: "um",
      value: "",
      read_only: false
    }
  ],
  id_idea: "FW",
  local: true
}

export const defaultActivity: Reference = {
  uid: '',
  fields: [
    {
      name: "code",
      value: "",
      read_only: false
    },
    {
      name: "amount",
      value: 0,
      read_only: false
    },
    {
      name: "cost",
      value: 0.0,
      read_only: false
    },
    {
      name: "desc",
      value: "",
      read_only: false
    },
    {
      name: "machine",
      value: "",
      read_only: false
    },
    {
      name: "obs",
      value: "",
      read_only: false
    },
    {
      name: "sector",
      value: "",
      read_only: false
    },
    {
      name: "total",
      value: 0.0,
      read_only: true,
      formula: "{.Custo Unitário} * {.Qtd/Tempo}"
    },
    {
      name: "um",
      value: "",
      read_only: false
    }
  ],
  id_idea: "FW",
  local: true
}

export const defaultMeasure: Reference = {
  uid: '',
  fields: [
    {
      name: "code",
      value: "",
      read_only: false 
    },
    {
      name: "measure",
      value: 0.0,
      read_only: false
    },
    {
      name: "obs",
      value: "",
      read_only: false
    }
  ],
  id_idea: "FW",
  local: true
}

export const defaultGeneric: Reference = {
  uid: '',
  fields: [
    {
      name: "code",
      value: "",
      read_only: false
    },
    {
      name: "desc",
      value: "",
      read_only: false
    }
  ],
  id_idea: "FW",
  local: true
}

export const defaultReferences: DefaultReference = {
  material: defaultMaterial,
  activity: defaultActivity,
  measure: defaultMeasure,
  generic: defaultGeneric
}

export default defaultReferences;