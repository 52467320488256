import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { DataStorageService } from '../services/data-storage.service';
import { Datasheet } from '../models/datasheet';
import { DatasheetService } from '../services/datasheet.service';
import { DialogService } from '../services/dialog.service';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog/message-dialog.component';
import { StatusType } from '../models/enums/status-type';

@Injectable({
  providedIn: 'root'
})
export class NotSavedDatasheetGuard  {

  isSaved!: string;

  constructor(
    private _datasheet: DatasheetService,
    private _dialog: DialogService,
  ){
  }

 canDeactivate(): Observable<boolean> | boolean {
    this.isSaved = this._datasheet.getStatus()
    if (this.isSaved == StatusType.MODIFIED) {
      const dialogRef = this._dialog.displayDialog(MessageDialogComponent, null, '560px');
      return dialogRef.pipe(map(result => {
        return result;
      }));
    }
    return true;
  }
}

