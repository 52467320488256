import { Field } from "./field";
import { Group } from "./groups";
import { Image } from "./image";
import { Reference } from "./reference";
import { v4 as uuidv4 } from 'uuid';

export interface Variant {
  uid: string,
  fields: Field[],
  materials: Reference[],
  activities: Reference[],
  measures: Reference[],
  generics: Reference[],
  pattern_groups?: Group[],
  marker_groups?: Group[],
  fashion_studio?: Reference[],
  images?: Image[],
  "sub-variants"?: Variant[],
  parentName?: string,
  level?: number,
}


export const defaultVariant: Variant = {
  uid: '',
  fields: [
    {
      name: "code",
      value: "Variante principal",
      read_only: true
    },
    {
      name: "desc",
      value: "",
      read_only: false
    },
    {
      name: "total",
      value: 0,
      read_only: true
    }
  ],
  materials: [],
  activities: [],
  measures: [],
  generics: [],
  pattern_groups: [],
  marker_groups: [],
  fashion_studio: [],
  images: [],
}

export default defaultVariant;