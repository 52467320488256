import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloModule, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { LocalStorageService } from '../services/localStorage.service';
import { environment } from 'src/environments/environment';

const errorLink = onError(({ graphQLErrors, networkError, response }) => {

	if (graphQLErrors && graphQLErrors.length > 0) {
		if (
			(graphQLErrors[0] as any)?.statusCode >= 400 &&
			(graphQLErrors[0] as any)?.statusCode < 500
		) {
			console.error(`[Client side error]: ${graphQLErrors[0].message}`);
		} else {
			console.error(`[Server side error]: ${graphQLErrors[0].message}`);
		}
	}
	if (networkError) {
    console.error(`[Network error]: ${networkError.message}`);
	}
});

export function createApollo(httpLink: HttpLink) {
  const authService = new LocalStorageService();
  const cpnValue = authService.getCurrentCompanyParam();
  const baseUrl = environment.API_GQL;
  const uri = `${baseUrl}?cpn=${cpnValue}`;
  const authObj = authService.getHeadersWithAuthorization().get('authorization');

  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));
  const auth = setContext((operation, context) => {
    const token = authObj;
    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: ` ${token}`,
        },
      };
    }
  });


  const link = ApolloLink.from([basic, auth, errorLink, httpLink.create({ uri })]);
  const cache = new InMemoryCache();
  return {
    link,
    cache,
  };
}

@NgModule({
	imports: [BrowserModule, HttpClientModule, ApolloModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink],
		},
	],
})
export class GraphQLModule {}
