import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { RedirectGuard } from './guards/redirect.guard';
import { NoErrorPageGuard } from './guards/errorpage.guard';


const routes: Routes = [

  {
    path: '',
    canActivate: [RedirectGuard],
    loadChildren: () =>
    import('../app/routes/home/home-routing.module').then(
      (m) => m.HomeRoutingModule
    ),
  },
  {
    path: 'properties-editor',
    loadChildren: () =>
      import('../app/routes/properties-editor/properties-editor-routing.module').then(
        (m) => m.PropertiesEditorRoutingModule
      ),
  },
  {
    path: 'advanced-editor',
    loadChildren: () =>
      import('../app/routes/advanced-editor/advanced-editor-routing.module').then(
        (m) => m.AdvancedEditorRoutingModule
      )
  },
  {
    path: 'print',
    loadChildren: () =>
      import('../app/routes/print/print-routing.module').then(
        (m) => m.PrintRoutingModule
      ),
  },
  { path: 'home', pathMatch: 'full', redirectTo: '' },
  {
    path: 'redirect',
    loadChildren: () =>
      import('../app/routes/redirect/redirect-routing.module').then(
        (m) => m.RedirectRoutingModule
      )
  },
  { path: '**', pathMatch: 'full',canActivate: [NoErrorPageGuard],
      component: ErrorPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
