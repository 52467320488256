import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-filled',
  templateUrl: './button-filled.component.html',
  styleUrls: ['./button-filled.component.scss']
})
export class ButtonFilledComponent implements OnInit {
  @Output() click = new EventEmitter();
  @Input() height: number = 37;
  @Input() color: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public onClick(): void {
    this.click.emit();
  }

}
