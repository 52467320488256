<mat-toolbar>
  <div class="header__container">
    <a class="logo-link" routerLink="/">
      <img matTooltip="Home" src="../../../assets/img/icone-fichaweb.svg" alt="Audaces Ficha Web" />
    </a>
  </div>
  <span class="spacer"></span>

  <div *ngIf="isHome == false" class="header__model-container">
    <p class="header__model-title">Ficha-técnica do modelo:</p>
    <p class="header__model-name">{{ modelName }}</p>
    <p class="header__model-status">- {{ status }}</p>
  </div>
  <span class="spacer"></span>
  <div class="right-info">

    <button-text [isLoading]="isLoading" *ngIf="isLoading"></button-text>
    <button-text 
      appearance="outline"
      mat-raised-button
      matTooltip="{{failed?('toolbar.failed' | translate) : ('toolbar.saved' | translate) }} {{ lastSavedDate | date:'dd/MM/yyyy, HH:mm' }}"
      *ngIf="!isLoading && uid">
      <mat-icon [ngClass]="{'red': failed}">cloud_done</mat-icon>
    </button-text>

    <button mat-raised-button color="primary" (click)="returnToIsa()">
      {{ 'shared.actions.backToIsa.label' | translate }}
    </button>
    <a class="user-profile language-button" (click)="openConfigs()">
      <span class="material-icons-round">
        settings
        </span>
    </a>
    <a class="user-profile" [matMenuTriggerFor]="menu">
      <img class="profile-picture" [src]="userPicture !== '' ? userPicture : '../../../assets/images/sidebar/account.svg'" alt="User Profile Button">
    </a>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item style="pointer-events: none; cursor: default;">
      <div class="profile_resume">
        <img class="profile_resume__image" [src]="userPicture !== '' ? userPicture : '../../../assets/images/sidebar/account.svg'" alt="User Profile Button">
        <p class="profile_resume__name">{{ userName }}</p>
        <p class="profile_resume__enterprise">{{ 'models.enterprise' |translate }}:
          {{ userEnterprise }}</p>
      </div>
    </button>
    <mat-divider></mat-divider>
    <a class="menu_option" href="https://my.audaces.com/profile" mat-menu-item target="_blank">
        <mat-icon svgIcon="audaces-profile" aria-hidden="false"></mat-icon>
        {{ 'shared.actions.profile.label' | translate }}
    </a>
    <button class="menu_option" mat-menu-item (click)="onLogout()">
        <mat-icon [ngStyle]="{'color': '#6B6B6B'}">exit_to_app</mat-icon>
        {{ 'shared.actions.logout.label' | translate }}
    </button>
    <div style="padding-right: 15px;font-size: smaller;color: gray;text-align: right;position: absolute;bottom: 1px;right: 0px;">{{appVersion}}</div>
  </mat-menu>

</mat-toolbar>
