<div class="dialog_container">
  <div class="dialog_container__header">
    <h2 mat-dialog-title class="dialog_container__header_tittle">{{ 'variantNameDialog.title' | translate:{ data: { varIndex: data.varIndex } } }}</h2>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog_container__content">
    <span>{{ 'variantNameDialog.variationDescription' | translate:{ data: { varIndex: data.varIndex } } }}</span>
    <mat-form-field>
      <mat-label>{{ 'variantNameDialog.variationTypeLabel' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.variationName" (keyup.enter)="onEnter()" cdkFocusInitial>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="dialog_container__actions">
    <button-text class="exit" (click)="onNoClick()" [mat-dialog-close]="true">{{ 'variantNameDialog.cancelButton' | translate }}</button-text>
    <button-filled class="save" [mat-dialog-close]="data.variationName">{{ 'variantNameDialog.applyButton' | translate }}</button-filled>
  </div>
</div>
