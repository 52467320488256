import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination';
import { ErpDiff } from 'src/app/models/datasheet';
import { DialogService } from 'src/app/services/dialog.service';
import { ErpReferencesService } from 'src/app/services/erp-references.service';
import { ConfirmRefUpdateComponent } from '../confirm-ref-update/confirm-ref-update.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-erp-update-ref',
  templateUrl: './erp-update-ref.component.html',
  styleUrls: ['./erp-update-ref.component.scss'],
})
export class ErpUpdateRefComponent implements OnInit {
  isLoading = true;
  hasError = false;
  isProcessing = false;
  selectedItemIndex = 0;
  selectedErpDiff!: ErpDiff;
  currentPage = 1;
  keepDSRef: ErpDiff[] = [];
  updateDSRef: ErpDiff[] = [];
  applyToAll: boolean = false;

  constructor(
    private erpReferencesService: ErpReferencesService,
    private _dialogRef: MatDialogRef<ErpReferencesService>,
    private _dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public erpDiffs: ErpDiff[]
  ) {}

  ngOnInit(): void {
    this.erpReferencesService.verifyErpReferences().subscribe(
      (diffs) => (this.erpDiffs = diffs, this.isLoading = false),
      () => this.handleHttpResponse(false)
    );
  }

  private handleHttpResponse = (success: boolean) => {
    this.isLoading = false;
    this.hasError = !success;
    this.isProcessing = false;
    if (success) {
      this._dialogRef.close(true);
    }
  };

  changePage(event: number) {
    this.currentPage = event;
    this.selectedItemIndex = event - 1;
  }

  private handleKeepReference() {
    const itemToKeep = this.erpDiffs[this.selectedItemIndex];
    this.removeFromUpdateRef(itemToKeep);
    if (!this.keepDSRef.includes(itemToKeep)) {
      this.keepDSRef.push(itemToKeep);
    }

    if (this.isLastItem()) {
      this.showConfirmationDialog();
    } else {
      this.navigateToNextItem();
    }
  }

  private handleUpdateReference() {
    const itemToUpdate = this.erpDiffs[this.selectedItemIndex];
    this.removeFromKeepRef(itemToUpdate);
    if (!this.updateDSRef.includes(itemToUpdate)) {
      this.updateDSRef.push(itemToUpdate);
    }

    if (this.isLastItem()) {
      this.showConfirmationDialog();
    } else {
      this.navigateToNextItem();
    }
  }

  private removeFromUpdateRef(item: any) {
    const indexInUpdateRef = this.updateDSRef.indexOf(item);
    if (indexInUpdateRef !== -1) {
      this.updateDSRef.splice(indexInUpdateRef, 1);
    }
  }

  private removeFromKeepRef(item: any) {
    const indexInKeepRef = this.keepDSRef.indexOf(item);
    if (indexInKeepRef !== -1) {
      this.keepDSRef.splice(indexInKeepRef, 1);
    }
  }

  private isLastItem() {
    return this.selectedItemIndex === this.erpDiffs.length - 1;
  }

  private showConfirmationDialog() {
    const dialogRef = this._dialog.displayDialog(ConfirmRefUpdateComponent, {
      toKeep: this.keepDSRef,
      toUpdate: this.updateDSRef,
    }, '420px', '');
    dialogRef.pipe(take(1)).subscribe(result => {
      if (result == 'success') {
        this._dialogRef.close(true);
      }
    });
  }

  private navigateToNextItem() {
    setTimeout(() => {
      this.selectedItemIndex++;
      this.currentPage = this.selectedItemIndex + 1;
    }, 500);
  }

  keepRef() {
    this.applyToAll ? this.handleKeepAllReferences() : this.handleKeepReference();
  }

  updateRef() {
    this.applyToAll ? this.handleUpdateAllReferences() : this.handleUpdateReference();
  }

  private handleKeepAllReferences() {
    this.keepDSRef = [...this.erpDiffs];
    this.updateDSRef = this.updateDSRef.filter(item => !this.keepDSRef.includes(item));
    this.showConfirmationDialog();
  }

  private handleUpdateAllReferences() {
    this.updateDSRef = [...this.erpDiffs];
    this.keepDSRef = this.keepDSRef.filter(item => !this.updateDSRef.includes(item));
    this.showConfirmationDialog();
  }
}
