import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';
import { CompaniesStorageData } from 'src/app/models/isa-company-data';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,

  ) {}

  ngOnInit(): void {
    let callbackRoute = this._localStorageService.get('CALLBACK_ROUTE');

    if (callbackRoute) {
      let params = callbackRoute.split('/');
      if (params[0] == '') {
        params.shift();
      }
      if (params.length) {
        const queryStringIndex = params[params.length - 1].indexOf('?');
        if (queryStringIndex !== -1) {
          const queryString = params[params.length - 1].substring(
            queryStringIndex + 1
          );
          const queryParams = new URLSearchParams(queryString);
          const idString =  queryParams.get('cpn');
          const id = parseInt(idString!);

          if (id !== null) {
            const companyStorageData: CompaniesStorageData = { id }
            this._localStorageService.set(LocalStorageKeys.USER_CURRENT_COMPANY, JSON.stringify(companyStorageData));
          }
        }
        this._router.navigate(params);
      }
    }
  }
}
