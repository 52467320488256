export enum ReferenceType {
  Material = 'materials',
  Activity = 'activities',
  Measures = 'measures',
  Free = 'generics',
  Pattern = 'pattern_groups',
  Marker = 'marker_groups',
  FashionStd = 'fashion_studio',
}

export enum CategoryType {
  materials  = 'material',
  activities = 'activity',
  measures   = 'measures',
  generics   = 'free',
  pattern_groups   = 'pattern',
  marker_groups    = 'marker',
  fashion_studio   = 'fashion_studio',
}