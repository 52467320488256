import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { MessageDialogComponent, MessageDialogType } from '../message-dialog/message-dialog/message-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-fw-configuration',
  templateUrl: './fw-configuration.component.html',
  styleUrls: ['./fw-configuration.component.scss']
})
export class FwConfigurationComponent implements OnInit {
  @Input() configType: string = "config";
  settingsForm!: FormGroup;
  isLoading: boolean = false;

  public languages = [
    { code: 'pt-BR', label: 'Language.PT' },
    { code: 'en-US', label: 'Language.US' },
    { code: 'it-IT', label: 'Language.IT' },
    { code: 'es-ES', label: 'Language.ES' },
  ];

  public currencies = [
    { code: 'USD', label: 'Currency.USD' },
    { code: 'BRL', label: 'Currency.BRL' },
    { code: 'EUR', label: 'Currency.EUR' },
    { code: 'GTQ', label: 'Currency.GTQ' },
    { code: 'HNL', label: 'Currency.HNL' },
    { code: 'PEN', label: 'Currency.PEN' },
    { code: 'PYG', label: 'Currency.PYG' },
    { code: 'VES', label: 'Currency.VES' },
    { code: 'GBP', label: 'Currency.GBP' },
    { code: 'ARS', label: 'Currency.ARS' },
    { code: 'BOB', label: 'Currency.BOB' },
    { code: 'CLP', label: 'Currency.CLP' },
    { code: 'COP', label: 'Currency.COP' },
    { code: 'DOP', label: 'Currency.DOP' },
    { code: 'UYU', label: 'Currency.UYU' },
    { code: 'MXN', label: 'Currency.MXN' },
  ];

  public measuresUnit = [
    { code: 'mm', label: 'Measure.mm' },
    { code: 'cm', label: 'Measure.cm' },
    { code: 'm', label: 'Measure.m' },
    { code: 'in', label: 'Measure.pol' },
    { code: 'ft', label: 'Measure.foot' },
    { code: 'yd', label: 'Measure.yard' }
  ];

  public areaUnit = [
    { code: 'm²', label: 'Area.m2' },
    { code: 'mm²', label: 'Area.mm2' },
    { code: 'cm²', label: 'Area.cm2' },
    { code: 'in²', label: 'Area.pol2' },
    { code: 'ft²', label: 'Area.foot2' },
    { code: 'yd²', label: 'Area.yard2' }
  ];

  public weightUnit = [
    { code: 'mg', label: 'Weight.mg' },
    { code: 'g', label: 'Weight.g' },
    { code: 'kg', label: 'Weight.kg' },
    { code: 'oz', label: 'Weight.oz' },
    { code: 'lb', label: 'Weight.lb' }
  ];

  defaultConfigs ={
    language: undefined,
    currency: "BRL",
    measureUnit: 'mm',
    areaUnit: 'mm²',
    weightUnit: 'mg'
  }

  private originalConfigs: any;


  constructor(
    private _fb: FormBuilder,
    private _localStorage: LocalStorageService,
    private translateService: TranslateService,
    private _router: Router,
    private _dialog: MatDialog,
    private _datasheetStorage: DataStorageService,
    private _datasheetService: DatasheetService,
    private cdr: ChangeDetectorRef,
    private _dialogRef: MatDialogRef<FwConfigurationComponent>,
    private _snackBar: SnackbarService,
    private _printService: PrintService
  ) {}

  ngOnInit(): void {
    this.settingsForm = this._fb.group({
      language: [''],
      currency: [''],
      measureUnit: [''],
      areaUnit: [''],
      weightUnit: ['']
    });

    this.initForm();
  }

  onSubmit() {
    console.log(this.settingsForm.value);
    this.setConfiguration();
  }

  setConfiguration() {
    this.isLoading = true;
    const configs = this.settingsForm.value;

    if (this.configType === 'model-configs') {
      const hasMeasureChanges = this.hasMeasureChanges(configs);

      if (hasMeasureChanges) {
        const dialogRef = this._dialog.open(MessageDialogComponent, {
          data: {
            type: MessageDialogType.UPDATE_DS_CONFIGS,
          }
        });

        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.updateConfigsHandler(result, configs);
          } else {
            this.updateConfigsHandler(false, configs);
          }
        });
        this.isLoading = false;
      }
    } else {
      this._datasheetStorage.updateUserConfig(configs).subscribe(() => {
        this.setLanguage(configs.language);
        this._localStorage.set(LocalStorageKeys.USER_CONFIGS, configs, 'local', false);
        this._dialogRef.close();
      });
    }
  }

  private hasMeasureChanges(configs: any): boolean {
    return (
      configs.measureUnit !== this.originalConfigs.measureUnit ||
      configs.areaUnit !== this.originalConfigs.areaUnit ||
      configs.weightUnit !== this.originalConfigs.weightUnit
    );
  }

initForm() {
  let configs: any;
  if (this.configType == 'model-configs') {
    configs = this._datasheetService.getDatasheet().getAllMeasureValues();
  } else {
    configs = this._localStorage.get(LocalStorageKeys.USER_CONFIGS, "local", false) || this.defaultConfigs;
  }
  const language = configs.language || localStorage.getItem(LocalStorageKeys.USER_CURRENT_LANGUAGE);

  // Armazena as configurações originais para comparação posterior
  this.originalConfigs = { ...configs };

  this.patchValueonForm(configs, language);
}

  patchValueonForm(configs: any, language?: any){
    this.settingsForm.patchValue({
      language: language,
      currency: configs.currency ,
      measureUnit: configs.measureUnit ,
      areaUnit: configs.areaUnit,
      weightUnit: configs.weightUnit
    });
  }

  setLanguage(userLang: string) {
    const languageCode = userLang.split('-')[0];
    this.translateService.setDefaultLang(languageCode);
    this.translateService.use(languageCode);
    localStorage.setItem(LocalStorageKeys.USER_CURRENT_LANGUAGE, userLang);
  }

  updateConfigsHandler( result: any, configs: any) {

    this.isLoading = true;
    this._datasheetStorage.updateDatasheetConfigs(this.settingsForm.value, result)
      .subscribe(
        (resp) => {
          const currentDS = this._datasheetService.getDatasheet();
          Object.assign(currentDS, resp.body);
          this._datasheetService.setDataSheet(currentDS);
          this._printService.printDataParser(currentDS);
          this._dialog.closeAll();
        },
        (error) => {
          this.isLoading = false;
          this._snackBar.failMessage(this.translateService.instant('alerts.failSaveUserData.text'));
        },
        () => {
          this.isLoading = false;
        }
      );
  }

}
