<div class="dialog_container">

  <div mat-dialog-content>
    <h2>{{'erp.forms.loginTittle.label' | translate}}</h2>
    <div [formGroup]="erpCommomForm">
      <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
        <mat-label> {{ 'erp.forms.user.label' | translate }} </mat-label>
        <input matInput formControlName="user">
      </mat-form-field>
      <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
        <mat-label> {{ 'erp.forms.password.label' | translate }} </mat-label>
        <input type="password" matInput formControlName="password" (keydown.enter)="doErpLogin()">
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions  class="dialog-actions">
    <button-text class="exit" [mat-dialog-close]="false" >{{ 'shared.actions.cancel.label' | translate }}</button-text>
    <button-filled class="save" [isLoading]="isProcessing" (click)="doErpLogin()">{{ 'shared.actions.login.label' | translate }}</button-filled>
  </div>
</div>
