import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColumnFilteringService {

  public columnFilteringChanged = new Subject<boolean>();
  private columnNames$ = new Subject<string[]>();
  public columns:string[] = [];

  constructor() { }

  public getColumnNames(): Observable<string[]> {
    return this.columnNames$.asObservable();
  }

  public setColumnNames(columnNames: string[]): void {
    this.columns = columnNames;
    this.columnNames$.next(this.columns);
  }
}
