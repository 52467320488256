import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar',
  templateUrl: 'snack-bar.html',
  styleUrls: ['snack-bar.scss'],
})

export class AppSnackBar {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackBarRef: MatSnackBarRef<AppSnackBar>,
  ) {}

  closeSnackbar() {
    this._snackBarRef.dismiss();
  }

  actionClicked() {
    if (this.data.callback) {
      this.data.callback();
    }
    this.closeSnackbar();
  }

}
