import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorage.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { ErpConfigData } from '../models/integration-user-data';
import { ErpServer } from './erp-service.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationServiceService {
  constructor(
    private _localStorage: LocalStorageService,
    private _http: HttpClient
  ) {}

  public integrationLogin(
    address: string,
    erpData: ErpConfigData
  ): Observable<boolean> {
    const token = 'Bearer ' + this._localStorage.getAuthorizationToken();

    let headers: any = {
      'Authorization': token,
      'erp-user': erpData.user.name,
    };

    if (erpData.user.password) {
      headers['erp-password'] = erpData.user.password;
    }

    const params = {
      dictionary: erpData.apiAudaces!.dictionary,
      credential: erpData.apiAudaces!.credential,
    };

    return this._http.post<any>(address, params, { headers: headers, observe: 'response' })
      .pipe(
        map((response) => {
          const statusCode = response.status;
          return statusCode < 300;
        }),
        catchError(error => {
          console.error('Erro na chamada API:', error);
          return of(false);
        })
      );
  }

  public erpHealthcheck(address: any){
    const headers = this._localStorage.getHeadersWithAuthorization();
    const options = {
      headers: headers,
    };

    return this._http.get<any>(`${address}/audaces/erp/api/v1/healthcheck/`, options)
  }

  public getRequest( erpServer: ErpServer, url : string, queryParams : any = {}, headerParams : HttpHeaders|null = null, asJson = true): Observable<any> {

    if( !headerParams ) {
      headerParams = erpServer.getAuthorizationHeader();
    }

    if(erpServer.erp_cfg.erp.customToken) {
      queryParams['api_key'] = erpServer.erp_cfg.erp.customToken;
    }
    
    if(asJson)
      return this._http.get<any>(url, { headers: headerParams, params:queryParams, observe: 'response' });
    else
      return this._http.get<any>(url, { headers: headerParams, params:queryParams, observe: 'body', responseType: 'text' as 'json' });
  }

  public postRequest( erpServer: ErpServer, url : string, bodyParams : any = {}, needAuth = true): Observable<any> {
    
    if(erpServer.erp_cfg.erp.customToken) {
      bodyParams['api_key'] = erpServer.erp_cfg.erp.customToken;
    }

    let body = new URLSearchParams();
    for( let key in bodyParams) {
      body.set(key, bodyParams[key]);
    }
    
    let headers = new HttpHeaders({
      "Content-Type" : "application/x-www-form-urlencoded"
    });
    let access_token = erpServer.getAuthToken();
    if( needAuth && access_token.length ) {
      headers = new HttpHeaders({
        'Authorization' : `Bearer ${access_token}`
      });
    }

    return this._http.post<any>(url, body, { headers: headers, observe: 'response' });
  }


}
