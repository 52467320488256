import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErpDiff } from 'src/app/models/datasheet';
import { PrintData, TableData } from 'src/app/models/print-data';
import { PrintService } from 'src/app/services/print.service';
import { AnalyticsService } from 'src/app/services/analytics.service';


@Component({
  selector: 'app-print-config-drawer',
  templateUrl: './print-config-drawer.component.html',
  styleUrls: ['./print-config-drawer.component.scss'],
})
export class PrintConfigDrawerComponent implements OnInit {
  @Input() erpDiffs: ErpDiff[] = [];
  @Output() _closeDrawer: EventEmitter<void> = new EventEmitter<void>();
  public printData!: PrintData;
  searchTerms: { [key: string]: string } = {};
  searchTerm:any;
  imageUrl: any;
  selectedOrientation: string = 'portrait';
  isDragOver: boolean = false;
  private subscription!: Subscription;

  constructor(
    private _printService: PrintService,
    private _analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this._printService
    .getPrintData()
    .subscribe((data) => {
      this.printData = data;
      console.log(data);
    });
  }
  ngOnDestroy(): void {
    // Desinscrever para evitar vazamentos de memória
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

toggleItem(action: string, index: number) {
  switch (action) {
    case 'Material':
      this.printData.ToggleMaterialIndex(index);
      break;
    case 'Variant':
      this.printData.ToggleVariantIndex(index);
      console.log(this.printData.variants)
      break;
    case 'Activities':
      this.printData.ToggleActivitiesIndex(index);
      break;
    case 'Measures':
      this.printData.ToggleMeasuresIndex(index);
      break;
    case 'VariantInfo':
      this.printData.ToggleVariantHeadersIndex(index);
      break;
    case 'Generic':
      this.printData.ToggleGenericIndex(index);
      break;
    case 'Model':
      this.printData.ToggleModelIndex(index);
      break;
    case 'ModelImages':
      this.printData.ToggleModelImages(index);
      break;
    case 'ReferencesImages':
      this.printData.ToggleReferencesImages(index);
      break;
    default:
      console.debug('Ação não reconhecida');
  }
}

private toggleAllItemsVisibility(show: boolean, action:string){
  switch (action) {
    case 'Model':
      show ? this.printData.showAllModel() : this.printData.hiddenAllModel();
      break;
    case 'Variant':
      show ? this.printData.showAllVariant() : this.printData.hiddenAllVariant();
      break;
    case 'VariantInfo':
      show ? this.printData.showAllVariantInfo() : this.printData.hiddenAllVariantInfo();
      break;
    case 'Material':
      show ? this.printData.showAllMaterial() : this.printData.hideAllMaterials();
      break;
    case 'Activity':
      show ? this.printData.showAllActivity() : this.printData.hideAllActivity();
      break;
    case 'Measures':
      show ? this.printData.showAllMeasures() : this.printData.hideAllMeasures();
      break;
    case 'Generic':
      show ? this.printData.showAllGeneric() : this.printData.hideAllGeneric();
      break;
  }

}

hideAllItems(action:string){
  this.toggleAllItemsVisibility(false, action);
}

showAllItems(action: string){
  this.toggleAllItemsVisibility(true, action);
}

togleTableVisibility(event: Event,action: string){
  event.stopPropagation();
  switch (action) {
    case 'Model':
      this.printData.toggleModelVisibility();
      break;
    case 'Variant':
      this.printData.toggleVariantVisibility();
      console.log(this.printData.variants)
      break;
    case 'VariantInfo':
      this.printData.toggleVariantInfoVisibility();
      break;
    case 'Material':
      this.printData.toggleMaterialVisibility();
      break;
    case 'Activity':
      this.printData.toggleActivityVisibility();
      break;
    case 'Measures':
      this.printData.toggleMeuasureVisibility();
      break;
    case 'Generic':
      this.printData.toggleGenericVisibility();
      break;
    case 'ModelImg':
      this.printData.toggleModelImageVisibility();
    break;
    case 'ReferenceImg':
      this.printData.toggleReferenceImageVisibility();
    break;
  }
}

public verifyCheckBoxStatus(action: string) {
  switch(action){
    case 'Model':
      return this.printData.verifyAllModelInfochecked();
    case 'Variant':
      return this.printData.verifyAllVariantschecked();
    case 'VariantInfo':
      return this.printData.verifyAllVariantInfoschecked();
    case 'Material':
      return this.printData.verifyAllMaterialchecked();
    case 'Activity':
      return this.printData.verifyAllActivitiesChecked();
    case 'Measures':
      return this.printData.verifyAllMeasuresChecked();
    case 'Generic':
      return this.printData.verifyAllGenericChecked();
      default:
        return 'unchecked'
  }
}

searchTermHandler(term: string, field:string){
  this.searchTerms[field] = term;
}

onCheckboxChange(event: any, action:string) {
  const isChecked = event.checked;
  if (isChecked) {
    this.showAllItems(action);
  } else {
    this.hideAllItems(action);
  }
}

onDragOver(event: DragEvent) {
  event.preventDefault();
  this.isDragOver = true;
}

onDragLeave(event: any) {
  this.isDragOver = false;
}

onDrop(event: DragEvent) {
  event.preventDefault();
  this.isDragOver = false;
  this.handleImageDrop(event.dataTransfer!.files);
}

onFileSelected(event: Event) {
  const target = event.target as HTMLInputElement;
  const files = target.files;

  this.handleImageDrop(files);
}

private handleImageDrop(files: FileList | null) {
  if (files && files.length > 0) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageUrl = e.target?.result;
      this.setLogo();
      console.log(this.imageUrl)
    };

    reader.readAsDataURL(files[0]);
  }
}

public setLogo(){
  this.printData.setLogo(this.imageUrl);
}

public removeLogo(){
  this.imageUrl = "";
  this.printData.removeLogo();
}

public print(){
  this._analyticsService.trackStartEvent("sendToPrinter");
  window.print();
  this._analyticsService.trackEndEvent("sendToPrinter");
}

public closerDrawer(){
  this._closeDrawer.emit();
}
}



