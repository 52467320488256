import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type CategoryFieldGraphType = {
  __typename?: 'CategoryFieldGraphType';
  custom: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  properties: PropertyGraphType;
  type: Scalars['String']['output'];
};

export type CategoryGraphType = {
  __typename?: 'CategoryGraphType';
  activity: CategoryTypeGraphType;
  free: CategoryTypeGraphType;
  fashion_studio: CategoryTypeGraphType;
  garment: CategoryTypeGraphType;
  group: CategoryTypeGraphType;
  marker: CategoryTypeGraphType;
  marker_group: CategoryTypeGraphType;
  material: CategoryTypeGraphType;
  measures: CategoryTypeGraphType;
  pattern: CategoryTypeGraphType;
  pattern_group: CategoryTypeGraphType;
  variant: CategoryTypeGraphType;
};

export type CategoryTypeGraphType = {
  __typename?: 'CategoryTypeGraphType';
  fields: Array<CategoryFieldGraphType>;
  label: Scalars['String']['output'];
};

export type ColorGraphType = {
  __typename?: 'ColorGraphType';
  alpha: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DatasheetGraphType = {
  __typename?: 'DatasheetGraphType';
  categories: CategoryGraphType;
  creation_date: Scalars['String']['output'];
  creation_user: Scalars['String']['output'];
  format_version: Scalars['String']['output'];
  fw: FwGraphType;
  garment: GarmentGraphType;
  last_modification_date: Scalars['String']['output'];
  last_modification_user: Scalars['String']['output'];
  revision: Scalars['Int']['output'];
  variant_main_fields: Array<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type DatasheetSumaryGraphType = {
  __typename?: 'DatasheetSumaryGraphType';
  allVariantNames: Array<Maybe<Scalars['String']['output']>>;
  fw: FwGraphType;
  garmentActivitiesCount?: Maybe<Scalars['Int']['output']>;
  garmentDesc: Scalars['String']['output'];
  garmentGenericsCount?: Maybe<Scalars['Int']['output']>;
  garmentMaterialCount?: Maybe<Scalars['Int']['output']>;
  garmentMeasureCount?: Maybe<Scalars['Int']['output']>;
  garmentName: Scalars['String']['output'];
  garmentTotal: Scalars['String']['output'];
  image: Scalars['String']['output'];
  last_modification_date: Scalars['String']['output'];
  last_modification_user: Scalars['String']['output'];
};

export type FwGraphType = {
  __typename?: 'FWGraphType';
  datasheet_revision: Scalars['Int']['output'];
  datasheet_uid: Scalars['String']['output'];
  datasheet_version: Scalars['String']['output'];
  isa_company?: Maybe<Scalars['String']['output']>;
  isa_kanbam?: Maybe<Scalars['String']['output']>;
  isa_model_id?: Maybe<Scalars['Int']['output']>;
  isa_owner_user?: Maybe<Scalars['String']['output']>;
  isa_shape?: Maybe<Scalars['String']['output']>;
};

export type FieldGraphType = {
  __typename?: 'FieldGraphType';
  color?: Maybe<ColorGraphType>;
  formula?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  read_only: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type GarmentGraphType = {
  __typename?: 'GarmentGraphType';
  activities?: Maybe<Array<ReferenceGraphType>>;
  fields: Array<FieldGraphType>;
  fashion_studio?: Maybe<Array<ReferenceGraphType>>;
  garments?: Maybe<Array<GroupGraphType>>;
  generics?: Maybe<Array<ReferenceGraphType>>;
  images?: Maybe<Array<ImageGraphType>>;
  marker_groups?: Maybe<Array<GroupGraphType>>;
  materials?: Maybe<Array<ReferenceGraphType>>;
  measures?: Maybe<Array<ReferenceGraphType>>;
  pattern_groups?: Maybe<Array<GroupGraphType>>;
  reference_groups?: Maybe<Array<GroupGraphType>>;
  uid: Scalars['String']['output'];
  variants: Array<Maybe<VariantGraphType>>;
};

export type GroupGraphType = {
  __typename?: 'GroupGraphType';
  activities?: Maybe<Array<ReferenceGraphType>>;
  fields: Array<FieldGraphType>;
  fashion_studio?: Maybe<Array<ReferenceGraphType>>;
  garments?: Maybe<Array<GroupGraphType>>;
  generics?: Maybe<Array<ReferenceGraphType>>;
  id_idea: Scalars['String']['output'];
  images: Array<ImageGraphType>;
  local: Scalars['Boolean']['output'];
  marker_groups?: Maybe<Array<GroupGraphType>>;
  markers?: Maybe<Array<ReferenceGraphType>>;
  materials?: Maybe<Array<ReferenceGraphType>>;
  measures?: Maybe<Array<ReferenceGraphType>>;
  pattern_groups?: Maybe<Array<GroupGraphType>>;
  patterns?: Maybe<Array<ReferenceGraphType>>;
  reference_groups?: Maybe<Array<GroupGraphType>>;
  uid: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  variant: Scalars['String']['output'];
  variants?: Maybe<Array<VariantGraphType>>;
};

export type ImageGraphType = {
  __typename?: 'ImageGraphType';
  format: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  location: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Set values on Isa related fields inside a specific datasheet. */
  set_isa_fields: Scalars['Boolean']['output'];
};


export type MutationSet_Isa_FieldsArgs = {
  datasheet_uid: Scalars['String']['input'];
  field_key: Scalars['String']['input'];
  field_value: Scalars['String']['input'];
};

export type PaginationResultOfDatasheetSumaryGraphType = {
  __typename?: 'PaginationResultOfDatasheetSumaryGraphType';
  items: Array<DatasheetSumaryGraphType>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type PropertyGraphType = {
  __typename?: 'PropertyGraphType';
  always_editable?: Maybe<Scalars['Boolean']['output']>;
  automatic_datetime?: Maybe<Scalars['Boolean']['output']>;
  empty_valid?: Maybe<Scalars['Boolean']['output']>;
  external_custom?: Maybe<Scalars['Boolean']['output']>;
  fractional_digits?: Maybe<Scalars['Float']['output']>;
  max_value?: Maybe<Scalars['Float']['output']>;
  measure?: Maybe<Scalars['String']['output']>;
  min_value?: Maybe<Scalars['Float']['output']>;
  multi_line?: Maybe<Scalars['Boolean']['output']>;
  read_only?: Maybe<Scalars['Boolean']['output']>;
  remove_zeros?: Maybe<Scalars['Boolean']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retorna uma lista de datasheets. */
  get_datasheet: Array<Maybe<DatasheetGraphType>>;
  /**
   * Returns a paginated list of datasheets summary.
   *
   *
   * **Returns:**
   * A paginated list containing summaries of the datasheets.
   */
  get_sumary: PaginationResultOfDatasheetSumaryGraphType;
};


export type QueryGet_DatasheetArgs = {
  cpn: Scalars['Int']['input'];
  datasheet_uid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGet_SumaryArgs = {
  cpn: Scalars['Int']['input'];
  filter?: InputMaybe<Query_FilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type ReferenceGraphType = {
  __typename?: 'ReferenceGraphType';
  fields: Array<FieldGraphType>;
  id_idea: Scalars['String']['output'];
  local: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  uri?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
  variation_name?: Maybe<Scalars['String']['output']>;
};

export type VariantGraphType = {
  __typename?: 'VariantGraphType';
  activities?: Maybe<Array<ReferenceGraphType>>;
  fields: Array<FieldGraphType>;
  fashion_studio?: Maybe<Array<ReferenceGraphType>>;
  generics?: Maybe<Array<ReferenceGraphType>>;
  marker_groups?: Maybe<Array<GroupGraphType>>;
  materials?: Maybe<Array<ReferenceGraphType>>;
  measures?: Maybe<Array<ReferenceGraphType>>;
  name: Scalars['String']['output'];
  pattern_groups?: Maybe<Array<GroupGraphType>>;
  sub_variants?: Maybe<Array<VariantGraphType>>;
  uid: Scalars['String']['output'];
};

export type Query_FilterInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GetDatasheetSummaryQueryVariables = Exact<{
  cpn: Scalars['Int']['input'];
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  filter?: InputMaybe<Query_FilterInput>;
}>;


export type GetDatasheetSummaryQuery = { __typename?: 'Query', get_sumary: { __typename?: 'PaginationResultOfDatasheetSumaryGraphType', totalItems: number, pageNumber: number, pageSize: number, items: Array<{ __typename?: 'DatasheetSumaryGraphType', garmentName: string, garmentMaterialCount?: number | null, garmentActivitiesCount?: number | null, garmentMeasureCount?: number | null, garmentGenericsCount?: number | null, garmentDesc: string, garmentTotal: string, last_modification_date: string, last_modification_user: string, image: string, allVariantNames: Array<string | null>, fw: { __typename?: 'FWGraphType', datasheet_uid: string, datasheet_revision: number, isa_company?: string | null, isa_kanbam?: string | null, isa_model_id?: number | null, isa_shape?: string | null } }> } };

export const GetDatasheetSummaryDocument = gql`
    query GetDatasheetSummary($cpn: Int!, $pageNumber: Int!, $pageSize: Int!, $filter: query_filterInput) {
  get_sumary(
    cpn: $cpn
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
  ) {
    items {
      garmentName
      garmentMaterialCount
      garmentActivitiesCount
      garmentMeasureCount
      garmentGenericsCount
      garmentDesc
      garmentTotal
      last_modification_date
      last_modification_user
      fw {
        datasheet_uid
        datasheet_revision
        isa_company
        isa_kanbam
        isa_model_id
        isa_shape
      }
      image
      allVariantNames
    }
    totalItems
    pageNumber
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDatasheetSummaryGQL extends Apollo.Query<GetDatasheetSummaryQuery, GetDatasheetSummaryQueryVariables> {
    override document = GetDatasheetSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }