<div class="model-form" [formGroup]="values">
  <div class="div" *ngFor="let field of valuesWithOptions; let i = index" [ngClass]="getFieldForColor(field) == 'color' ? 'dad-color' : ''">
    <ng-container *ngIf="hasOptions(field); else input">
      <mat-form-field class="model-form__field" appearance="outline">
        <mat-label>{{ field.label! | translate }}</mat-label>
        <mat-select disableRipple panelClass="model-form__panel isa-med-elevation" disableOptionCentering
          [formControlName]="field.name" matInput>
          <mat-option [value]="option" *ngFor="let option of field.options">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-template #input>
      <ng-container [ngSwitch]="getFieldType(field.name)">
        <ng-container *ngSwitchCase="'Color'">
          <app-color-picker [form_Group]="values" [field]="field" class="model-form__field"></app-color-picker>
        </ng-container>
        <ng-container *ngSwitchCase="'Number'">
          <mat-form-field appearance="outline" class="model-form__field">
            <mat-label>{{ field.label! | translate }}</mat-label>
            <input [formControlName]="field.name" matInput type="number"
              inputmode="numeric"
              (keypress)="preventNonNumericalInput($event)"
              [value]="values.get(field.name)?.value"
            />
            <span style="color: grey; padding-right: 10px" *ngIf="field.properties?.measure" matSuffix>{{getMeasureSymbol(field.properties?.measure!)}}</span>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'Currency'">
          <mat-form-field appearance="outline" class="model-form__field" >
            <mat-label>{{ field.label! | translate }}</mat-label>
            <input [formControlName]="field.name" matInput type="text"
              formatDecimal
              [value]="values.get(field.name)?.value"

            />
            <span style="color: grey; padding-right: 10px" matSuffix> {{getCurrency()}}</span>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'Text'">
          <mat-form-field appearance="outline"
            [ngClass]="field.properties?.multi_line ? '_textarea' : 'model-form__field'">
            <mat-label>{{ field.label! | translate }}</mat-label>
            <textarea style="resize: none;" *ngIf="field.properties?.multi_line; else inputField"
              [formControlName]="field.name" matInput></textarea>
            <ng-template #inputField>
              <input [formControlName]="field.name" matInput type="text" />
            </ng-template>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'datetime-local'">
          <mat-form-field class="model-form__field" appearance="outline" *ngIf="!field.properties?.automatic_datetime">
            <mat-label>{{ field.label! | translate }}</mat-label>
            <input [formControlName]="field.name" matInput [matDatepicker]="picker3" readonly="false"
              [value]="timestampToDate(values.get(field.name)?.value)" [required]="false">
            <mat-datepicker-toggle matIconSuffix [for]="picker3" style="top: 0;right: 0;"></mat-datepicker-toggle>
            <mat-datepicker-toggle style="color: grey" matSuffix (click)="clearDate(field.name)" style="position: absolute;top: 0;right: 32px;">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'FloatingNumber'">
          <mat-form-field appearance="outline" class="model-form__field">
            <mat-label>{{ field.label! | translate }}</mat-label>
            <input
              [formControlName]="field.name"
              matInput
              formatDecimal
              type="text"
              [value]="values.get(field.name)?.value"  step="0.01"
            />
            <span style="color: grey; padding-right: 10px" *ngIf="field.properties?.measure" matSuffix>{{getMeasureSymbol(field.properties?.measure!)}}</span>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'Boolean'">
          <mat-form-field class="model-form__field" appearance="outline">
            <mat-label>{{ field.label! | translate }}</mat-label>
            <mat-select [formControlName]="field.name">
              <mat-option value="true">{{ 'shared.actions.yes.label' | translate }}</mat-option>
              <mat-option value="false">{{ 'shared.actions.no.label' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-form-field class="model-form__field" appearance="outline">
            <mat-label>{{ field.label! | translate }}</mat-label>
            <input [formControlName]="field.name" matInput [type]="getFieldType(field.name) || 'text'" />
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-template>

    <!-- Mat Menu -->
    <button style="margin-top: -5px;" mat-icon-button  [matMenuTriggerFor]="menu" aria-label="Menu">
      <mat-icon style="color: #324FBE;">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="editField(field)">
        <mat-icon>edit</mat-icon>
        <span>{{'refList.edit' | translate}}</span>
      </button>
      <button mat-menu-item (click)="deleteField(field.name)">
        <mat-icon>delete</mat-icon>
        <span>{{'refList.delete' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
