<div class="dialog_container">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header__tittle">{{ 'variantManager.title' | translate }}</h2>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider style="width: 95%; align-self: center; margin-top: 10px;"></mat-divider>

  <div mat-dialog-content class="dialog-content">
    <div class="dialog-content_variations">
      <div class="dialog-content_variations-1" (click)="setSelectedLevel(0)"
        [ngClass]="{'selected': lvlSelected === 0, 'not-selected': lvlSelected !== 0 && lvlSelected !== null}">
        <img src="../../../assets/images/level0.svg" [alt]="'variantManager.variations.level0.imageAlt' | translate">
        <span>{{ 'variantManager.variations.level0.label' | translate }}</span>
      </div>
      <div class="dialog-content_variations-2" (click)="setSelectedLevel(1)"
        [ngClass]="{'selected': lvlSelected === 1, 'not-selected': lvlSelected !== 1 && lvlSelected !== null}">
        <img src="../../../assets/images/level1.svg" [alt]="'variantManager.variations.level1.imageAlt' | translate">
        <span>{{ 'variantManager.variations.level1.label' | translate }}</span>
      </div>
      <div class="dialog-content_variations-3" (click)="setSelectedLevel(2)"
        [ngClass]="{'selected': lvlSelected === 2, 'not-selected': lvlSelected !== 2 && lvlSelected !== null}">
        <img src="../../../assets/images/level2.svg" [alt]="'variantManager.variations.level2.imageAlt' | translate">
        <span>{{ 'variantManager.variations.level2.label' | translate }}</span>
      </div>
      <div class="dialog-content_variations-4" (click)="setSelectedLevel(3)"
        [ngClass]="{'selected': lvlSelected === 3, 'not-selected': lvlSelected !== 3 && lvlSelected !== null}">
        <img src="../../../assets/images/level3.svg" [alt]="'variantManager.variations.level3.imageAlt' | translate">
        <span>{{ 'variantManager.variations.level3.label' | translate }}</span>
      </div>
    </div>

    <div class="dialog-content_config">
      <div class="dialog-content_config_variant">
        <div *ngFor="let array of getArrays(); let i = index">
          <mat-form-field class="chip-list" appearance="outline" >
            <mat-label style="margin-bottom: 4px;">
              {{ getLabel(i) }}
              <mat-icon (click)="openDialog(i)"
                style=" color: #F569AA; margin-bottom: -5px; font-size: 24px; margin-left: 15px;  padding: 0;"
                class="material-icons-outlined" matSuffix
                [matTooltip]="'variantManager.configDialogTooltip' | translate">edit</mat-icon>
            </mat-label>
            <mat-chip-grid #chipGrid aria-label="Enter fruits">
              <ng-container *ngFor="let item of array">
                <mat-chip-row (removed)="deleteNode(item, i)" [editable]="true" (edited)="edit(i + 1, item, $event)"
                  [aria-description]="'press enter to edit ' + item">
                  {{ item }}
                  <button matChipRemove [attr.aria-label]="'remove ' + item">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </ng-container>
              <input placeholder="{{ 'variantManager.newItemPlaceholder' | translate }}" [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event, i )" />
            </mat-chip-grid>
            <mat-hint>{{ 'variantManager.chipHint' | translate: { levelNumber: i + 1 } }}</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="dialog-actions action_bottom">
    <button-text class="exit" [mat-dialog-close]="true">{{ 'variantManager.cancelButton' | translate }}</button-text>
    <button-filled class="save" (click)="onAddVariant()">{{ 'variantManager.applyButton' | translate }}</button-filled>
  </div>

</div>
