import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { ErpDiff } from 'src/app/models/datasheet';
import { MessageType } from 'src/app/models/enums/message-type';
import { PrintData } from 'src/app/models/print-data';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { dataResolutionServiceService } from 'src/app/services/dataResolutionService.service';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ErpReferencesService } from 'src/app/services/erp-references.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  printData!: PrintData;
  public uid: string = '';
  public isLoading: boolean = true;
  public hasError : boolean = false;
  isProcessing: boolean = false;
  drawerOpenned = true;
  private subcptRoute?: Subscription;
  MessageType = MessageType;
  erpDiffs: ErpDiff[] = [];
  private destroy$ = new Subject<boolean>();

  constructor(
    private _datasheetService: DatasheetService,
    private _printService : PrintService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _resolution : dataResolutionServiceService,
    private _erpReferencesService: ErpReferencesService,
    private _analyticsService: AnalyticsService,

  ) { }


  ngOnInit(): void {
    this.subcptRoute = this._route.params.subscribe((params: Params) => {
      if (params['uid'] !== undefined) {
        this.uid = params['uid'];
      }
    });
    this.resolver();
  }
  backToModel(uid: string): void {
    this._router.navigate(['/properties-editor', uid]);
  }

  toggleDrawer() {
    this.drawerOpenned = !this.drawerOpenned;
  }

  public retryFunction(){
    this.isProcessing = true;
    this.resolver();
  }

  private handleHttpError() {
    this.isLoading = false;
    this.hasError = true;
    this.isProcessing = false
  }

  private handleHttpSucess(){
    this.isLoading = false;
    this.hasError = false;
    this.isProcessing = false;
  }

  private resolver() {

    this._analyticsService.trackStartEvent("viewPrintpreview");

    this._resolution.resolveData(this.uid!).subscribe({
      next: (resolvedData) => {
        this._printService.printDataParser(resolvedData);
        this.uid = this._datasheetService.getDatasheetUid();
        this._erpReferencesService.verifyErpReferences().subscribe(x => this.erpDiffs = x);
        this.handleHttpSucess();
        this._analyticsService.trackEndEvent("viewPrintPreview");

        this._datasheetService.isaMetaChanged
        .pipe(takeUntil(this.destroy$))
        .subscribe(isaMeta => {
          this._printService.updateIsaMetaData( isaMeta );
        });

      },
      error: (error) => {
        console.debug('Erro ao resolver dados:', error);
        this.handleHttpError();
        this._analyticsService.trackEndEvent("viewPrintPreview");
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
