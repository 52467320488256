import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErpDiff } from 'src/app/models/datasheet';
import { ErpReferencesService } from 'src/app/services/erp-references.service';
import { ErpUpdateRefComponent } from '../erp-update-ref/erp-update-ref.component';

@Component({
  selector: 'app-confirm-ref-update',
  templateUrl: './confirm-ref-update.component.html',
  styleUrls: ['./confirm-ref-update.component.scss']
})
export class ConfirmRefUpdateComponent {


  isLoading = false;
  hasError = false;
  isProcessing = false;

  constructor(
    private _dialogRef: MatDialogRef<ConfirmRefUpdateComponent>,
    private erpReferencesService: ErpReferencesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  private saveDatasheet() {
    this.isLoading = true;
    this.isProcessing = true;
    this.erpReferencesService.applyDiffsToDatasheet(
      this.data.toUpdate,
      this.handleHttpResponse
    );
  }

  private handleHttpResponse = (success: boolean) => {
    this.isLoading = false;
    this.hasError = !success;
    this.isProcessing = false;
    if (success) {
      this._dialogRef.close('success');
    }
  };


  save() {
    if(this.data.toUpdate.length > 0){
      this.saveDatasheet();
    }
    else this._dialogRef.close('success');
  }
}
