import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CategoryField } from 'src/app/models/category';
import { DatasheetService } from 'src/app/services/datasheet.service';

@Component({
  selector: 'app-garment-form',
  templateUrl: './garment-form.component.html',
  styleUrls: ['./garment-form.component.scss']
})
export class GarmentFormComponent implements OnInit {
  @Input() standardValues!: FormGroup;

  @Input() categoryFields: CategoryField[] = [];

  constructor(private _datasheetService: DatasheetService) { }

  ngOnInit(): void {
  }

  public getFieldLabel = (fieldCode: string): string => {
    return this._datasheetService.getDatasheet().getCategoryField(fieldCode, this.categoryFields).label;
  }

  getCurrency(){
    return this._datasheetService.getDatasheet().getCurrencySymbol();
  }

}
