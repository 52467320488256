export const environment = {
  name: "Development",
  production: false,
  API_URL          : 'https://fw360-dev.azurewebsites.net',
  API_GQL          : 'https://fw360-dev.azurewebsites.net/api/integration/',
  openidConfigUrl  : 'assets/oidc/config-development.json',
  
  ISA_HOME         : 'https://isa-dev.audaces.com/home',
  ISA_ITEM         : 'https://isa-dev.audaces.com/item/',
  ISA_URL          : 'https://isa360-dev.azurewebsites.net/api/integation/company',
  ISA_COOKIE_TOKEN : 'https://isa360-dev.azurewebsites.net/api/image-token',
  ISA_GRAPHQL      : 'https://isa360-dev.azurewebsites.net/api/graphql',
  ISA_COOKIE_AUTH  : 'https://dev.isa.audaces.com/auth',

  IDEAX_SERVICE    : 'https://audaces-integ-isa-fw-dev.azurewebsites.net',
};