import { DialogRef } from '@angular/cdk/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CategoryField } from 'src/app/models/category';
import { MeasureType } from 'src/app/models/datasheet';
import { Field } from 'src/app/models/field';
import { DefaultMeasures } from 'src/app/models/property';


@Component({
  selector: 'app-custom-field-creator',
  templateUrl: './custom-field-creator.component.html',
  styleUrls: ['./custom-field-creator.component.scss']
})
export class CustomFieldCreatorComponent implements OnInit {
  fieldCreated = new Subject<GeneretedCustomField>();
  multLineOrIntOrNow: boolean = false;
  fieldName = new FormControl('', [Validators.required]);
  isMeasureField: boolean = false;
  selectedMeasureType!: string;
  defaultMeasures = Object.values(DefaultMeasures);

  constructor(
    private _dialog: DialogRef<CustomFieldCreatorComponent>,
    public dialogRef: MatDialogRef<CustomFieldCreatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ){}
  ngOnInit(): void {
    if(this.data){
      this.originalName = this.data.name;
      this.fieldName.patchValue(this.data.name) ;
      this.selectedType = (this.data.type === "Number") ? "FloatingNumber" : this.data.type;
      if(this.data.type == "Number" || this.data.properties.multi_line == true){
        this.multLineOrIntOrNow = true;
      }
      this.lockSelect = true;
      if(this.data.options.length > 0 ){
        this.createList = true;
        for(let i = 0; i < this.data.options.length; i++){
          this.valuesList.push(this.data.options[i]);
        }
      }

      if( this.data.properties?.measure ) {
        this.selectedMeasureType = this.data.properties.measure;
        this.isMeasureField = true;
      }
    }
    this.translateFieldTypes();
  }
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  lockSelect:boolean = false;

  public fieldTypes: { value: string, label: string }[] = [
    { value: 'Text', label: '' },
    { value: 'FloatingNumber', label: '' },
    { value: 'Currency', label: '' },
    { value: 'Color', label: '' },
    { value: 'Boolean', label: '' },
    { value: 'DateTime', label: '' }
  ];

  originalName?: string;
  selectedType: string = 'Text';



  valuesList: string[] = [];

  createList: boolean = false;

  addItem() {
    this.valuesList.push('');
  }

  updateItem(index: number, value: string) {
    this.valuesList[index] = value;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    if (value && !this.valuesList.includes(value)) {
      if (this.selectedType === 'FloatingNumber') {
        if (!isNaN(Number(value))) {
          this.valuesList.push(value);
        }
      } else {
        this.valuesList.push(value);
      }
    }

    if (input) {
      input.value = '';
    }
  }



  edit(item: any, event: MatChipEditedEvent): void {
    const value = event.value.trim();

    if (value && !this.valuesList.includes(value)) {
      const index = this.valuesList.indexOf(item);
      if (index >= 0) {
        this.valuesList[index] = value;
      }
    } else if (!value) {
      this.remove(item);
    }
  }

  remove(item: any): void {
    let array = this.valuesList;

    const index = array.indexOf(item);
    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  generateCustomField(): GeneretedCustomField {
    let oldName;
    if(this.originalName != null){
      oldName = this.originalName
    }
    const customField: GeneretedCustomField = {
      name: this.fieldName.value!,
      type: this.selectedType,
      multLineOrIntOrNow: this.multLineOrIntOrNow,
      values: [],
      oldName: oldName,
    };

    if (this.valuesList.length > 0 &&
      (this.selectedType != "text" &&
       !this.multLineOrIntOrNow &&
       !["Currency", "Color", "Boolean", "DateTime"].includes(this.selectedType))) {
    customField.values = this.valuesList;
    this.valuesList = [];
    }

    if(this.isMeasureField){
      customField.measure = this.selectedMeasureType as DefaultMeasures;
    }

    return customField;
  }

  onSave() {
    if (this.fieldName.invalid) {
      return;
    }
    const newField = this.generateCustomField();
    this.fieldCreated.next(newField);
  }

  translateFieldTypes() {
    this.translate.get('customFieldCreator.fieldType').subscribe((translations: any) => {
      this.fieldTypes = this.fieldTypes.map(fieldType => ({
        value: fieldType.value,
        label: translations[fieldType.value] || fieldType.value
      }));
    });
  }

}



export interface GeneretedCustomField {
  name: string;
  type: string;
  multLineOrIntOrNow: boolean;
  values: string[];
  oldName?: string;
  read_only? : boolean;
  value? : any
  measure?: DefaultMeasures
}

export interface CustomFieldCreatorData {
  parentFields: Field[];
  parentCategoryField: CategoryField[];
  field: GeneretedCustomField;
  
  createdCustomField?: CustomFieldCreated;
  parentFieldsPath?: string;
  parentCategoryFieldPath?: string;
  parentName?: string;
}

export interface CustomFieldCreated {
  field: Field;
  categoryField: CategoryField;
  parentCategoryFieldPath?: string;
  parentName?: string;
}

export interface CustomFieldRemoved {
  fieldName: string;
  parentFieldsPath: string;
  parentCategoryFieldPath: string;
  parentName: string;
}
export interface CustomFieldEdited {
  fieldName: string;
  fieldOldName: string;
  categoryField: CategoryField;
  parentFieldsPath: string;
  parentCategoryFieldPath: string;
  parentName: string;
}

