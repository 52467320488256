import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { combineLatest,  Observable, Subject, take, takeUntil } from 'rxjs';
import { MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions, MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/legacy-checkbox';

import { ReferencesHandlerDialogComponent } from './../references-handler-dialog/references-handler-dialog.component';
import { DialogService } from './../../services/dialog.service';
import { ColumnFilteringService } from './../../services/column-filtering.service';
import { SelectedReference } from 'src/app/models/selectedReference';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { ReferencesService } from 'src/app/services/references.service';
import { ReferenceType } from 'src/app/models/enums/reference-type';
import { ReferenceListDataModel } from 'src/app/models/ReferenceListDataModel';
import { MessageType } from 'src/app/models/enums/message-type';
import { FunctionType } from 'src/app/models/enums/function-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-references-table',
  templateUrl: './references-table.component.html',
  styleUrls: ['./references-table.component.scss'],
  providers: [{provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions}]
})
export class ReferencesTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchTerm: string = '';
  @Input() isLoading: boolean = true;

  MessageType = MessageType;

  private destroy$ = new Subject<boolean>();
  private datasheetUid!:string;

  public columnNames$?: Observable<string[]>;
  public references: any[] = [];

  hasError = false;

  constructor(
    private _datasheetService: DatasheetService,
    private _dialog: DialogService,
    private _columnFiltering: ColumnFilteringService,
    private _referencesServices: ReferencesService,
    private _referenceService: ReferencesService,
    private _translateService: TranslateService,
    ) { }

  ngOnInit(): void {
    this.hasError = false;
    this.datasheetUid = this._datasheetService.getDatasheetUid();
    console.debug(this.datasheetUid)

    this.columnNames$ = this._columnFiltering.getColumnNames();
    this._columnFiltering.columnFilteringChanged.next(true);

    this.getVariantAndType()
      .pipe(takeUntil(this.destroy$))
      .subscribe(values => {console.debug(values),this.setValues(values.referenceType, values.variantId)});

    this._referenceService.getSelectedReferences()
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectedReferences => {
        if (selectedReferences.length == 0) {
          this.references = this.references.map(reference => { return { ...reference, checked: false }});
        }
      });

    }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(): void {

  }

  public setReferenceSeed(value: any) {
    value.checked = !value.checked;

    const selectedReferences = this.references.filter(ref => ref.checked == true);
    const referenceSeeds: SelectedReference[] = selectedReferences.map(ref => { return { referenceId: ref.referenceId, datasheetId: this.datasheetUid } });

    this._referenceService.setSelectedReferences(referenceSeeds);
  }

  public openDialog(reference: any, title:string, functionType: FunctionType){
    const {referenceId, parentId } = reference;
    this._referenceService.setSelectedReferences([{ referenceId: referenceId, datasheetId : this.datasheetUid}]);

    this._dialog.displayDialog(
      ReferencesHandlerDialogComponent, {title: title, function: functionType}, '550' )
      .pipe(take(1))
      .subscribe(()=>{
        // it happens when dialog is closed
        this._referencesServices
        .verifyChanges()
        .pipe(take(1));
      })
  }

  private getVariantAndType(): Observable<any> {
    const referenceType = this._referenceService.getReferenceType();
    const variantId = this._referenceService.getSelectedVariant();

    return combineLatest({referenceType: referenceType, variantId: variantId});
  }

  private setValues(referenceType: ReferenceType, variantId: string): void {
    console.debug(referenceType)
    this.isLoading = true;
    setTimeout(() => {
      this.references = this._datasheetService.getReferenceListDataModel(referenceType, variantId);
      this._columnFiltering.setColumnNames( this.extractColumnNames(this.references, referenceType) ) ;
      this._columnFiltering.columnFilteringChanged.next(true);
      this.isLoading = false;
  }, 1000)
  }

  private extractColumnNames(references: ReferenceListDataModel[], referenceType: ReferenceType): string[] {
    let keys: string[] = ['parentName'];
    for( let rldm of references) {
      for(let field of rldm.reference.fields) {
        if(field.name == 'code' ) {
          continue;
        }

        if(keys.indexOf(field.name) === -1 ) {
          keys.push(field.name)
        }
      }
    }
    return keys;
  }

  public expodeReference(reference: ReferenceListDataModel) {
    let res: any = {
      parentName: reference.parentName
    };

    for(let field of reference.reference.fields) {
      let txt = field.value || field.color?.name;
      if( txt == "true" || txt == "false" ) {
        txt = this._translateService.instant(txt);
      }

      if( typeof txt == 'string' ) {
        res[field.name] = this.formatTimestamp(txt);
      } else {
        res[field.name] = txt;
      }

    }

    return res;
  }

  private isTimestamp(value: string): boolean {
    if (!/^\d+$/.test(value)) {
      return false;
    }
  
    const timestamp = parseInt(value, 10);
  
    const minTimestamp = 100000000; // Aproximadamente 2001
    const maxTimestamp = 4102444800; // Aproximadamente 2100
  
    return timestamp >= minTimestamp && timestamp <= maxTimestamp;
  }
  
  private formatTimestamp(value: string): string {
    if (this.isTimestamp(value)) {
      const date = new Date(parseInt(value, 10) * 1000);
      return date.toLocaleDateString();
    }
    return value;
  }

}
