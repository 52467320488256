<app-login (onAuthorized)="authEvent($event)"></app-login>

<ng-container *ngIf="!isAuthorized">
  <div>
    <div class="loading-page">
      <img src="assets/images/loading.gif" style="margin-left: auto; margin-right: auto" />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isAuthorized">
  <div class="toolbar">
    <app-toolbar-component
    (setDefaultLanguage)="setLanguage($event)"
    [appVersion]="appVersion"
    />
  </div>
  <div class="content">
    <router-outlet *ngIf="!(loading$ | async)"></router-outlet>
  </div>
</ng-container>

<p-confirmDialog  key="myDialog"></p-confirmDialog>

