<div class="drawer-form-container">
  <ng-container *ngIf="formFields">
    <div class="drawer-form__reference-tittle-container">
      <span class="drawer-form__reference-tittle">{{ datasheet.getFieldValue('code', ReferenceData.fields) }}</span>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-tab-group class="drawer-tabs" animationDuration="0ms">
      <mat-tab label="{{ 'referenceEditorDrawerComponent.tabMenu.tabs.info.label' | translate }}">
        <div class="drawer-form__title" style="width: 100%;">
          <button-outline *ngIf="datasheet.isStandAlone()"  (click)="createCustomField()" [height]="30" [color]="'primary'">
            {{'shared.actions.addCustomField.label' | translate}}
          </button-outline>
          <p class="drawer-form__forms-tittle">
            {{ 'referenceEditorDrawerComponent.forms.defaultFields.label' | translate }}
          </p>
          <div class="model-form" [formGroup]="formFields">
            <div *ngFor="let field of defaultFields" style="width: 90%;" >
              <mat-form-field *ngIf="hasOptions(field); else input" appearance="outline" class="drawer-form__field">
                <mat-label>{{ field.name | translate }}</mat-label>
                <mat-select disableRipple
                  panelClass="model-form__panel isa-med-elevation" disableOptionCentering formControlName="{{field.name}}"
                  matInput>
                  <mat-option [value]="option" *ngFor="let option of field.options">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
              <ng-template #input>
                <ng-container [ngSwitch]="getFieldType(field)">
                  <ng-container *ngSwitchCase="'Color'">
                    <app-color-picker [form_Group]="formFields" [field]="field" class="drawer-form__field"></app-color-picker>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Currency'">
                    <mat-form-field appearance="outline" class="drawer-form__field">
                      <mat-label>{{ field.name | translate }}</mat-label>
                      <input [formControlName]="field.name" matInput
                        type="text"
                        formatDecimal
                        [value]="formFields.get(field.name)?.value"
                      />
                      <span style="color: grey" matSuffix> {{getCurrency()}}</span>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Text'" >
                    <mat-form-field appearance="outline" class="drawer-form__field">
                      <mat-label>{{ field.name | translate }}</mat-label>
                      <input [formControlName]="field.name" matInput type="text"/>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngSwitchCase="'FloatingNumber'">
                    <mat-form-field appearance="outline" class="drawer-form__field">
                      <mat-label>{{ field.name | translate }}</mat-label>
                      <input [formControlName]="field.name" matInput
                        type="text"
                        formatDecimal
                        [value]="formFields.get(field.name)?.value"
                      />
                      <span style="color: grey" *ngIf="field.properties?.measure" matSuffix>{{getMeasureSymbol(field.properties?.measure!)}}</span>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Number'">
                    <mat-form-field appearance="outline" class="drawer-form__field">
                      <mat-label>{{ field.label! | translate }}</mat-label>
                      <input [formControlName]="field.name" matInput
                        type="text"
                        formatDecimal
                        [value]="formFields.get(field.name)?.value"
                      />
                      <span style="color: grey" *ngIf="field.properties?.measure" matSuffix>{{getMeasureSymbol(field.properties?.measure!)}}</span>
                    </mat-form-field>
                  </ng-container>
                  <input appearance="outline" [formControlName]="field.name" matInput type="text"
                  *ngSwitchDefault />
                </ng-container>
              </ng-template>
            </div>
          </div>

          <ng-container  *ngIf="showCustomFields">
            <p class="drawer-form__forms-tittle">
              {{ 'referenceEditorDrawerComponent.forms.customFields.label' | translate }}
            </p>
            <div style="width: 90%;">
              <app-custom-fields-form  *ngIf="showCustomFields"
              [values]="formCustomFields"
              [valuesWithOptions]="customFields"
              (editFieldEvent)="onEditField($event)"
              (deleteFieldEvent)="onDeleteField($event)"
              ></app-custom-fields-form>
            </div>
          </ng-container>
        </div>

        <div class="drawer-form__title" style="width: 100%;" *ngIf="groupFields.length>0">
          <div class="model-form" [formGroup]="formGroupFields">
            <div *ngFor="let field of groupFields" style="width: 90%;" >

              <p class="drawer-form__forms-tittle" *ngIf="field.name.indexOf('code',0) == 0">
                {{ field.value }}
              </p>
              <mat-form-field *ngIf="hasOptions(field); else input" appearance="outline" class="drawer-form__field">
                <mat-label>{{ field.label! | translate }}</mat-label>
                <mat-select disableRipple
                  panelClass="model-form__panel isa-med-elevation" disableOptionCentering formControlName="{{field.name+'-'+field.value}}"
                  matInput>
                  <mat-option [value]="option" *ngFor="let option of field.options">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
              <ng-template #input>
                <ng-container [ngSwitch]="getFieldType(field)">
                  <ng-container *ngSwitchCase="'Color'">
                    <app-color-picker [form_Group]="formFields" [field]="field" class="drawer-form__field"></app-color-picker>
                  </ng-container>
                  <ng-container *ngSwitchDefault >
                    <mat-form-field appearance="outline" class="drawer-form__field">
                      <mat-label>{{ field.label! | translate }}</mat-label>
                      <input [formControlName]="field.name+'-'+field.value" matInput type="text"/>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="{{ 'referenceEditorDrawerComponent.tabMenu.tabs.sample.label' | translate }}" *ngIf="imageList.length">
        <div class="drawer-form__title">
          <div class="model-form" [formGroup]="formImages">
            <div class="images" *ngFor="let img of imageList" class="drawer-form__field"
              style="display: flex; align-items: center; justify-content: center; padding-bottom: 15px;">
              <img src="{{ img.url! | appRemoveS3Link }}" />
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div>
    </div>
  </ng-container>
</div>
