import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
  @Input() numberOfLines: number = 3; // Default value: 3 lines
  @Input() height: string = '30px'; // Default value: 30px height
  @Input() skeletonType: string = 'default';

  getLinesArray(): number[] {
    return Array.from({ length: this.numberOfLines }).map((_, i) => i);
  }

  calculateOpacity(line: number): number {
    if(this.numberOfLines === 1) {
      return 1;
    }
    return (1 - (line * (1/(this.numberOfLines))));
  }
}
