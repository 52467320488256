<div class="history-container">
  <ng-container *ngIf="isLoading">
    <app-skeleton-loader [numberOfLines]="10" height="90px"></app-skeleton-loader>
  </ng-container>
  <ng-container *ngIf="!isLoading && HttpError">
    <app-mensage [messageType]="MessageType.httpError" [isProcessing]="isProcessing"
      (retryClicked)="retryFunction()"></app-mensage>
  </ng-container>
  <ng-container *ngIf="!isLoading && !HttpError">
    <div class="history-container__tittle-container">
      <h2 class="drawer-form__reference-tittle">{{ 'historyDrawerComponent.label' | translate }}</h2>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="history-container__filter-container">
      <mat-form-field class="history-container__filter-container__user" appearance="outline">
        <mat-label>{{ 'historyDrawerComponent.userSelect.label' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedUser" panelClass="model-form__panel isa-med-elevation" disableOptionCentering>
          <mat-option [value]='undefined'>{{ 'historyDrawerComponent.userSelect.options.all' | translate }}</mat-option>
          <mat-option *ngFor="let user of uniqueUsers" [value]="user">{{user}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="history-container__filter-container__date initial" appearance="outline">
        <mat-label>{{ 'shared.inputs.initialDate.label' | translate }}</mat-label>
        <input matInput [matDatepicker]="startPicker" placeholder="Data de início" [(ngModel)]="startDate"
          (ngModelChange)="onStartDateChange()">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="isMobileView" #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="history-container__filter-container__date final" appearance="outline">
        <mat-label>{{ 'shared.inputs.finalDate.label' | translate }}</mat-label>
        <input matInput [matDatepicker]="endPicker" [min]="startDate" placeholder="Data de fim" [(ngModel)]="endDate"
          [disabled]="!startDate ">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="isMobileView" #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="history-container__history"
      *ngFor="let history of historyData | filterHistory:selectedUser:startDate:endDate ; let i=index"
      [ngClass]="i%2 === 0 ? 'even' : 'odd'">
      <div class="history-container__history__inner">
        <div class="userInfo">
          <div class="userInfo__details">
            <div class="userInfo__details__contIcon">

              <mat-icon class="userInfo__details__icon">account_circle</mat-icon>
            </div>
            <span class="userInfo__details__user-name">{{history.user}}</span>
          </div>
          <span class="date">{{history.date | date:'dd/MM/yyyy HH:mm' }}</span>

        </div>
        <ng-container *ngFor="let operation of history.operations">
          <ng-container *ngIf="operation">

            <ng-container *ngIf="operation.operationType === 'removeOperation'">
              <div class="history-container__history__data">
                <span class="actions">{{'historyDrawerComponent.operations.remove.message' | translate }} </span> 
                <span class="references">{{getFieldLabel(operation.baseEntity)}}</span>
                <span class="actions">{{ operation.targetType === 'Modelo' ? ( 'historyDrawerComponent.operations.origin.isGarment' | translate ) : (
                  'historyDrawerComponent.operations.origin.noGarment' | translate )}}
                  {{operation.targetType === 'Modelo' ? ( 'historyDrawerComponent.operations.type.garment' | translate )
                  : (operation.targetType === 'Variant' ? ( 'historyDrawerComponent.operations.type.variant' | translate
                  ) : ( 'historyDrawerComponent.operations.type.subVariant' | translate ))}}
                </span> 
                <span class="references"> {{operation.targetName }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="operation.operationType === 'removeCustomFieldOperation'">
              <div class="history-container__history__data">
                <span class="actions">{{'historyDrawerComponent.operations.removeCusFil.message' | translate }} </span> 
                <span class="references">{{getFieldLabel(operation.baseEntity)}}</span> 
                <span class="actions">
                  {{ operation.targetType === 'Modelo' ? ( 'historyDrawerComponent.operations.origin.isGarment' | translate ) : (
                  'historyDrawerComponent.operations.origin.noGarment' | translate )}}
                  {{operation.targetType === 'Modelo' ? ( 'historyDrawerComponent.operations.type.garment' | translate )
                  : (operation.targetType === 'Variant' ? ( 'historyDrawerComponent.operations.type.variant' | translate
                  ) : ( 'historyDrawerComponent.operations.type.subVariant' | translate ))}}
                </span>
                <span class="references"> {{operation.targetName }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="operation.operationType === 'editedCustomFieldOperation'">
              <div class="history-container__history__data">
                <span class="actions">{{'historyDrawerComponent.operations.editCusFil.message' | translate }} </span> 
                <span class="references">{{operation.oldValue}}</span> 
                <span class="actions"> {{ 'historyDrawerComponent.operations.target.to' | translate }} </span>
                <span class="references"> {{operation.newValue }}</span>
                <span class="actions">

                  {{ operation.seedType === 'Modelo' ? ( 'historyDrawerComponent.operations.origin.isGarment' | translate ) : ''  }}
                  {{ operation.seedType === 'garment' ? ( 'historyDrawerComponent.operations.origin.isGarment' | translate ) : ''  }}
                  {{ operation.seedType === 'variants' ? ( 'historyDrawerComponent.operations.type.variant' | translate ) : ''  }}
                  {{ operation.seedType === 'variant' ? ( 'historyDrawerComponent.operations.type.variant' | translate ) : ''  }}
                  {{ operation.seedType === 'reference' ? ( 'historyDrawerComponent.operations.edit.model.reference' | translate ) : ''  }}
                </span>
                <span class="references"> {{operation.seedName }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="operation.operationType === 'copyOperation'">
              <div class="history-container__history__data"><span class="actions">{{
                  'historyDrawerComponent.operations.copy.message' | translate }}</span> <span class=" references">{{
                    getFieldLabel(operation.baseEntity)
                  }}</span> <span class="actions"> {{ operation.seedType === 'Modelo' ? (
                  'historyDrawerComponent.operations.origin.isGarment' | translate ) : (
                  'historyDrawerComponent.operations.origin.noGarment' | translate )}}
                  {{operation.targetType === 'Modelo' ? ( 'historyDrawerComponent.operations.type.garment' | translate )
                  : (operation.targetType === 'Variant' ? ( 'historyDrawerComponent.operations.type.variant' | translate
                  ) : ( 'historyDrawerComponent.operations.type.subVariant' | translate ))}}</span>
                <span class="references">{{ operation.seedName }}</span> <span class="actions"> {{ operation.targetType
                  === 'Modelo' ? ( 'historyDrawerComponent.operations.target.isGarment' | translate ) : (
                  'historyDrawerComponent.operations.target.noGarment' | translate )}}
                  {{operation.targetType === 'Modelo' ? ( 'historyDrawerComponent.operations.type.garment' | translate )
                  : (operation.targetType === 'Variant' ? ( 'historyDrawerComponent.operations.type.variant' | translate
                  ) : ( 'historyDrawerComponent.operations.type.subVariant' | translate ))}}
                </span> <span class="references">{{ operation.targetName }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="operation.operationType === 'addOperation' || operation.operationType === 'addVarOperation' || operation.operationType === 'editVarOperation'  ">
              <div class="history-container__history__data">
                <span class="actions" *ngIf="operation.operationType === 'addOperation'">    {{ 'historyDrawerComponent.operations.add.message' | translate }} </span> 
                <span class="actions" *ngIf="operation.operationType === 'addVarOperation'"> {{ 'historyDrawerComponent.operations.addVar.message' | translate }} </span> 
                <span class="actions" *ngIf="operation.operationType === 'editVarOperation'">{{ 'historyDrawerComponent.operations.edtVar.message' | translate }} </span> 
                <span class=" references"> {{ operation.baseEntity }} </span> 
                <span class="actions">
                  <ng-container *ngIf="operation.seedType === 'Modelo'; else noGarment">
                    <span> 
                      {{ 'historyDrawerComponent.operations.add.isGarment' | translate }}
                      <span class="references"> {{'historyDrawerComponent.operations.type.garment'| translate}} </span>
                    </span>
                  </ng-container>
                  <ng-template #noGarment>
                    <span  *ngIf="!(operation.seedName == operation.targetType && operation.seedName == operation.seedType)">
                      {{ 'historyDrawerComponent.operations.add.noGarment' | translate }} {{'historyDrawerComponent.operations.type.variant'| translate}}
                    </span>
                  </ng-template>
                </span>
                <span *ngIf="operation.seedType != 'Modelo'" class="references">{{ operation.targetName }}</span>
              </div>
            </ng-container>
            
            <ng-container *ngIf="operation.operationType === 'addImage' || operation.operationType === 'moveImage' || operation.operationType === 'removeImage'  ">
              <div class="history-container__history__data">
                <span class="actions" *ngIf="operation.operationType === 'addImage'">    {{ 'historyDrawerComponent.operations.addImage' | translate }} </span> 
                <span class="actions" *ngIf="operation.operationType === 'moveImage'"> {{ 'historyDrawerComponent.operations.moveImage' | translate }} </span> 
                <span class="actions" *ngIf="operation.operationType === 'removeImage'">{{ 'historyDrawerComponent.operations.removeImage' | translate }} </span> 
              </div>
            </ng-container>

            <ng-container *ngIf="operation.operationType === 'addCustomFieldOperation'">
              <div class="history-container__history__data">
                <span class="actions"> {{ 'historyDrawerComponent.operations.addCusFil.message' | translate }} </span> 
                <span class=" references"> {{ operation.baseEntity }} </span> 
                <span class="actions">
                  <ng-container *ngIf="operation.seedType === 'garment'; else noGarment">
                    <span> 
                      {{ 'historyDrawerComponent.operations.addCusFil.isGarment' | translate }}
                      <span class="references"> {{'historyDrawerComponent.operations.type.garment'| translate}} </span>
                    </span>
                  </ng-container>
                  <ng-template #noGarment>
                    <span>
                      {{ 'historyDrawerComponent.operations.addCusFil.noGarment' | translate }} {{'historyDrawerComponent.operations.type.variant'| translate}}
                    </span>
                  </ng-template>
                </span>
                <span *ngIf="operation.seedName != ''" class="references"> {{ operation.seedName }} </span>
              </div>
            </ng-container>

            <ng-container *ngIf="operation.operationType === 'moveOperation'">
              <div class="history-container__history__data"><span class="actions">{{
                  'historyDrawerComponent.operations.move.message' | translate }}</span> <span class=" references">{{
                    getFieldLabel(operation.baseEntity)
                  }}</span> <span class="actions"> {{ operation.seedType === 'Modelo' ? (
                  'historyDrawerComponent.operations.origin.isGarment' | translate ) : (
                  'historyDrawerComponent.operations.origin.noGarment' | translate )}} {{
                  operation.seedType }}</span> <span class="references">{{ operation.seedName }}</span> <span
                  class="actions"> {{ operation.targetType === 'Modelo' ? (
                  'historyDrawerComponent.operations.move.target.isGarment' | translate ) : (
                  'historyDrawerComponent.operations.target.noGarment' | translate )}}
                  {{ operation.targetType }}</span> <span class="references">{{ operation.targetName }}</span></div>
            </ng-container>
            <ng-container *ngIf="operation.operationType === 'editOperation' && operation.seedType != 'reference'">
              <div class="history-container__history__data">
                <span class="actions">{{'historyDrawerComponent.operations.edit.message' | translate }} </span> 
                <span class="references">{{getFieldLabel(operation.baseEntity)}}</span>
                <span *ngIf="operation.oldValue" class="actions">{{'historyDrawerComponent.operations.origin.from' | translate }}</span> 
                <span class="references">{{ (+operation.oldValue > 999999000) ? (operation.oldValue | date:'dd/MM/yyyy, HH:mm') : operation.oldValue   }}  </span>
                <span class="actions">{{ 'historyDrawerComponent.operations.target.to' | translate }}</span> 
                <span class="references">{{ (+operation.newValue > 999999000) ? (operation.newValue | date:'dd/MM/yyyy, HH:mm') : operation.newValue   }}, </span>
                <span class="actions"> 
                  <ng-container *ngIf="operation.seedType === 'garment'; else notGarment">
                    <span >{{ 'historyDrawerComponent.operations.add.isGarment' | translate }} <span class="references">{{'historyDrawerComponent.operations.type.garment'| translate}}</span></span>
                  </ng-container>
                  <ng-template #notGarment>
                    {{ 'historyDrawerComponent.operations.add.noGarment' | translate }} {{'historyDrawerComponent.operations.type.variant'| translate}}
                  </ng-template>
                </span>
                <span class="references" *ngIf="operation.seedName !== 'garment'"> {{
                  operation.seedName }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="operation.operationType === 'editOperation' && operation.seedType == 'reference'">
              <div class="history-container__history__data">
                <span class="actions">{{'historyDrawerComponent.operations.edit.message' | translate }} </span> 
                <span class="references">{{getFieldLabel(operation.baseEntity)}}</span> 
                <span class="actions">{{ 'historyDrawerComponent.operations.edit.model.reference' | translate }}</span>
                <span class="references">{{operation.insertedEntity}}</span> 
                <span class="references">{{operation.refName}}</span>
                <span *ngIf="operation.oldValue" class="actions">{{ 'historyDrawerComponent.operations.origin.from' | translate }}</span> 
                <span class="references">{{ (+operation.oldValue > 999999000) ? (operation.oldValue*1000  | date:'dd/MM/yyyy') : operation.oldValue }}</span> 
                <span class="actions">{{'historyDrawerComponent.operations.target.to' | translate }}</span>
                <span class="references">{{ (+operation.newValue > 999999000) ? (operation.newValue*1000 | date:'dd/MM/yyyy') : operation.newValue }}, </span>
                <ng-container>

                    <ng-container *ngIf="operation.seedName === 'garment'; else notGarment">
                      <span >{{ 'historyDrawerComponent.operations.add.isGarment' | translate }} <span class="references">{{'historyDrawerComponent.operations.type.garment'| translate}}</span></span>
                    </ng-container>
                    <ng-template #notGarment>
                      {{ 'historyDrawerComponent.operations.add.noGarment' | translate }} {{'historyDrawerComponent.operations.type.variant'| translate}}
                    </ng-template>

                  <span *ngIf="operation.seedName != 'garment'" class="references">{{ operation.seedName }}</span>
                </ng-container>
              </div>
            </ng-container>
            <ng-container
              *ngIf="operation.operationType !== 'removeOperation' && operation.operationType !== 'copyOperation' && operation.operationType !== 'moveOperation' && operation.operationType !== 'editOperation' && operation.operationType !== 'addOperation' && operation.operationType !== 'addCustomFieldOperation' && operation.operationType !== 'removeCustomFieldOperation' && operation.operationType !== 'addVarOperation' && operation.operationType !== 'editVarOperation' && operation.operationType !== 'addImage' && operation.operationType !== 'moveImage' && operation.operationType !== 'removeImage' && operation.operationType !== 'editedCustomFieldOperation'">
              {{ 'historyDrawerComponent.operations.unknown.message' | translate }} {{ operation.operationType }}.
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
