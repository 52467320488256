<div class="editor__container" [ngClass]="{'nopic' : imagesUrl.length == 0}">
  <div class="slide">
    <div class="keen-slider" #sliderRef>
      <div class="keen-slider__slide" [ngClass]="'number-slide' + (i + 1)"
        *ngFor="let slide of imagesUrl; let i = index">
        <div class="slide_container">
          <div *ngIf="isLoadingImg" slide_container__slide>
            <app-skeleton-loader [numberOfLines]="1" height="100%"></app-skeleton-loader>
          </div>
          <img class="slide_container__slide" [src]="slide.url! | appRemoveS3Link" [ngStyle]="{ 'display': isLoadingImg ? 'none' : 'block' }" (load)="hideImageLoader()" (error)="onImageError($event)">
        </div>
      </div>
    </div>
    <div class="footer__container" *ngIf="isStandAlone">
      <div (click)="onAddImage()" style="border: none;">
        <button-misc [disabled]="isLoading" [isLoading]="isLoading" >
          <mat-icon *ngIf="!isLoading" class="style-icon">add_circle_outline</mat-icon>
          <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none" accept="image/*">
        </button-misc>
      </div>
      <div [ngClass]="isEditionMode ? 'selected' : ''">
        <button-misc (click)="onEditionMode()">
          <mat-icon class="style-icon">edit</mat-icon>
        </button-misc>
      </div>
    </div>
  </div>
</div>
<div class="thumb_container">
  
  <mat-icon *ngIf="imagesUrl.length > 4" #prevButton class="nav-button prev" (click)="thumbnailSlider.prev()">chevron_left</mat-icon>

  <div class="keen-slider thumbnail" #thumbnailRef [ngClass]="isEditionMode ? 'h150' : 'h100'">
    <div class="keen-slider__slide" [ngClass]="'number-slide' + (i + 1)" *ngFor="let slide of imagesUrl; let i = index"  style="overflow: auto !important;">
      <div class="thumb_card">
        <img class="thumb_card__img" [src]="slide.url! | appRemoveS3Link" [ngStyle]="{ 'display': isLoadingImg ? 'none' : 'block' }" (load)="hideImageLoader()" (error)="onImageError($event)">

        <span class="actions_container" *ngIf="isEditionMode">
          <mat-icon class="actions" (click)="onMove(i, -1)">keyboard_arrow_left</mat-icon>
          <mat-icon class="actions"
          (click)="onDelete(slide.id)"
          [title]="'imageEditor.canDelete' | translate"
          >delete</mat-icon>

          <mat-icon class="actions" (click)="onMove(i, 1)">keyboard_arrow_right</mat-icon>
        </span>
      </div>
    </div>
  </div>

  <mat-icon *ngIf="imagesUrl.length > 4" #nextButton class="nav-button next" (click)="thumbnailSlider.next()">chevron_right</mat-icon>
</div>
