<div class="skeleton-container">
  <ng-container  *ngIf="skeletonType === 'default'" >
    <div *ngFor="let line of getLinesArray()">
      <ngx-skeleton-loader count="1" [theme]="{
          'border-radius': '5px',
          'height': height,
          'background':'#DCE0F3'
        }" [ngStyle]="{ 'opacity': calculateOpacity(line) }"></ngx-skeleton-loader>
    </div>
  </ng-container>
  <div *ngIf="skeletonType === 'properties-editor'" class="properties-editor">
    <div class="properties-editor_caroussel">
      <div class="properties-editor_caroussel_content">
        <ngx-skeleton-loader count="1" [theme]="{
        'height': '480px',
        'border-radius': '5px',
        'background':'#DCE0F3'
      }">
        </ngx-skeleton-loader>
        <div class="properties-editor_caroussel_content_thumb">
          <ngx-skeleton-loader count="1" [theme]="{
          'height': '110px',
          'width' : '120px',
          'border-radius': '5px',
          'background':'#DCE0F3',
          'margin-right': '25px',
        }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{
          'height': '110px',
          'width' : '120px',
          'border-radius': '5px',
          'background':'#DCE0F3',
          'margin-right': '25px'
        }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{
          'height': '110px',
          'width' : '120px',
          'border-radius': '5px',
          'background':'#DCE0F3',
          'margin-right': '25px'
        }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{
          'height': '110px',
          'width' : '120px',
          'border-radius': '5px',
          'background':'#DCE0F3',
        }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="properties-editor_form">
      <div class="properties-editor_form_content">
        <div class="properties-editor_form_content_header">
          <div class="properties-editor_form_content_header_tittle">
            <ngx-skeleton-loader [theme]="{
              'border-radius': '50%',
              'width': '60px',
              'height': '60px',
              'background':'#DCE0F3'
            }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
              'border-radius': '5px',
              'width': '150px',
              'background':'#DCE0F3',
              'margin-left': '20px',
              'margin-bottom':'20px',
              'height': '38px'
            }">
            </ngx-skeleton-loader>
          </div>
          <div class="properties-editor_form_content_header_buttons">
            <ngx-skeleton-loader [theme]="{
              'border-radius': '5px',
              'width': '150px',
              'background':'#DCE0F3',
              'margin-left': '20px',
              'height': '38px'
              }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
              'border-radius': '5px',
              'width': '50px',
              'background':'#DCE0F3',
              'margin-left': '20px',
              'height': '38px'
              }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
              'border-radius': '5px',
              'width': '50px',
              'background':'#DCE0F3',
              'margin-left': '20px',
              'height': '38px'
              }">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="properties-editor_form_content_stepper">
          <div class="properties-editor_form_content_stepper_tittle">
            <ngx-skeleton-loader [theme]="{
                'border-radius': '50%',
                'width': '24px',
                'background':'#DCE0F3',
                'margin-left': '20px',
                'height': '24px'
                }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
                'border-radius': '5px',
                'width': '150px',
                'background':'#DCE0F3',
                'margin-left': '20px',
                'height': '30px'
                }">
            </ngx-skeleton-loader>
          </div>
        </div>
        <div class="properties-editor_form_content_stepper_content">
          <ngx-skeleton-loader count="4" [theme]="{
                'border-radius': '5px',
                'background':'#DCE0F3',
                'margin-left': '40px',
                'height': '38px',
                'width':'95%'
                }">
          </ngx-skeleton-loader>
          <div class="properties-editor_form_content_stepper_content_toggler">
            <ngx-skeleton-loader [theme]="{
              'border-radius': '50%',
              'background':'#DCE0F3',
              'margin-left': '40px',
              'height': '24px',
              'width': '24px'
              }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{
            'border-radius': '5px',
            'background':'#DCE0F3',
            'margin-left': '25px',
            'height': '30px',
            'width': '150px'
            }">
            </ngx-skeleton-loader>
          </div>
          <ngx-skeleton-loader [theme]="{
          'border-radius': '5px',
          'background':'#DCE0F3',
          'margin-left': '40px',
          'min-height': '23dvh',
          'max-height': '25vh',
            'width': '95%'
          }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="skeletonType === 'advanced-editor'" class="advanced-editor">
    <div class="advanced-editor_header">
      <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '99%',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
    </div>
    <div class="advanced-editor_controls">
      <div class="advanced-editor_controls_select">
        <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '350px',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
      </div>
      <div class="advanced-editor_controls_buttons">
        <ngx-skeleton-loader count="7" [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '80px',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="advanced-editor_body">
      <div class="advanced-editor_body_refs">
        <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'height': '350px',
        'background':'#DCE0F3',
        'margin-left':'10px',
        'width' : '150px'
      }"></ngx-skeleton-loader>
      </div>
      <div class="advanced-editor_body_table">
        <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'background':'#DCE0F3',
        'width' : '100%',
        'height': '100%',
      }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div *ngIf="skeletonType === 'references-table'" class="references-table">
    <ngx-skeleton-loader [theme]="{
      'border-radius': '5px',
      'background':'#DCE0F3',
      'min-height': '68vh',
      'max-height': '950px',
      'width': '100%'
      }">
      </ngx-skeleton-loader>
  </div>

  <div *ngIf="skeletonType === 'update-ref'">
    <div class="update-ref_controls">
      <div class="update-ref_controls_select">
        <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '350px',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
      </div>
      <div class="update-ref_controls_buttons">
        <ngx-skeleton-loader count="1" [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '80px',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="update-ref_body_table">
      <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'background':'#DCE0F3',
        'min-height': '400px',

        }">
        </ngx-skeleton-loader>
    </div>
    <div class="update-ref_confirm">

      <div >
        <ngx-skeleton-loader count="2" [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '150px',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
      </div>
    </div>

  </div>

  <div *ngIf="skeletonType === 'isa_dialog'">
    <div class="isa-dialog">
    <ngx-skeleton-loader [theme]="{
      'border-radius': '5px',
      'height': '38px',
      'background':'#DCE0F3',
      'width': '350px',
      'margin-left':'10px'
    }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{
        'border-radius': '5px',
        'height': '38px',
        'background':'#DCE0F3',
        'width': '350px',
        'margin-left':'10px'
      }"></ngx-skeleton-loader>
    <div class="isa-dialog_cards">
    <ngx-skeleton-loader [theme]="{
      'border-radius': '5px',
      'height': '350px',
      'background':'#DCE0F3',
      'width': '350px',
      'margin-left':'10px'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      'border-radius': '5px',
      'height': '350px',
      'background':'#DCE0F3',
      'width': '350px',
      'margin-left':'10px'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      'border-radius': '5px',
      'height': '350px',
      'background':'#DCE0F3',
      'width': '350px',
      'margin-left':'10px'
    }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{
      'border-radius': '5px',
      'height': '350px',
      'background':'#DCE0F3',
      'width': '350px',
      'margin-left':'10px'
    }"></ngx-skeleton-loader>
      </div>

    </div>
  </div>

  <!-- </div> -->
