import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FunctionType } from 'src/app/models/enums/function-type';
import { MessageType } from 'src/app/models/enums/message-type';
import { ReferencesService } from 'src/app/services/references.service';

@Component({
  selector: 'app-references-handler-dialog',
  templateUrl: './references-handler-dialog.component.html',
  styleUrls: ['./references-handler-dialog.component.scss'],
})
export class ReferencesHandlerDialogComponent implements OnInit, OnDestroy {
  public data$: any;
  private destroy$ = new Subject<boolean>();
  isLoading?: boolean = true;
  HttpError?: boolean = false;
  isProcessing!: boolean ;

  MessageType = MessageType;

  public searchTerm!: string;

  private selectedVariants: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _referencesService: ReferencesService,
    private _dialogRef: MatDialogRef<ReferencesHandlerDialogComponent>
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.getTargetsLists();
  }

  public selectedVariantHandle(value: any): void {
    value.checked = !value.checked;
    if (this.selectedVariants.includes(value.target_uid)) {
      this.selectedVariants.splice(
        this.selectedVariants.indexOf(value.target_uid),
        1
      );
    } else {
      this.selectedVariants.push(value.target_uid);
    }
  }

  public recieveSearchTerm(event: any) {
    this.searchTerm = event;
  }

  public referencesHandler(functionType: FunctionType) {
    switch (functionType) {
      case FunctionType.move:
        this.isLoading = true;
        this.isProcessing = true
        var moveWasSuccessful = this._referencesService
          .moveReferences(this.selectedVariants)
          .pipe(take(1))
          .subscribe(() => {
            this.isLoading = false;
            this.isProcessing = false
            this._dialogRef.close();
          });
        break;
      case FunctionType.copy:
        this.isLoading = true;
        this.isProcessing = true;
        this._referencesService
          .copyReferences(this.selectedVariants)
          .pipe(take(1))
          .subscribe(() => {
            this.isLoading = false;
            this.isProcessing = false;
            this._dialogRef.close();
          });
        break;
    }
  }

  private getTargetsLists() {
    switch (this.data.function) {
      case FunctionType.move:
        this._referencesService
          .listPossibleTargetsToMoveAReference()
          .subscribe({
            next: (x) => {
              this.handleHttpSucess(x);
            },
            error: (error) => {
              console.debug('Erro ao obter possíveis alvos para mover:', error);
              this.handleHttpError();
            }
          });
        break;
      case FunctionType.copy:
        this._referencesService
          .listPossibleTargetsToCopyAReference()
          .subscribe({
            next: (x) => {
              this.handleHttpSucess(x);
            },
            error: (error) => {
              console.debug('Erro ao obter possíveis alvos para copiar:', error);
              this.handleHttpError();
            }
          });
        break;
    }
  }

  public retryFunction(){
    this.isProcessing = true;
    this.getTargetsLists();
  }

  private handleHttpError() {
    this.data$ = null;
    this.isLoading = false;
    this.HttpError = true;
    this.isProcessing = false
  }

  private handleHttpSucess(data: any){
    this.data$ = data
    this.isLoading = false;
    this.HttpError = false;
    this.isProcessing = false;
  }
}

