import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterHistory'
})
export class FilterHistoryPipe implements PipeTransform {
  transform(historyData: any[], selectedUser?: string , startDate?: Date, endDate?: Date): any[] {

    const ONE_SECOND_MS = 1000;

    if (endDate) {
      endDate.setSeconds(endDate.getSeconds() + 1);
    }

    if (!historyData) {
      return historyData;
    }
    return historyData.filter(history => {
      if (!history) {
        return false;
      }
    
      const isUserMatched = !selectedUser || history.user === selectedUser;
      const isStartDateMatched = !startDate || new Date(history.date) >= startDate;
      const isEndDateMatched = !endDate || new Date(history.date) <= new Date(endDate.getTime() + 86400000);
    
      return isUserMatched && isStartDateMatched && isEndDateMatched;
    });
  }

}
