import { Subscription, take } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Datasheet } from 'src/app/models/datasheet';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { StatusType } from 'src/app/models/enums/status-type';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationDialogComponent } from '../configuration-dialog/configuration-dialog.component';

@Component({
  selector: 'app-toolbar-component',
  templateUrl: './toolbar-component.component.html',
  styleUrls: ['./toolbar-component.component.scss']
})
export class ToolbarComponentComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  public isDrawerOpened: boolean = false;
  availableLanguages = [
    { ref: 'Portuguese', code: 'pt-BR' },
    { ref: 'English', code: 'en-US' },
    { ref: 'Spanish', code: 'es-ES' },
    { ref: 'Italian', code: 'it-IT' }
  ];
  @Input() isHome?:boolean;

  @Input() modelName: string = '';
  @Input() status:string = '';

  @Input() userName = '';
  @Input() userMail = '';
  @Input() userPicture = '';

  @Input() appVersion: string = '';

  currentLanguage: string = '';
  @Output() setDefaultLanguage = new EventEmitter<string>();
  userEnterprise = '';

  uid: string = '';
  lastSavedDate: string = '';

  isLoading: boolean = false;
  failed: boolean = false;

  private subcptRoute?: Subscription;
  private subcptDatasheet?: Subscription;
  private subcptStatus?: Subscription;
  private subcptPropSaved?: Subscription;

  constructor(
    public _loginService: LoginService,
    private _datasheetService: DatasheetService,
    private _localStorageService: LocalStorageService,
    private _route: ActivatedRoute,
    private router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _confirmationService: ConfirmationService,
    private _translate: TranslateService,
    private _dialog: MatDialog,
  ) {
    iconRegistry.addSvgIcon('audaces-profile', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/audaces-icon-profile.svg'));
  }

  ngOnInit(): void {
    this.uid = this._datasheetService.getDatasheetUid();
    this.subcptRoute = this._route.params.subscribe(
      (params: Params) => {
        if( params['uid'] !== undefined ) {
          this.uid = params['uid'];
        }
      }
    );

    this.router.events
      .subscribe((event) => {
        if( event instanceof NavigationEnd) {
          if (event.urlAfterRedirects === '/') {
            this.uid = '';
          }
        }
      });

    this.getCurrentLanguage();

    this.subcptDatasheet = this._datasheetService.dataSheetChanged
      .pipe(take(1))
      .subscribe(dt => {
        const datasheet = new Datasheet( dt );
        this.uid = dt.fw?.datasheet_uid||'';
        this.lastSavedDate = dt.last_modification_date;
        this.modelName = String(datasheet.getFieldValue('code', datasheet.garment?.fields));
      });

    this.subcptStatus = this._datasheetService.statusChanged
      .subscribe(status => {
        this.status = status;
        const dt = this._datasheetService.getDatasheet();
        this.uid = dt.fw?.datasheet_uid||'';
        this.lastSavedDate = dt.last_modification_date;
      });

    this.userName = this._loginService.getCurrentUserName();
    this.userMail = this._loginService.getCurrentUserMail();
    this.userPicture = this._loginService.getCurrentUserImage();
    this.userEnterprise = JSON.parse(this._localStorageService.get(LocalStorageKeys.USER_CURRENT_COMPANY)).name;

    this.subcptPropSaved = this._datasheetService.datasheetPropsSaved
      .subscribe(status => {
        this.isLoading = (status == 'start');
        this.failed = status == 'fail';
        this.lastSavedDate = new Date().toISOString();
      });
  }

  ngOnDestroy() {
    this.subcptRoute?.unsubscribe();
    this.subcptDatasheet?.unsubscribe();
    this.subcptStatus?.unsubscribe();
    this.subcptPropSaved?.unsubscribe();
  }

  public logout() {
    this._loginService.DoLogout();
  }

  onLogout() {
    this.logout();
  }

  async returnToIsa() {
    const currentRoute = this.router.url;
    if (currentRoute === '/') {
      const ISA_HOME = environment.ISA_HOME;
      window.location.href = ISA_HOME;
    } else if (currentRoute != '/') {
      const ISA_ITEM = environment.ISA_ITEM;
      const itemId = this._datasheetService.getIsaModelId();

      if( itemId == 0) {
        const confirmed = await this.confirm( this._translate.instant("shared.actions.backToIsa.notReady") );
        return;
      }

      const isSaved = this._datasheetService.getStatus()  == StatusType.SAVED;
      const dsUid = this._datasheetService.getDatasheet().fw?.datasheet_uid;

      if( !dsUid || !isSaved ) {
        const confirmed = await this.confirm( this._translate.instant("shared.actions.backToIsa.notSaved") );
        return;
      }

      const isaItemUrl = `${ISA_ITEM}${itemId}`;
      window.location.href = isaItemUrl;
    }
  }

  confirm(message: any): Promise<boolean> {
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: message,
        header: this._translate.instant('shared.actions.backToIsa.label'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'OK',
        rejectLabel: 'none',
        rejectButtonStyleClass: 'hidden',
        key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

  setLanguage(language: string): void {
    this.setDefaultLanguage.emit(language);
    this.getCurrentLanguage();
  }

  private getCurrentLanguage(): void {
    const test = localStorage.getItem(LocalStorageKeys.USER_CURRENT_LANGUAGE);
    this.currentLanguage = this.availableLanguages.find((lang) => {
      return (lang.code == test);
    })!.ref;
  }

  openConfigs(){
    const currentRoute = this.router.url;
    let type  = (currentRoute.includes('/properties-editor') || currentRoute.includes('/print')) && !currentRoute.includes('/properties-editor/new') ? "model-configs" : "configs";
    this._dialog.open(ConfigurationDialogComponent, {
      data: { type: type }
    });
  }
}
