
  <mat-form-field class="select" appearance="outline">
    <mat-label>
      {{ 'shared.actions.selectVariant.label' | translate }}
    </mat-label>
    <mat-select [value]="selectedVariant" panelClass="model-form__panel isa-med-elevation" disableOptionCentering>
      <mat-option (click)="onSelectVariant(variant)" *ngFor="let variant of variants"
        [value]="variant.index">{{variant.name}}</mat-option>
    </mat-select>
  </mat-form-field>

