<div *ngIf="isLoading">
  <app-skeleton-loader [skeletonType]="'references-table'" [numberOfLines]="10" height="30px"></app-skeleton-loader>
</div>

 <ng-container *ngIf="!isLoading && references.length == 0; ">
  <app-mensage  [messageType]="MessageType.notFound"></app-mensage>
</ng-container>
<div>
<table  class="table"
  *ngIf=" columnNames$ | async as columnNames">
  <ng-container  *ngIf="!isLoading">
    <thead *ngIf="references.length > 0">
      <tr>
        <th class="table__header table__header--icon"></th>
        <th class="table__header table__header--checkbox"></th>
        <th class="table__header" *ngFor="let columnName of columnNames">
          {{ columnName=='parentName'? ('models.variant' | translate ) : columnName | translate }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr class="table__row" *ngIf="references.length == 0;">
      </tr>
      <tr class="table__row" *ngFor="let reference of references | appFilter: searchTerm;"
        [class.selected]="reference.checked">
        <td class="table__data table__data--icon">
          <button [matMenuTriggerFor]="menu"
            style="border:none;background-color:transparent;margin:0;padding:0;height:40px;width:40px;">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>

        <mat-menu #menu="matMenu" class="menu">
          <!-- <button class="menu__item" mat-menu-item (click)="openDialog(reference, 'shared.actions.move.label', 0)">
            {{ 'shared.actions.move.label' | translate }}
          </button> -->
          <button class="menu__item" mat-menu-item (click)="openDialog(reference, 'shared.actions.copy.label', 1)">
            {{ 'shared.actions.copy.label' | translate }}
          </button>
          <!-- <button class="menu__item" mat-menu-item [matMenuTriggerFor]="substitute">
            {{ 'shared.actions.replace.label' | translate }}
            <mat-icon class="menu__icon">navigate_next</mat-icon>
          </button> -->
        </mat-menu>

        <mat-menu #substitute="matMenu" class="menu menu--substitute">
          <button class="menu__item" mat-menu-item>
            {{ 'shared.actions.importFromErp.label' | translate }}
          </button>
          <button class="menu__item" mat-menu-item>
            {{ 'shared.actions.insertReference.label' | translate }}
          </button>
        </mat-menu>

        <td (click)="setReferenceSeed(reference)" class="table__data table__data--checkbox"><mat-checkbox disableRipple
            [checked]="reference.checked" color="primary"></mat-checkbox></td>
        <td (click)="setReferenceSeed(reference)" class="table__data" *ngFor="let columnName of columnNames">{{
          expodeReference(reference)[columnName] ?? ' - ' }}</td>
      </tr>
    </tbody>
  </ng-container>
</table>

