import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErpConfigData } from 'src/app/models/integration-user-data';
import { ErpServiceService } from 'src/app/services/erp-service.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-erp-configuration',
  templateUrl: './erp-configuration.component.html',
  styleUrls: ['./erp-configuration.component.scss'],
})
export class ErpConfigurationComponent implements OnInit {
  public erpCommomForm: FormGroup;
  public audacesErp: FormGroup;
  public customToken: FormGroup;
  public millenniumErp: FormGroup;

  public selectedService: string = '';
  public erpServices: string[] = [
    'erp.types.audaces',
    'erp.types.default',
    'erp.types.millennium',
    'erp.types.none',
  ];
  public userDictionaries: string[] = [];
  public userCostTable: string[] = [];
  public userData: ErpConfigData;


  public isProcessing: boolean = false;
  public isSaving: boolean = false;
  public connectionError: boolean = false;
  public connectionMessage: string = "";

  constructor(
    private _fb: FormBuilder,
    private _erpService: ErpServiceService,
    private _snack: SnackbarService,
    public dialogRef: MatDialogRef<ErpConfigurationComponent>,
    private translate: TranslateService
  ) {
    this.erpCommomForm = this._fb.group({
      serverAdress: ['', [Validators.required]],
      serverPort: [''],
      selectedService: ['', [Validators.required]],
      user: ['', [Validators.required]],
      password: [''],
      timeout: [null],
    });

    this.userData = {
      type: 'erp.types.none',
      ip: '',
      port: 0,
      user: {
        name: '',
        password: ''
      },
      timeout: 0,
      apiAudaces : {
        credential: '',
        dictionary: '',
      },
      costTable: '',
      customToken: ''
    }

    this.audacesErp = this._fb.group({
      credentialFormat: ['', [Validators.required]],
      dictionary: ['', [Validators.required]],
    });

    this.customToken = this._fb.group({
      token: [''],
    });

    this.millenniumErp = this._fb.group({
      costTable: [''],
    });
  }

  ngOnInit(): void {
    this.verifyCacheData();

    this.erpCommomForm.valueChanges.subscribe(() => {
      this.updateErpAddressAndPortFromForm();
    });

    this.customToken.valueChanges.subscribe(() => {
      this.updateCustomTokenFromData();
    })

    this.audacesErp.valueChanges.subscribe(() => {
      this.updateAudaErpDataFromForm();
    });

    this.millenniumErp.valueChanges.subscribe(() => {
      this.updateMillenErpDataFromForm();
    }); 
  }

  private setErrorMessage(code: number) {
    this.connectionError = true;
    this.connectionMessage = this._erpService.erpErrorMessage(code);
    switch (code) {
      case -1:
        this.connectionError = false;
        return;
      case 200:
        this.connectionError = false;
        break;
    }

    setTimeout(() => {
      this.connectionMessage = '';
      this.connectionError = false;
    }, 10*1000);
  }

  public verifyErpConnection() {
    if(this.formsHasError(false)) {
      return;
    }
    
    this.isProcessing = true;

    this._erpService.erpServer.updateCache(
      this.userData,
      this.selectedService
    );

    this._erpService.login()
      .then(response => {
        this.setErrorMessage(response.status||200);
        this.isProcessing = false;

        if(response.hasOwnProperty("body")) {
        
          // for audaces erp api
          if(response.body.hasOwnProperty("dictionaries")) {
            this.userDictionaries = response.body.dictionaries;
            this._erpService.setCacheCustomList(this.userDictionaries);
          }
  
          // for audaces erp api
          if(response.body.hasOwnProperty("user")) {
            this.audacesErp.patchValue({
              credentialFormat: response.body.user.credential,
              dictionary: response.body.user.dictionary,
            });
          }
        
        } else {

          this.userCostTable = []
          for(let itm of response) {
            let id = itm["d:TABELA"] || 0;
            let cod = itm["d:COD_TPRECO"] || '';
            let desc = itm["d:DESCRICAO"] || '';

            this.userCostTable.push( `${id} - ${cod} - ${desc}` );            
          }

          if( this.userCostTable.length) {
            this.millenniumErp.patchValue({
              costTable: this.userCostTable[0]
            });

            this._erpService.setCacheCostTable(this.userCostTable);
          }
        }


      })
      .catch(error => {
        this.setErrorMessage(error.status);
        this.isProcessing = false;
        console.error(error);
      });
  }

  private verifyCacheData() {
    const cacheData = this._erpService.erpServer.erpCacheData();

    if (cacheData) {
      this.userData = cacheData;

      this.erpCommomForm.patchValue({
        serverAdress: cacheData.ip,
        serverPort: cacheData.port,
        user: cacheData.user.name,
        password: cacheData.user.password,
        timeout: cacheData.timeout
      });

      this.customToken.patchValue({
        token: cacheData.customToken
      });

      if (cacheData.apiAudaces) {
        this.userDictionaries = this._erpService.getCacheCustomList();
        if(this.userDictionaries.length === 0) {
          this.userDictionaries.push(cacheData.apiAudaces.dictionary);
        }

        this.audacesErp.patchValue({
          credentialFormat: cacheData.apiAudaces.credential,
          dictionary: cacheData.apiAudaces.dictionary,
        });
      }

      if (cacheData.type) {
        this.selectedService = cacheData.type;
        this.erpCommomForm.patchValue({
          selectedService: cacheData.type,
        });
      }

      if (cacheData.costTable) {
        this.userCostTable = this._erpService.getCacheCostTable();
        if(this.userCostTable.length == 0) {
          this.userCostTable.push(cacheData.costTable);
        }

        this.millenniumErp.patchValue({
          costTable: cacheData.costTable
        });
      }
    }
  }

  formsHasError(isSaving=true) {
    let hasError = false;

    if( this.erpCommomForm.controls['selectedService'].value == "erp.types.none") {
      return false;
    }

    Object.keys(this.erpCommomForm.controls).forEach(key => {
      this.erpCommomForm.controls[key].markAsTouched();
    });

    if( !this.erpCommomForm.valid ) {
      hasError = true;
    }
    
    if (isSaving && this.selectedService == 'erp.types.audaces') {
      Object.keys(this.audacesErp.controls).forEach(key => {
        this.audacesErp.controls[key].markAsTouched();
      });
      
      if( !this.audacesErp.valid ) {
        hasError = true;
      }
    }

    if (isSaving && this.selectedService == 'erp.types.millennium') {
      Object.keys(this.millenniumErp.controls).forEach(key => {
        this.millenniumErp.controls[key].markAsTouched();
      });
      
      if( !this.millenniumErp.valid ) {
        hasError = true;
      }
    }

    if( hasError ) {
      this.translate.get('alerts.verifyFields.text').subscribe((trans: string) => {
        this._snack.failMessage( trans );
      });
    }
    
    return hasError;
  }

  save() {
    
    if( this.formsHasError() ) {
      this.isSaving = false;
      return;
    }

    this.isSaving = true;

    if( this.userData.type !== this.selectedService || this.selectedService == 'erp.types.none' ) {
      this._erpService.saveToken('');
    }
    this._erpService.erpServer.saveErpConfigInServer(this.userData, this.selectedService);
    this._erpService.erpServer.updateCache(this.userData, this.selectedService);

    if (this.selectedService == 'erp.types.audaces') {

      Object.keys(this.audacesErp.controls).forEach(key => {
        this.audacesErp.controls[key].markAsTouched();
      });
      
      if( !this.audacesErp.valid ) {
        this.translate.get('alerts.verifyFields.text').subscribe((trans: string) => {
          this._snack.failMessage( trans );
        });
        return;
      }

      this._erpService.audacesApiLogin().subscribe((success: boolean) => {
        if (success) {
          this._erpService.updateAudacesErpUser();
          this.isSaving = false;
          this.connectionError = false;
          this.dialogRef.close();
        } else {
          this.isSaving = false;
          this.connectionError = true;
          this.translate.get('alerts.failSaveUserData.text').subscribe((trans: string) => {
            this._snack.failMessage( trans );
          });
        }
      });

    } else {
      setTimeout(() => {
        this.isSaving = false;
        this.dialogRef.close();
      }, 1000);
    }
  }

  updateErpAddressAndPortFromForm(): void {
    this.userData.ip = this.erpCommomForm.get('serverAdress')!.value;
    this.userData.port = this.erpCommomForm.get('serverPort')!.value;
    this.userData.user.name = this.erpCommomForm.get('user')!.value,
    this.userData.user.password = this.erpCommomForm.get('password')!.value;
    this.userData.timeout = this.erpCommomForm.get('timeout')?.value;
  }

  updateAudaErpDataFromForm(): void {
    this.userData.apiAudaces!.credential = this.audacesErp.get('credentialFormat')!.value;
    this.userData.apiAudaces!.dictionary = this.audacesErp.get('dictionary')!.value;
  }

  updateMillenErpDataFromForm(): void {
    this.userData.costTable! = this.millenniumErp.get('costTable')!.value
  }

  private updateCustomTokenFromData(): void {
    this.userData.customToken = this.customToken.get('token')?.value; 
  }
}
