<button
  class="button-text"
  [class.button-text--primary]="color == 'primary'"
  [class.button-text--secondary]="color == 'secondary'"
  [style.height.px]="height"
  [disabled]="disabled"
  [class.spinner]="isLoading"
  >
  <ng-content></ng-content>
</button>
