<div class="dialog_container">
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header__tittle">{{ 'confirmDialog.header.title' | translate }}</h2>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog-content">
    <div class="dialog-content__lists-container">
      <div class="dialog-content__lists-container_update" *ngIf="data.toUpdate.length > 0">
        <span class="dialog-content__lists-container_update_tittle">
          {{ 'confirmDialog.content.updateList.title' | translate }}
        </span>
        <div class="dialog-content__lists-container_update_container">
          <ng-container *ngFor="let item of data.toUpdate; let isOdd = odd; let isEven = even; let i = index">
            <div class="dialog-content__lists-container_update_container_content" [ngClass]="{ 'odd': isOdd, 'even': isEven }">
              <span class="dialog-content__lists-container_update_container_content_item">
                {{ item.reference.fields[0].value }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="dialog-content__lists-container_keep" *ngIf="data.toKeep.length > 0">
        <span class="dialog-content__lists-container_keep_tittle">
          {{ 'confirmDialog.content.keepList.title' | translate }}
        </span>
        <div class="dialog-content__lists-container_keep_container">
          <ng-container *ngFor="let item of data.toKeep; let isOdd = odd; let isEven = even; let i = index">
            <div class="dialog-content__lists-container_keep_container_content" [ngClass]="{ 'odd': isOdd, 'even': isEven }">
              <span class="dialog-content__lists-container_keep_container_content_item">
                {{ item.reference.fields[0].value }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button-text class="exit" [mat-dialog-close]="true">{{ 'confirmDialog.actions.cancel' | translate }}</button-text>
    <button-filled class="save" [disabled]="isLoading" [isLoading]="isLoading" (click)="save()">{{ 'confirmDialog.actions.confirm' | translate }}</button-filled>
  </div>
</div>
