import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintRoutingModule } from './print-routing.module';
import { PrintComponent } from './print.component';
import { PrintConfigDrawerComponent } from 'src/app/components/print-config-drawer/print-config-drawer.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { PrintPreviewComponent } from 'src/app/components/print-preview/print-preview.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateHttpLoaderFactory } from 'src/assets/i18n/config/http.loader';
import { HttpClient } from '@angular/common/http';


@NgModule({
  declarations: [
    PrintComponent,
    PrintConfigDrawerComponent,
    PrintPreviewComponent

  ],
  imports: [
    PrintRoutingModule,
    SharedModule
  ]
})
export class PrintModule { }
