<p-confirmDialog></p-confirmDialog>
<div class="container">
  <mat-tab-group class="reference-list-tabs" animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
    <ng-container *ngFor="let ref of references ">
      <mat-tab label="{{ref | translate}}">
        <div class="search-input-cont" style="display: flex;">
          <button-outline *ngIf="datasheet.isStandAlone()" (click)="onAddReference()" [color]="'primary'" [height]="42" class="menuBtn">
            <mat-icon>add_circle_outline</mat-icon>
            <button *ngIf="datasheet.isStandAlone()" (click)="onErpMenuClick($event)" [matMenuTriggerFor]="addMenu" class="drop-down-btn">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #addMenu="matMenu">
              <button mat-menu-item (click)="openModalAddFwReference()">
                <mat-icon class="material-icons-outlined" style="color:#696969">book</mat-icon>
                <span>{{'refList.addRefFW' | translate}}</span>
              </button>
              <button mat-menu-item (click)="openModalAddReference()">
                <mat-icon class="material-icons-outlined" style="color:#696969">collections_bookmark</mat-icon>
                <span>{{'refList.addRefERP' | translate}}</span>
              </button>
            </mat-menu>
          </button-outline>
          <mat-form-field class="search-input" appearance="outline" floatLabel="always">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>
              {{ 'shared.inputs.search.label' | translate }}
            </mat-label>
            <input  matInput [(ngModel)]="searchTerm"  />
          </mat-form-field>

        </div>

        <div class="reference-list-cont" *ngIf="currentListPath">
          <div class="flex"
            *ngFor="let item of currentListPath | appFilter :searchTerm; let i = index"
            [class.selected]="i === selectedReferenceIndex"
          >
            <p class="reference-list"
                appHighlight
                [searchedWord]="searchTerm"
                [content]="datasheet.getFieldValue('code', item.fields)"
                [setTitle]="true"
                (click)="SelectedReferenceEmmit(i)">
              {{ datasheet.getFieldValue('code', item.fields) }}
            </p>

            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="onMenuClick(i)" *ngIf="datasheet.isStandAlone()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="SelectedReferenceEmmit(i)">
                <mat-icon>edit</mat-icon>
                <span>{{'refList.edit' | translate}}</span>
              </button>

              <button mat-menu-item (click)="onDeleteClick(i)">
                <mat-icon>delete</mat-icon>
                <span>{{'refList.delete' | translate}}</span>
              </button>

              <mat-divider></mat-divider>

              <button mat-menu-item [matMenuTriggerFor]="copySubmenu" [disabled]="targetCopy.length == 0 ? true : null">
                <mat-icon>content_copy</mat-icon>
                <span>{{'refList.copyTo' | translate}}</span>
              </button>

              <button mat-menu-item [matMenuTriggerFor]="move1Submenu" *ngIf="datasheet.variantLevel() >= 1" [disabled]="targetMove1.length == 0 ? true : null">
                <mat-icon>looks_one</mat-icon>
                <span>{{'refList.moveToVariant' | translate}}</span>
              </button>

              <button mat-menu-item [matMenuTriggerFor]="move2Submenu" *ngIf="datasheet.variantLevel() >= 2" [disabled]="targetMove2.length == 0 ? true : null">
                <mat-icon>looks_two</mat-icon>
                <span>{{'refList.moveToSubVariant' | translate}}</span>
              </button>

              <button mat-menu-item [matMenuTriggerFor]="move3Submenu" *ngIf="datasheet.variantLevel() >= 3" [disabled]="targetMove3.length == 0 ? true : null">
                <mat-icon>looks_3</mat-icon>
                <span>{{'refList.moveToSubVariant2' | translate}}</span>
              </button>
            </mat-menu>

            <mat-menu #copySubmenu="matMenu">
              <button mat-menu-item *ngFor="let tgt of targetCopy" (click)="onCopyClick(i, tgt)"> {{tgt.target_name}} </button>
            </mat-menu>

            <mat-menu #move1Submenu="matMenu">
              <button mat-menu-item *ngFor="let tgt of targetMove1" (click)="onMoveClick(i, tgt, $event)"> {{tgt.target_name}} </button>
            </mat-menu>

            <mat-menu #move2Submenu="matMenu">
              <button mat-menu-item *ngFor="let tgt of targetMove2" (click)="onMoveClick(i, tgt, $event)"> {{tgt.target_name}} </button>
            </mat-menu>

            <mat-menu #move3Submenu="matMenu">
              <button mat-menu-item *ngFor="let tgt of targetMove3" (click)="onMoveClick(i, tgt, $event)"> {{tgt.target_name}} </button>
            </mat-menu>

          </div>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>





