import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.scss']
})
export class ConfigurationDialogComponent implements OnInit {

  public dialogs = [
    { name: 'erp.menu.label', type: 'erp', active: false},
    { name: 'settingDialog.tittle', type: 'configs', active: false},
    { name: 'settingDialog.tittleModel', type: 'model-configs', active: false},
  ];
  public dialogSelected: any;

  isModelConfig: string = 'configs';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private _router: Router,
    private _dialogSrrvice: DialogService
  ){}

  ngOnInit(): void {
    this._dialogSrrvice.closeDrawer();
    const currentRoute = this._router.url;
    if ((currentRoute.includes('/properties-editor') || currentRoute.includes('/print')) && !currentRoute.includes('/properties-editor/new')){
      this.isModelConfig = 'model-configs';
    }
    this.translate.get(this.dialogs.map(dialog => dialog.name)).subscribe(translations => {
      this.dialogs = this.dialogs.map(dialog => ({
        ...dialog,
        name: translations[dialog.name],
      }));
    });
    const selected = this.dialogs.find(dialog => dialog.type === this.data.type);
    if (selected) {
      selected.active = true;
      this.dialogSelected = selected;
    }

  }

  changeDialog(dialogType: any) {
    this.dialogs.forEach((item) => (item.active = item.type == dialogType));
    const selected = this.dialogs.find((dialog) => dialog.type == dialogType)
    this.dialogSelected = selected;
  }

}
