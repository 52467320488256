import { Injectable } from '@angular/core';

import { AppSnackBar } from './snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) { }


  showMessage(
    message: string,
    type: 'success' | 'error' | 'alert' = 'success',
    icon: string = 'done',
    duration: number = 5000, // 5 segundos padrão
    action: string = 'X',
    callback?: () => void
) {
    const snackBarRef = this._snackBar.openFromComponent(AppSnackBar, {
        duration: duration,
        data: { message, action, icon, type, callback },
    });

    if (callback) {
        snackBarRef.onAction().subscribe(() => {
            callback();
        });
    }
}

  failMessage(message: string, action: string = 'X', duration: number = 180000) {
    this.showMessage(message, 'error', 'highlight_off', duration, action); // Configurando o tipo como 'error'
  }

  warnMessage(message: string, action: string = 'X', duration: number = 15000) {
    this.showMessage(message, 'alert', 'warning', duration, action); // Configurando o tipo como 'alert'
  }

  okMessage(message: string, action: string = 'X', duration: number = 5000) {
    this.showMessage(message, 'success', 'done', duration, action); // Configurando o tipo como 'success'
  }

  warnUnlockMessage(message: string, action: string = 'Retry', duration: number = 15000, callback?: () => void) {
    this.showMessage(message, 'alert', 'warning', duration, action, callback);
}
}
