import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Field } from 'src/app/models/field';
import { DatasheetService } from 'src/app/services/datasheet.service';

@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss']
})
export class CustomFieldsFormComponent implements OnInit {
  @Input() values!: FormGroup;
  @Input() valuesWithOptions!: Field[];
  @Input() parent?:any;
  @Output() editFieldEvent = new EventEmitter<{field: Field, parent: string}>();
  @Output() deleteFieldEvent = new EventEmitter<{name: string, parent: string}>();
  public names: any;
  public options: any = {};

  constructor(
    private _translate: TranslateService,
    private _confirmationService: ConfirmationService,
    private _datasheetService: DatasheetService
  ) {}

  ngOnInit(): void {
    this.names = Object.keys(this.values.getRawValue());
    this.valuesWithOptions.forEach(cf => this.options[cf.name] = cf.options);

    for( const fld of this.valuesWithOptions ) {
      if( fld.type == "DateTime" ) {
        this.values.get(fld.name)?.clearValidators();
      }
    }
  }

  public hasOptions(field: Field): boolean {
    return field.hasOwnProperty('options') && (field.options!.length > 0);
  }

  public getFieldType(name: string): string | undefined {
    const field = this.valuesWithOptions.find(cf => cf.name === name);
    return field?.type === 'DateTime' ? 'datetime-local' : field?.type;
  }

  public getFieldForColor(field:any) {
    if(field.hasOwnProperty('color') && field.color)
      return 'color';
    return 'text';
  }

  public isSelectField(name: string): boolean {
    return this.valuesWithOptions.some(cf => cf.name === name && cf.options!.length > 0);
  }

  public handleColorObj( colorObj: any) : string {

    if(colorObj.hasOwnProperty('color') && colorObj.color) {
      if( this.values.get(colorObj.name) && this.values.get(colorObj.name)?.value == "" ) {
        this.values.get(colorObj.name)!.patchValue(colorObj.color!.value, {emitEvent: false});
      }

      return colorObj.color!.value;
    }

    return colorObj!.value;
  }


  public timestampToDate(timestamp: any): any {

    for( const fld of this.valuesWithOptions ) {
      if( fld.type == "DateTime" ) {
        this.values.get(fld.name)?.setErrors(null);
      }
    }

    let numb = Number(timestamp);
    if( timestamp instanceof Object ) {
      return timestamp;
    }
    if( numb > 0 )
      return new Date(timestamp * 1000,);
    else
      return "";
  }

  public clearDate(name: string) {
    this.values.get(name)?.setValue("");
  }

  trackByFn(index:any, item:any) {
    return index;
  }

  preventNonNumericalInput(event: KeyboardEvent): void {
    const charCode = event.charCode;
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  editField(field: Field): void {
    this.editFieldEvent.emit({ field, parent: this.parent });
  }

  async deleteField(name: string): Promise<void> {
    const confirmed = await this.confirm("alerts.customFields.removing.title", "alerts.customFields.removing.confirm");
    if( !confirmed ) {
      return;
    }
    this.deleteFieldEvent.emit({ name, parent: this.parent });
  }

  confirm(title: string, message: string): Promise<boolean> {
    const header = this._translate.instant(title);
    const acceptLabel = this._translate.instant('refList.acceptLabel');
    const rejectLabel = this._translate.instant('refList.rejectLabel');
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message:  this._translate.instant(message),
        header: header,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        rejectButtonStyleClass: 'p-button-text',
        key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

  getCurrency(){
    return this._datasheetService.getDatasheet().getCurrencySymbol();
  }

  getMeasureSymbol(measure: string){
    return this._datasheetService.getDatasheet().getMeasure(measure);
  }

}





