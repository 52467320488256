import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  errorCode = '404';
  errorMsg1 = "Oops! Page not found";
  errorMsg2 = "we are sorry, but the page you requested was not found";
  
  constructor( private router: Router ) {
    
    switch (this.router.url) {
      case '/forbidden':
        this.errorCode = '403';
        this.errorMsg1 = 'Oops! You have no access';
        this.errorMsg2 = "we are sorry, but the page you requested is Forbidden";
        break;
        
        case '/unauthorized':
          this.errorCode = '401';
          this.errorMsg1 = 'Oops! You are unauthorized';
          this.errorMsg2 = "we are sorry, but the page you requested if Unauthorized";
        break;
    
      default:
        break;
    }
  }

}
