import { Property } from "./property";

export interface CategoryField {
  name: string,
  label: string,
  type: string,
  custom: boolean,
  options: string[], 
  properties: Property,
}

export interface CategoryType {
  label: string,
  fields: CategoryField[],
}

export interface Category {
  activity: CategoryType,
  free: CategoryType,
  garment: CategoryType,
  material: CategoryType,
  measures: CategoryType,
  pattern?: CategoryType,
  pattern_group?: CategoryType,
  marker?: CategoryType,
  marker_group?: CategoryType,
  group?: CategoryType,
  fashion_studio?: CategoryType,
  variant: CategoryType,
}

export const defaultCategory: Category = {
  fashion_studio: {
    label: "Audaces Fashion Studio",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      }
    ]
  },
  activity: {
    label: "Atividade",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "sector",
        label: "Setor",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "obs",
        label: "Observação",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "machine",
        label: "Máquina",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "amount",
        label: "Qtd/Tempo",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: false,
          empty_valid: false,
          fractional_digits: 4,
          min_value: 0,
          remove_zeros: true
        }
      },
      {
        name: "um",
        label: "UM",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "cost",
        label: "Custo Unitário",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "total",
        label: "Total",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      }
    ]
  },
  free: {
    label: "Referência Genérica",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      }
    ]
  },
  garment: {
    label: "Modelo",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "collection",
        label: "Coleção",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "resp",
        label: "Responsável",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "obs",
        label: "Observação",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "amount",
        label: "Qtd",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: false,
          empty_valid: false,
          fractional_digits: 4,
          min_value: 0,
          remove_zeros: true
        }
      },
      {
        name: "base_cost",
        label: "Custo Base",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "cost",
        label: "Custo Unitário",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "max_total",
        label: "Maximum total",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "total",
        label: "Total",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "mat_total",
        label: "Custo dos Materias",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "act_total",
        label: "Custo das Atividades",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "mat_max_cost",
        label: "Maximum Materials Cost",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "act_max_cost",
        label: "Maximum Activities Cost",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      }
    ]
  },
  group: {
    label: "Grupo de Referências",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      }
    ]
  },
  marker: {
    label: "Encaixe",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "type",
        label: "Tipo",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "way",
        label: "Direção",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "barcode",
        label: "Código de barras",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "break_points",
        label: "Pontos de Emenda",
        type: "Number",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          min_value: 0
        }
      },
      {
        name: "qty_to_place",
        label: "Qtd a Encaixar",
        type: "Number",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          min_value: 0
        }
      },
      {
        name: "qty_placed",
        label: "Qtd Encaixadas",
        type: "Number",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          min_value: 0
        }
      },
      {
        name: "length",
        label: "Comprimento",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "width",
        label: "Largura",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "heigth",
        label: "Peso",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "weight",
          remove_zeros: true
        }
      },
      {
        name: "net_weight",
        label: "Peso líquido",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "weight",
          remove_zeros: true
        }
      },
      {
        name: "area",
        label: "Área",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "area",
          remove_zeros: true
        }
      },
      {
        name: "efficiency",
        label: "Aproveitamento",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: false,
          empty_valid: true,
          fractional_digits: 4,
          min_value: 0,
          remove_zeros: true
        }
      },
      {
        name: "yield_consumption",
        label: "Rendimento consumo",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "yield_weight",
        label: "Rendimento peso",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "weight",
          remove_zeros: true
        }
      },
      {
        name: "cut_perimeter",
        label: "Perímetro de Corte",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "rect_perimeter",
        label: "Perímetro retilíneo",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "curved_perimeter",
        label: "Perímetro Curvo",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "xfactor",
        label: "Fator X",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: false,
          empty_valid: true,
          fractional_digits: 4,
          remove_zeros: true
        }
      },
      {
        name: "yfactor",
        label: "Fator Y",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: false,
          empty_valid: true,
          fractional_digits: 4,
          remove_zeros: true
        }
      }
    ]
  },
  marker_group: {
    label: "Grupo de encaixes",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      }
    ]
  },
  material: {
    label: "Material",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "group",
        label: "Grupo",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "supplier",
        label: "Fornecedor",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "obs",
        label: "Observação",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "color",
        label: "Cor",
        type: "Color",
        custom: false,
        options: [],
        properties: {
          empty_valid: true
        }
      },
      {
        name: "amount",
        label: "Qtd",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: true,
          empty_valid: false,
          fractional_digits: 4,
          min_value: 0,
          remove_zeros: true
        }
      },
      {
        name: "um",
        label: "UM",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "cost",
        label: "Custo Unitário",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      },
      {
        name: "total",
        label: "Total",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      }
    ]
  },
  measures: {
    label: "Medida",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "measure",
        label: "Medida",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      },
      {
        name: "obs",
        label: "Observação",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      }
    ]
  },
  pattern: {
    label: "Molde",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "fabric",
        label: "Tecido",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "obs",
        label: "Observação",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "amount",
        label: "Qtd",
        type: "Number",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          min_value: 0
        }
      },
      {
        name: "grade",
        label: "Graduação",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "turn",
        label: "Giro",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "range",
        label: "Faixa",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          always_editable: false,
          empty_valid: true,
          fractional_digits: 4,
          remove_zeros: true
        }
      },
      {
        name: "double",
        label: "Duplo",
        type: "Boolean",
        custom: false,
        options: [],
        properties: {
          empty_valid: true
        }
      },
      {
        name: "even",
        label: "Par",
        type: "Boolean",
        custom: false,
        options: [],
        properties: {
          empty_valid: true
        }
      },
      {
        name: "mirror",
        label: "Espelhar",
        type: "Boolean",
        custom: false,
        options: [],
        properties: {
          empty_valid: true
        }
      },
      {
        name: "hfold",
        label: "Dobrar H",
        type: "Boolean",
        custom: false,
        options: [],
        properties: {
          empty_valid: true
        }
      },
      {
        name: "vfold",
        label: "Dobrar V",
        type: "Boolean",
        custom: false,
        options: [],
        properties: {
          empty_valid: true
        }
      },
      {
        name: "size",
        label: "Tamanho",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: false
        }
      },
      {
        name: "area",
        label: "Área",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "area",
          remove_zeros: true
        }
      },
      {
        name: "perimeter",
        label: "Perímetro",
        type: "FloatingNumber",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          fractional_digits: 4,
          measure: "length",
          remove_zeros: true
        }
      }
    ]
  },
  pattern_group: {
    label: "Grupo de Moldes",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      }
    ]
  },
  variant: {
    label: "Variante",
    fields: [
      {
        name: "code",
        label: "Nome",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          multi_line: false
        }
      },
      {
        name: "desc",
        label: "Descrição",
        type: "Text",
        custom: false,
        options: [],
        properties: {
          empty_valid: true,
          multi_line: true
        }
      },
      {
        name: "total",
        label: "Total",
        type: "Currency",
        custom: false,
        options: [],
        properties: {
          empty_valid: false,
          fractional_digits: 2,
          remove_zeros: false
        }
      }
    ]
  }
};

export default defaultCategory;