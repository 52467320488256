import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnalyticsMetadata } from '../models/analyticsMetadata';
import { LocalStorageService } from './localStorage.service';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  eventCache : any = {};

  constructor(
    private http: HttpClient,
    private _localStorageService: LocalStorageService
  ) {}

  trackSingleEvent(eventName: string): boolean {
    return this.postEvent( eventName );
  }

  trackStartEvent(eventName: string): void {
    this.eventCache[eventName] = new Date();
  }
  
  trackEndEvent(eventName: string): boolean {
    if( this.eventCache.hasOwnProperty(eventName) ) {
      const noww = new Date();
      const diffMs = noww.getTime() - this.eventCache[eventName].getTime(); // milliseconds
      delete this.eventCache[eventName];
      return this.postEvent( eventName, diffMs );
    } else {
      return this.postEvent( eventName );
    }
  }

  private postEvent(eventName: string, eventDuration?: number): boolean {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this._localStorageService.getAuthorizationToken()}`,
      'Content-Type': 'application/json'
    });

    const userInfo = this._localStorageService.getUserInfo();

    let analyticsData: AnalyticsMetadata = {
      userEmail: userInfo.email,
      userId: parseInt(userInfo.sub),
      language: localStorage.getItem(LocalStorageKeys.USER_CURRENT_LANGUAGE) || '',
      eventType: eventName
    };

    if( analyticsData ) {
      analyticsData['eventDuration'] = eventDuration;
    }
    
    const cpn = JSON.parse(this._localStorageService.get(LocalStorageKeys.USER_CURRENT_COMPANY));
    const url = `${environment.API_URL}/api/WebApp/track?cpn=${cpn.id.toString()}`;
    
    let success = false;
    this.http.post(url, analyticsData, { headers, observe: 'response' }).subscribe({
      next: (response) => {
        if (response.status === 200) {
          console.log('Event posted successfully');
          success = true;
        } else {
          console.error('Unable to send analytics data', response);
        }
      },
      error: (error) => {
        console.error('Failed to send analytics data', error);
      }
    });

    return success;
  }







}
