import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Datasheet } from 'src/app/models/datasheet';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { IdeaService } from 'src/app/services/idea.service';
import JSZip from 'jszip';

@Component({
  selector: 'app-import-ideax-dialog',
  templateUrl: './import-ideax-dialog.component.html',
  styleUrls: ['./import-ideax-dialog.component.scss']
})
export class ImportIdeaxDialogComponent {

  isProcessing = false;
  selectedFile: File | null = null;
  imageUrl: any;
  imageName: string = "";

  constructor(
    private _dialogRef: MatDialogRef<ImportIdeaxDialogComponent>,
    private _snack: SnackbarService,
    private translate: TranslateService,
    private _ideaService: IdeaService,
  ) {}

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if( !file || !file.name.endsWith(".ideax") ) {
      this._snack.failMessage( this.translate.instant('shared.actions.ideax.noIdeaxFile') );
      return;
    }

    this.selectFile(file);
  }

  onUpload(): void {
    this.isProcessing = true;
    
    if( !this.selectedFile) { 
      return;
    }

    const formData = new FormData();
    formData.append('blobIdeax', this.selectedFile, this.selectedFile.name);
    
    this._ideaService.postideax( formData )
      .subscribe(response => {
        this._ideaService.setDatasheet( response as Datasheet )      
          .then(response => {
            this.isProcessing = false;
            if( response ) {
              this._dialogRef.close(true);
            }
          })
          .catch(error => {
            this.isProcessing = false;
            console.error(error);
          });

      }, error => {
        console.error('Upload error:', error);
        this.isProcessing = false;
        this._snack.failMessage(this.translate.instant('shared.actions.ideax.fail') + " " + error.message);
      });

  }

  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onFileDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    const file: File = event.dataTransfer.files[0];

    if( !file || !file.name.endsWith(".ideax") ) {
      this._snack.failMessage( this.translate.instant('shared.actions.ideax.noIdeaxFile') );
      return;
    }

    this.selectFile( file );
  }

  private selectFile( file: File ) : void {
    
    const zip = new JSZip();
    const reader = new FileReader();
    reader.onload = (e) => {
      JSZip.loadAsync(e.target!.result as ArrayBuffer).then((zip) => {
        if (!zip.file('commands.json')) {
          this._snack.failMessage( this.translate.instant('shared.actions.ideax.oldIdeaxFile') );
          return;
        }

        const previewFile = zip.file('preview.jpg');
        if (previewFile) {
          previewFile.async('base64').then((data) => {
            this.imageUrl = 'data:image/jpeg;base64,' + data;
            this.imageName = file.name;
          });
        } else {
          this.imageUrl = null;
          this.imageName = "";
        }
      }).catch((error) => {
        this._snack.failMessage( this.translate.instant('shared.actions.ideax.failIdeaxFile') + error.message );
      });
    };
    reader.readAsArrayBuffer(file);

    this.selectedFile = file;
  }

}
