import { SharedModule } from './../../modules/shared/shared.module';
import { NgModule } from '@angular/core';
import { PropertiesEditorComponent } from './properties-editor.component';
import { ImageEditorComponent } from 'src/app/components/image-editor/image-editor.component';
import { ReferencesListComponent } from 'src/app/components/references-list/references-list.component';
import { ReferencesEditorDrawerFormComponent } from 'src/app/components/references-editor-drawer-form/references-editor-drawer-form.component';
import { GarmentFormComponent } from 'src/app/components/garment-form/garment-form.component';
import { CustomFieldsFormComponent } from 'src/app/components/custom-fields-form/custom-fields-form.component';
import { VariantFormComponent } from 'src/app/components/variant-form/variant-form.component';
import { DatasheetStepperComponent } from 'src/app/components/datasheet-stepper/datasheet-stepper.component';
import { DatasheetStepperContainerComponent } from 'src/app/components/datasheet-stepper-container/datasheet-stepper-container.component';
import { PropertiesEditorRoutingModule } from './properties-editor-routing.module';
import { EditorImageContainerComponent } from 'src/app/components/editor-image-container/editor-image-container.component';

import { DecimalDirective } from 'src/app/directives/decimal.directive';
import { IsaModelFieldsComponent } from 'src/app/components/isa-model-fields/isa-model-fields.component';




@NgModule({
  declarations: [
    PropertiesEditorComponent,
    ImageEditorComponent,
    ReferencesListComponent,
    ReferencesEditorDrawerFormComponent,
    CustomFieldsFormComponent,
    GarmentFormComponent,
    VariantFormComponent,
    DatasheetStepperComponent,
    DatasheetStepperContainerComponent,
    EditorImageContainerComponent,
    DecimalDirective,
    IsaModelFieldsComponent
  ],
  imports: [
    PropertiesEditorRoutingModule,
    SharedModule
  ]
})
export class PropertiesEditorModule { }
