import defaultVariant, { Variant } from "./variant";
import { v4 as uuidv4 } from 'uuid';



export enum VariationLevel {
  SINGLE,
  ONE,
  TWO,
  THREE
}

export interface VariationItem {
  name: string,
  variant: Variant,
  category: string,
  children?: VariationItem[],
  isEditing?: boolean;
}

export interface Variation {
  level: VariationLevel
  itens: VariationItem[]
}

export class VariationUtil {
  
  public static makeAUniqueVariant( name: string ) : Variant {
    let variant = JSON.parse(JSON.stringify(defaultVariant))
    variant.uid = uuidv4();
    variant.fields[0].value = name;
    return variant;
  }

  public static createVariantItem(name: string, category: string): VariationItem {
    return {
      name,
      variant: VariationUtil.makeAUniqueVariant(name),
      category: category,
      children: [],
      isEditing: false
    };
  }

  public static copyVariantItem( originalItem: VariationItem ): VariationItem {
    return {
      name: originalItem.name,
      variant: originalItem.variant,
      category: originalItem.category,
      children: [],
      isEditing: false
    };
  }

  public static createSimilarVariarion( original: Variation ) : Variation {
    let cpy: Variation = {
      level: original.level,
      itens: []
    }

    for( let item of original.itens || []) {
      let firstLevel = VariationUtil.copyVariantItem( item );
      cpy.itens.push(firstLevel);
      for( let child of item.children || [] ) {
        let secondLevel = VariationUtil.copyVariantItem( child );
        firstLevel.children!.push(child);
        for( let grandChild of child.children || [] ) {
          let thirdLevel = VariationUtil.copyVariantItem( grandChild );
          secondLevel.children!.push(thirdLevel);
        }
      }
    }

    return cpy;
  }
  
}