import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-text',
  templateUrl: './button-text.component.html',
  styleUrls: ['./button-text.component.scss']
})
export class ButtonTextComponent implements OnInit {
  @Output() click = new EventEmitter();
  @Input() height: number = 37;
  @Input() color: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public onClick(): void {
    this.click.emit();
  }

}
