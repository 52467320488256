<div class="dialog_container">
  <div class="dialog_container__header">
    <h2 mat-dialog-title class="dialog_container__header_tittle"> {{'isaCollections.tittle' | translate}} </h2>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="dialog_container_content">

    <app-skeleton-loader *ngIf="isLoading" [skeletonType]="'isa_dialog'"></app-skeleton-loader>

    <mat-card class="isa-card" *ngFor="let sheet of isacollection | paginate :{ itemsPerPage: 1, currentPage: actualPage, totalItems: totalItems } , let i = index" >
      <mat-card-header class="isa-card__header">
        <mat-card-title> {{"isaCollections.collection" | translate}}: <b>{{sheet.name}} </b></mat-card-title>
        <mat-card-subtitle>{{"isaCollections.season" | translate}}: <b>{{sheet.season}}</b> / <b>{{sheet.releaseYear}}</b></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="isa-card__content">
        <ng-container *ngFor="let sc of sheet.subCollections; let last = last" >
          <h2>{{"isaCollections.cycle" | translate}}: {{sc.name}}</h2>

          <ng-container *ngIf="sc.allCategories == 0">
            <app-mensage style="margin-top: 50px;" [messageType]="MessageType.noIsaItems"></app-mensage>
          </ng-container>

          <ng-container *ngFor="let cat of sc.allCategories">
            <div  class="layout">
            <ng-container *ngFor="let item of cat.items" class="layout">
                <mat-card  *ngIf="!item.datasheetUid && !item.variantParentId" class="isa-card__inner" style="margin: 5px; width: 300px;"  [ngClass]="{'disabled': item.datasheetUid}">
                  <mat-card-header>
                    <div mat-card-avatar class="header-image" style="background-image: url('{{ getShape(item.shapeId) }}');"></div>
                    <mat-card-title>{{getIsaItemName(item)}}</mat-card-title>
                    <mat-card-subtitle> <em>{{sc.name}} / {{cat.name}}</em></mat-card-subtitle>
                  </mat-card-header>
                  <div style="width: 100%; text-align: center; align-items: center;">
                    <img style="max-width: 250px;max-height: 250px;" *ngIf="!item.datasheetUid" [src]="item.coverImageReference" (error)="onImageError($event)"><br>
                  </div>
                  <div style="display: flex; align-items: center; gap: 5px;">
                    <mat-icon style="opacity: 0.5;" class="material-icons-outlined" *ngIf="item.assignee">assignment_ind</mat-icon>
                    {{item.assignee?.name}}
                  </div>
                  <mat-form-field class="styledForm steps-select" appearance="outline" >
                    <mat-label>Etapa</mat-label>
                    <mat-select #stepSelect [value]="setSelectedStep(sheet.steps)" [disabled]="item.datasheetUid">
                      <mat-option *ngFor="let stp of sheet.steps" [value]="stp">
                        {{stp.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-card-content>
                    {{ item.description }}
                  </mat-card-content>
                  <mat-card-actions>
                    <button-filled mat-button [disabled]="item.datasheetUid" (click)="onAssociateClick(item, stepSelect.value.name, sheet)">{{"isaCollections.add" | translate}}</button-filled>
                  </mat-card-actions>
                </mat-card>
              </ng-container>
            </div>
            <mat-divider *ngIf="!last"  style="width: 100%; margin-bottom: 15px; margin-top: 15px;"></mat-divider>
          </ng-container>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="paginator">
    <pagination-controls   (pageChange)="onPageChange($event)" nextLabel="" previousLabel=""></pagination-controls>
  </div>
</div>
