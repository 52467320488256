<button
  [matBadge]='badge' matBadgePosition="after" matBadgeColor="accent"
  class="button-misc"
  [class.button-misc--primary]="color == 'primary'"
  [class.button-misc--secondary]="color == 'secondary'"
  [class.button-misc--danger]="color == 'danger'"
  [class.button-misc--active]="active"
  [style.height.px]="height"
  [disabled]="disabled"
  [class.spinner]="isLoading"
  >
  <ng-content></ng-content>
</button>
