<div class="dialog_container">

  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header__tittle"></h2>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog-content">
    <div class="alert">

      <span class="material-icons-round ">
        warning_amber
        </span>
    </div>
    <span class="confirmation">{{'updateIdeaxModal.title' | translate}}</span>
    <span class="dialog-content__subtitle1"> {{message1}}</span>
    <span class="dialog-content__subtitle2">{{message2}}</span>
  </div>
  <div mat-dialog-actions  class="dialog-actions">
    <button-text class="exit" [mat-dialog-close]="true" (click)="actionButtonOne()">{{ "updateIdeaxModal.no" | translate }}</button-text>
    <button-filled class="save" (click)="actionButtonTwo()" [isLoading]="isProcessing" [disabled]="isProcessing">{{"updateIdeaxModal.yes" | translate }}</button-filled>
  </div>
</div>
