<div class="model-form" [formGroup]="values">
  <mat-form-field class="model-form__field" appearance="outline" *ngIf="editionMode">
    <mat-label>{{ 'shared.inputs.name.label' | translate }}  </mat-label>
    <input formControlName="code" matInput (ngModelChange)="onCodeChange($event)"  />
  </mat-form-field>
  <mat-form-field class="model-form__field" appearance="outline" *ngIf="level==1">
    <mat-label>
      {{ 'shared.inputs.total.label' | translate }}
    </mat-label>
    <input formControlName="total" matInput
      type="text"
      formatDecimal
      [value]="values?values.get('total')!.value:''"
    />
  </mat-form-field>
</div>
