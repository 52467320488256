import { ReferencesHandlerDialogComponent } from './../../components/references-handler-dialog/references-handler-dialog.component';
import { NgModule } from '@angular/core';

import { AdvancedEditorRoutingModule } from './advanced-editor-routing.module';
import { AdvancedEditorComponent } from './advanced-editor.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ReferencesTableComponent }  from 'src/app/components/references-table/references-table.component';
import { SelectVariantsComponent } from 'src/app/components/select-variants/select-variants.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateHttpLoaderFactory } from 'src/assets/i18n/config/http.loader';
import { HttpClient } from '@angular/common/http';


@NgModule({
  declarations: [
    AdvancedEditorComponent,
    ReferencesTableComponent,
    ReferencesHandlerDialogComponent,
    SelectVariantsComponent
  ],
  imports: [
    AdvancedEditorRoutingModule,
    SharedModule
  ]
})
export class AdvancedEditorModule { }
