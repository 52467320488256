import { Component, OnInit } from '@angular/core';
import { PrintData } from 'src/app/models/print-data';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-print-preview',
  templateUrl: './print-preview.component.html',
  styleUrls: ['./print-preview.component.scss']
})
export class PrintPreviewComponent implements OnInit {

  public printData!: PrintData;

  constructor(
    private _printService: PrintService
  ){ }
  
  ngOnInit() {

    this._printService.getPrintData().subscribe( printData => {
      this.printData = printData;
      console.log("printData", printData);
    });
  
  }

}
