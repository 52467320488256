<div class="model-form" [formGroup]="standardValues">
  <div class="model-form__names">
    <mat-form-field class="model-form__field model-form__names--model-name" appearance="outline">
      <mat-label>
        {{ 'code' | translate }}
      </mat-label>
      <input formControlName="code" matInput />
    </mat-form-field>

    <mat-form-field class="model-form__field model-form__names--collection-name" appearance="outline">
      <mat-label>
        {{ 'collection' | translate }}
      </mat-label>
      <input formControlName="collection" matInput />
    </mat-form-field>
  </div>

  <mat-form-field class="model-form__field" appearance="outline">
    <mat-label>
      {{ 'desc' | translate }}
    </mat-label>
    <!-- <textarea formControlName="desc" matInput></textarea> -->
    <input formControlName="desc" matInput/>
  </mat-form-field>

  <mat-form-field class="model-form__field" appearance="outline">
    <mat-label>
      {{ 'resp' | translate }}
    </mat-label>
    <input formControlName="resp" matInput />
  </mat-form-field>

  <mat-form-field class="model-form__field" appearance="outline">
    <mat-label>
      {{ 'obs' | translate }}
    </mat-label>
    <input formControlName="obs" matInput />
  </mat-form-field>

  <div class="model-form__names">
    <!-- <mat-form-field class="model-form__field model-form__names--collection-name" appearance="outline">
      <mat-label>
        {{ getFieldLabel('base_cost') }}
      </mat-label>
      <input formControlName="base_cost" matInput />
    </mat-form-field> -->


      <mat-form-field appearance="outline" class="model-form__field model-form__names--collection-name">
        <mat-label>{{ 'base_cost' | translate }}</mat-label>
        <input formControlName="base_cost" matInput
          formatDecimal
          type="text"
          [value]="standardValues.get('base_cost')?.value" />
          <span style="color: grey; margin-right: 5px;" matSuffix> {{getCurrency()}}</span>
      </mat-form-field>


    <mat-form-field class="model-form__field model-form__names--collection-name" appearance="outline">
      <mat-label>
        {{ 'max_total' | translate }}
          <mat-icon  style="margin-bottom: -5px; margin-left: 10px; color: #F569AA;" class="material-icons-outlined"
            matSuffix
            matTooltip="{{ 'propertyEditorPage.datasheetStepperComponent.forms.garment.inputs.maxTotalInfo.label' | translate }}"
            matTooltipShowDelay="500"
            matTooltipTouchGestures="auto"
            >info</mat-icon>
      </mat-label>
      <input formControlName="maxTotal" matInput
        type="text"
        formatDecimal
        [value]="standardValues.get('maxTotal')?.value"
      />
      <span style="color: grey; margin-right: 5px;" matSuffix> {{getCurrency()}}</span>
    </mat-form-field>
  </div>
  <div class="model-form__cost">

    <mat-form-field class="model-form__field model-form__cost--model-cost" appearance="outline">
      <mat-label>
        {{ 'mat_max_cost' | translate }}
        <mat-icon  style="margin-bottom: -5px; margin-left: 10px; color: #F569AA;" class="material-icons-outlined"
        matSuffix
        matTooltipShowDelay="500"
        matTooltipTouchGestures="auto"
        matTooltip="{{ 'propertyEditorPage.datasheetStepperComponent.forms.garment.inputs.matTotalInfo.label' | translate }}"
        >info</mat-icon>
      </mat-label>
      <input formControlName="matMaxCost"
        matInput
        formatDecimal
        type="text"
        [value]="standardValues.get('matMaxCost')?.value"
        />
        <span style="color: grey; margin-right: 5px;" matSuffix> {{getCurrency()}}</span>
    </mat-form-field>

    <mat-form-field class="model-form__field model-form__cost--model-cost" appearance="outline">
      <mat-label>
        {{ 'act_max_cost' | translate }}
        <mat-icon  style="margin-bottom: -5px; margin-left: 10px; color: #F569AA;" class="material-icons-outlined"
        matSuffix
        matTooltipShowDelay="500"
        matTooltipTouchGestures="auto"
        matTooltip="{{ 'propertyEditorPage.datasheetStepperComponent.forms.garment.inputs.actTotalInfo.label' | translate }}"
        >info</mat-icon>
      </mat-label>
      <input formControlName="actMaxCost" matInput
        formatDecimal
        type="text"
        [value]="standardValues.get('actMaxCost')?.value"
      />
      <span style="color: grey; margin-right: 5px;" matSuffix> {{getCurrency()}}</span>
    </mat-form-field>
  </div>
</div>
