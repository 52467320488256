import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Category, CategoryField } from 'src/app/models/category';
import { Color } from 'src/app/models/color';
import { Field } from 'src/app/models/field';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})

export class ColorPickerComponent implements OnInit {
  @Input() form_Group!:FormGroup;
  @Input() field!: any
  colorFormGroup!: FormGroup

  constructor(){}

  ngOnInit(): void {
    this.colorFormGroup = this.form_Group.get(this.field.name) as FormGroup;

    //this block will change the color.name when a new color is selected on color picker
    // this.colorFormGroup.controls["value"].valueChanges.subscribe((color) =>
    //   this.colorFormGroup.controls["name"].setValue(color, {
    //     emitEvent: false,
    //   })
    // )
  }

  getFormControl(controlName: string): FormControl {
    const control = this.colorFormGroup.get(controlName);
    if (control instanceof FormControl) {
      return control;
    }
    return new FormControl();
  }

}
