<div class="dialog-header">
  <h2 mat-dialog-title class="dialog-header__tittle">
    {{ "referenceDialog.title" | translate }}</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="!isLoading && data$?.length === 0">
  <ng-container>
    <app-mensage [messageType]="MessageType.copyMoveNotFound"></app-mensage>
  </ng-container>
</div>
<div *ngIf="!isLoading && HttpError">
  <ng-container>
    <app-mensage [messageType]="MessageType.httpError" [isProcessing]="isProcessing" (retryClicked)="retryFunction()"></app-mensage>
  </ng-container>
</div>

<div *ngIf="data$ !== null && data$ !== undefined && data$.length > 0">
<div mat-dialog-content class="dialog-content" >
  <span class="dialog-content__title">{{ 'referenceDialog.message' | translate }} {{ 'referenceDialog.secondMessage' | translate }}</span>
  <app-serch-bar (searchTermEmmit)="recieveSearchTerm($event)"></app-serch-bar>
  <div class="dialog-content__variants-list">

    <table class="table" [ngClass]="{'disabled': isLoading}" [ngStyle]="isLoading && {'opacity': '0.5'}||{'opacity': '1'}" *ngIf="data$">
      <tr class="table__row" *ngFor="let value of data$ | appFilter :searchTerm;"
        [class.selected]="value.checked">
        <td><mat-checkbox class="check" disableRipple [checked]="value.checked"
            (click)="selectedVariantHandle(value) " class="table__data table__data--checkbox"></mat-checkbox></td>
        <td (click)="selectedVariantHandle(value)" class="table__data" appHighlight [searchedWord]="searchTerm" [content]="value.name"
          [setTitle]="true">
          {{value.target_name }}
        </td>
      </tr>
    </table>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-button class="button" [mat-dialog-close]="false">
    {{ 'shared.actions.cancel.label' | translate }}
  </button>
  <button-filled [color]="'primary'" [height]="32" style="margin-left: 16px;" [isLoading]="isProcessing" [disabled]="isProcessing" (click)="referencesHandler(data.function)">
    {{ "shared.actions.copy.label" | translate }}
  </button-filled>
</div>
</div>
