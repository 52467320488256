import { Field } from "./field";
import { Group } from "./groups";
import { Image } from "./image";
import { Reference } from "./reference";
import defaultVariant, { Variant } from "./variant";

export interface Garment {
  uid: string,
  fields: Field[],
  garments?: Group[],
  materials: Reference[],
  activities: Reference[],
  measures: Reference[],
  generics: Reference[],
  patterns?: Reference[],
  markers?: Reference[],
  fashion_studio?: Reference[],
  images?: Image[],
  reference_groups?: Group[],
  pattern_groups?: Group[],
  marker_groups?: Group[],
  variants: Variant[],
}

export const defaultGarment: Garment = {
  uid: '',
  fields: [
    {
      name: "code",
      value: "",
      read_only: false
    },
    {
      name: "collection",
      value: "",
      read_only: false
    },
    {
      name: "desc",
      value: "",
      read_only: false
    },
    {
      name: "resp",
      value: "",
      read_only: false
    },
    {
      name: "obs",
      value: "",
      read_only: false
    },
    {
      name: "base_cost",
      value: 0,
      read_only: false
    },
    {
      name: "max_total",
      value: 0,
      read_only: true
    },
    {
      name: "mat_max_cost",
      value: 0,
      read_only: true
    },
    {
      name: "act_max_cost",
      value: 0,
      read_only: true
    }
  ],
  garments: [],
  materials: [],
  activities: [],
  measures: [],
  generics: [],
  patterns: [],
  markers: [],
  fashion_studio: [],
  images: [],
  reference_groups: [],
  pattern_groups: [],
  marker_groups: [],
  variants: [],
}

export default defaultGarment;