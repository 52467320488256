import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject } from 'rxjs';
import { ImageCookiesService } from './services/image-cookies.service';
import { LocalStorageKeys } from './models/enums/local-storage-keys';
import { version } from '../environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FichaWeb_MVP';
  isAuthorized = false;
  appVersion = version;

  public readonly loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private imageCookiesService: ImageCookiesService,
    private translateService: TranslateService
  ) {
    this.getCurrentLanguage();
  }

  ngOnInit() {}

  ngOnDestroy(): void { }

  authEvent(isAuth: boolean) {
    this.isAuthorized = isAuth;
    this.loading$.next(false);
    this.imageCookiesService.createSignedTokens().subscribe();
  }

  setLanguage(userLang: string) {
    const languageCode = userLang.split('-')[0];    
    this.translateService.setDefaultLang(languageCode);
    this.translateService.use(languageCode);
    localStorage.setItem(LocalStorageKeys.USER_CURRENT_LANGUAGE, userLang);
  }

  private getCurrentLanguage(): void {
    const userCurrentLanguage = localStorage.getItem(LocalStorageKeys.USER_CURRENT_LANGUAGE);
    if(userCurrentLanguage != null) {
      this.setLanguage(userCurrentLanguage);
    }else{
      this.setLanguage(navigator.language);
    }
  }
}
