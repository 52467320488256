<app-skeleton-loader *ngIf="isLoading" [skeletonType]="'update-ref'"></app-skeleton-loader>

<div class="dialog_container" *ngIf="!isLoading">

  <div class="dialog-header">
    <div class="dialog-header_tittleCont">
      <span mat-dialog-title class="dialog-header_tittleCont__tittle">{{ 'diffDialog.header.title' | translate }}</span>
      <span *ngIf="erpDiffs.length == 1" class="dialog-header_tittleCont__subtittle">{{ 'diffDialog.header.subtitle.one' | translate}}</span>
      <span *ngIf="erpDiffs.length > 1 " class="dialog-header_tittleCont__subtittle">{{ 'diffDialog.header.subtitle.plural' | translate: { count: erpDiffs.length } }}</span>
    </div>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ng-container *ngFor="let item of erpDiffs | paginate: { itemsPerPage: 1, currentPage: currentPage }; let itemIndex = index">
    <div mat-dialog-content class="dialog-content">
      <div class="dialog-content_refName">
        <span>{{ item.reference.fields[0].value }}</span>
      </div>
      <div class="dialog-content_diff-container">
        <mat-tab-group class="dialog-content_diff-container_tabs">
          <mat-tab label="{{ 'diffDialog.content.dataTab.label' | translate }}">
            <div class="dialog-content_diff-container_tabs_data-container">

              <div class="dialog-content_diff-container_tabs_data-container_current-refData" [ngClass]="{ 'selected-ref-border': keepDSRef.includes(item) }">
                <ng-container *ngFor="let field of item.fieldsFw; let isOdd = odd; let isEven = even; let i = index">
                  <div class="dialog-content_diff-container_tabs_data-container_current-refData_item" [ngClass]="{ 'odd': isOdd, 'even': isEven }">
                    <span class="dialog-content_diff-container_tabs_data-container_current-refData_item-field">{{ field.name | translate }}</span>
                    <span class="dialog-content_diff-container_tabs_data-container_current-refData_item-value">{{ field.value }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="dialog-content_diff-container_tabs_data-container_icon">
                <span><img src="../../../assets/images/ícone-atualizar-ref.svg" alt="{{ 'diffDialog.content.dataTab.updateIcon.alt' | translate }}"></span>
              </div>
              <div class="dialog-content_diff-container_tabs_data-container_current-refData" [ngClass]="{ 'selected-ref-border': updateDSRef.includes(item) }">
                <ng-container *ngFor="let field of item.fieldsErp; let isOdd = odd; let isEven = even; let i = index">
                  <div class="dialog-content_diff-container_tabs_data-container_current-refData_item" [ngClass]="{ 'odd': isOdd, 'even': isEven }">
                    <span class="dialog-content_diff-container_tabs_data-container_current-refData_item-field">{{ field.name | translate }}</span>
                    <span class="dialog-content_diff-container_tabs_data-container_current-refData_item-value">{{ field.value }}</span>
                  </div>
                </ng-container>
              </div>

            </div>
            <div class="dialog-content_diff-container_tabs_contTittle">
              <span class="dialog-content_diff-container_tabs_contTittle_1">{{"diffDialog.content.container.actual" | translate}}</span>
              <span class="dialog-content_diff-container_tabs_contTittle_2">{{"diffDialog.content.container.updated" | translate}}</span>
            </div>
          </mat-tab>
          <!-- <mat-tab label="{{ 'diffDialog.content.imagesTab.label' | translate }}">
            <div class="dialog-content_diff-container_tabs_image-container">
              <div class="dialog-content_diff-container_tabs_image-container_current-refImage">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2SqWl6Bx9nJOHWkwGdWxWSnOT7z4Dt_HJGP1Jpehvvg&s" alt="{{ 'diffDialog.content.imagesTab.currentRefImage.alt' | translate }}">
              </div>
              <div class="dialog-content_diff-container_tabs_data-container_icon">
                <span><img src="../../../assets/images/ícone-atualizar-ref.svg" alt="{{ 'diffDialog.content.dataTab.updateIcon.alt' | translate }}"></span>
              </div>
              <div class="dialog-content_diff-container_tabs_image-container_current-refImage">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2SqWl6Bx9nJOHWkwGdWxWSnOT7z4Dt_HJGP1Jpehvvg&s" alt="{{ 'diffDialog.content.imagesTab.currentRefImage.alt' | translate }}">
              </div>
            </div>
          </mat-tab> -->
        </mat-tab-group>
        <div *ngIf="erpDiffs.length > 1" class="dialog-content_diff-container_pagination">
          <pagination-controls (pageChange)="changePage($event)" nextLabel="" previousLabel=""></pagination-controls>
        </div>
      </div>
    </div>
  </ng-container>

  <div mat-dialog-actions class="dialog-actions">
    <div >
      <mat-checkbox *ngIf="erpDiffs.length > 1" [(ngModel)]="applyToAll">{{ 'diffDialog.actions.applyToAll' | translate }}</mat-checkbox>
    </div>
    <div class="dialog-actions__buttons">
      <button-text class="exit" (click)="keepRef()">{{ applyToAll ? ('diffDialog.actions.buttons.keepRef.applyToAllLabel' | translate) : ('diffDialog.actions.buttons.keepRef.label' | translate) }}</button-text>
      <button-filled class="save" (click)="updateRef()">{{ applyToAll ? ('diffDialog.actions.buttons.updateRef.applyToAllLabel' | translate) : ('diffDialog.actions.buttons.updateRef.label' | translate) }}</button-filled>
    </div>
  </div>

</div>
