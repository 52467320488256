import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Variation, VariationItem, VariationUtil } from 'src/app/models/Variation';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { ConfirmationService } from 'primeng/api';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-advanced-variant-mananger',
  templateUrl: './advanced-variant-mananger.component.html',
  styleUrls: ['./advanced-variant-mananger.component.scss']
})
export class AdvancedVariantManangerComponent implements OnInit {
  @Input() variationInput?: Variation;
  @ViewChild('inputField') inputField!: ElementRef;

  editedVariation?: Variation;
  confirmation!: ConfirmationService;

  constructor(
    public dialogRef: MatDialogRef<AdvancedVariantManangerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _datasheetService: DatasheetService,
    private _snack: SnackbarService,
    private _confirmationService: ConfirmationService,
    private _analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.editedVariation = this.data;
    this.editedVariation = VariationUtil.createSimilarVariarion( this.data );

    this._analyticsService.trackStartEvent("variantAdvManager");
  }

  async deleteNode(nodeToDelete: any) {
    let message: any;
    let lvl = this.getNodeLvl(nodeToDelete);

    if( lvl! <= 1 ) {
      message = 'Ao Remover esta variação, todas as referentcias e sub-variantes relacionadas a ela também serão removidas ';
    } else {
      message = "Ao remover esta variação, todas as referencias relacionas a ela serão removidas";
    }

    const confirmed = await this.confirm(message);
    if (confirmed) {
      this.editedVariation!.itens = this.removeNode(this.editedVariation!.itens, nodeToDelete);
    }
  }

  private removeNode(data: any[], nodeToDelete: any): any[] {
    return data.reduce((acc: any[], node) => {
      if (node === nodeToDelete) {
        return acc;
      }
      if (node.children) {
        node.children = this.removeNode(node.children, nodeToDelete);
      }

      acc.push(node);
      return acc;
    }, []);
  }

  toggleEditMode(node: any): void {
    node.isEditing = !node.isEditing;
  }

  saveNode( node: VariationItem, value: any): void {
    const temp = node.name;
    node.name = value;
    node.isEditing = false;

    if (!this.validateUniqueNames(this.editedVariation!)) {
      node.name = temp;
      this._snack.warnMessage("Já existe uma variante com este nome")
      return;
    }
  }

  editNode(node: any): void {
    node.isEditing = true;
    setTimeout(() => {
      if (this.inputField) {
        this.inputField.nativeElement.focus();
        this.inputField.nativeElement.click();
        this.inputField.nativeElement.select();
      }
    }, 0);
  }

  saveEdit(){
    this._datasheetService.updateVariants(this.editedVariation!);
    this.closeDialog();
    this._analyticsService.trackEndEvent("variantAdvManager");
  }

  private getNodeLvl(node: VariationItem, currentLevel: number = 0, data?: VariationItem[]): number | null {
    if (!data) {
      data = this.editedVariation!.itens;
    }

    for (let item of data) {
      if (item === node) {
        return currentLevel;
      }
      if (item.children?.length) {
        const depth = this.getNodeLvl(node, currentLevel + 1, item.children);
        if (depth !== null) {
          return depth;
        }
      }
    }
    return null;
  }

  addChild(node: any /*VariationItem*/) {
    const nodeDepth = this.getNodeLvl(node)! + 1;

    switch (nodeDepth) {
      case 1:
        const parent = this.editedVariation!.itens;
        const newNode1 = VariationUtil.createVariantItem(`${node.name} (${parent.length+1})`, node.category );
        for( let child of node.children || [] ) {
          let secondLevel = VariationUtil.createVariantItem(child.name, child.category );
          for( let grandChild of child.children || [] ) {
            let thirdLevel = VariationUtil.createVariantItem(grandChild.name, grandChild.category );
            secondLevel.children!.push(thirdLevel);
          }
          newNode1.children?.push(secondLevel);
        }
        parent.push( newNode1 );
        
        break;
      case 2:
        const newNode2 = VariationUtil.createVariantItem(`${node.name} (${node.parent!.children.length+1})`, node.category );
        for( let child of node.children || [] ) {
          newNode2.children?.push(
            VariationUtil.createVariantItem(child.name, child.category )
          );
        }
        node.parent!.children.push( newNode2 );
        
        break;
      case 3:
        const newNode3 = VariationUtil.createVariantItem(`${node.name} (${node.parent!.children.length+1})`, node.category );
        node.parent!.children.push( newNode3 );
        break;
    
      default:
        break;
    }

  }

  isLastNode(node: VariationItem): boolean {
    const nodeDepth = this.getNodeLvl(node);
    return nodeDepth !== null && nodeDepth >= 2;
  }

  validateUniqueNameRecursive(currentItem: VariationItem, items: VariationItem[]): boolean {

    const siblings = items.filter(item => item.category === currentItem.category && item !== currentItem);
    const duplicateSibling = siblings.find(item => item.name === currentItem.name);
    if (duplicateSibling !== undefined) {
      return false;
    }

    for (const child of currentItem.children || []) {
      if (!this.validateUniqueNameRecursive(child, currentItem.children!)) {
        return false;
      }
    }

    return true;
  }

  validateUniqueNames(variation: Variation): boolean {
    for (const item of variation.itens) {
      if (!this.validateUniqueNameRecursive(item, variation.itens)) {
        return false;
      }
    }
    return true;
  }

  confirm(message: any): Promise<boolean> {
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: message,
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        rejectButtonStyleClass: 'p-button-text',
         key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

  closeDialog() {
    this.dialogRef.close( this.editedVariation! );
  }
}
