

  <div class="keen-slider " #sliderRef>

    <div class="keen-slider__slide welcome">

        <div class="grid-isa-home-new">
          <img class="img-isa home-welcome" src="../../../assets/images/home/Isa.svg" />
        </div>

        <div class="text-field">
          <h5 class="text-welcome primary">
            {{ 'homePage.cards.welcome.greeting' | translate: {name: user} }}
          </h5>

          <span class="subtitle-1 home-items-text-color" >
            {{ 'homePage.cards.welcome.title' | translate }}
          </span>
          <p class="subtitle-2 home-items-text-color">
            {{ 'homePage.cards.welcome.subtitle' | translate }}
            <span class="configBTN">
              {{ 'homePage.cards.welcome.configureErpButton.label' | translate }}
            </span>
          </p>
        </div>
    </div>

    <div class="navigation">
      <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
        [class]="'dot ' + (i === currentSlide ? 'active' : '')" appDataCyRemover
        [attr.data-cy]="'@home/banner-dot-button#' + i"></button>
    </div>
  </div>

