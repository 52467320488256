<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h3>{{ errorMsg1 }}</h3>
            <h1>
                <span>{{ errorCode[0] }}</span>
                <span>{{ errorCode[1] }}</span>
                <span>{{ errorCode[2] }}</span>
            </h1>
        </div>
    <h2>{{ errorMsg2 }}</h2>
    </div>
</div>