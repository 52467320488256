import { Garment } from './../../models/garment';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, } from '@angular/forms';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { Subject } from 'rxjs';
import { Variant } from 'src/app/models/variant';
import { ReferenceType } from 'src/app/models/enums/reference-type';
import defaultReferences, { Reference } from 'src/app/models/reference';
import { TranslateService } from '@ngx-translate/core';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { Datasheet } from 'src/app/models/datasheet';
import { ReferenceTarget } from 'src/app/models/referenceTarget';
import { ConfirmationService } from 'primeng/api';
import { AddReferenceDialogComponent } from '../add-reference-dialog/add-reference-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AddFwReferenceComponent } from '../add-fw-reference/add-fw-reference.component';




@Component({
  selector: 'app-references-list',
  templateUrl: './references-list.component.html',
  styleUrls: ['./references-list.component.scss'],
})
export class ReferencesListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data!: Variant | Garment;
  @Input() currentLevel!: number;  // 0: garment | 1: variant | 2: subVariant | 3: subSubVariant
  @Input() variantIndexes!: number[];
  @Output() selectedReferenceEmmiter = new EventEmitter<any>();

  RefType!:ReferenceType;

  currentListPath?: Reference[];
  searchTerm: string = '';
  private destroy$ = new Subject<boolean>();
  selectedReferenceIndex: number | null = null;

  targetCopy : ReferenceTarget[] = [];
  targetMove1 : ReferenceTarget[] = [];
  targetMove2 : ReferenceTarget[] = [];
  targetMove3 : ReferenceTarget[] = [];

  // references are keys used by translateService (assets/i18n/*.json) at html level
  public references: string[] = [
    'models.reference.materials',
    'models.reference.activities',
    'models.reference.measurements',
    'models.reference.generics',
    'models.reference.fashion_studio',
    'models.reference.patternGroup',
    'models.reference.markerGroup'
  ];
  datasheet!: Datasheet;
  confirmation!: ConfirmationService;

  constructor(
    private _fb: FormBuilder,
    private _translateService: TranslateService,
    private _datasheetService: DatasheetService,
    private _confirmationService: ConfirmationService,
    private _dialog: DialogService,
    private _translate: TranslateService,
    private _analyticsService: AnalyticsService,
  ) {
    this.datasheet = this._datasheetService.getDatasheet();

    if(this.datasheet.isStandAlone()) {
      this.references.splice(-3);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);

    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.currentListPath = this.data.materials;
    this.RefType = ReferenceType.Material
  }

  ngOnChanges(){

    switch (this.RefType) {
      case ReferenceType.Material:
        this.currentListPath = this.data.materials;
        break;
      case ReferenceType.Activity:
        this.currentListPath = this.data.activities;
        break;
      case ReferenceType.Measures:
        this.currentListPath = this.data.measures;
        break;
      case ReferenceType.Free:
        this.currentListPath = this.data.generics;
        break;
      case ReferenceType.Marker:
        this.currentListPath = this.data.marker_groups;
        break;
      case ReferenceType.Pattern:
        this.currentListPath = this.data.pattern_groups;
        break;
      case ReferenceType.FashionStd:
        this.currentListPath = this.data.fashion_studio;
        break;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.searchTerm = '';
    switch (tabChangeEvent.index) {
      case 0:
        this.currentListPath = this.data.materials;
        this.RefType = ReferenceType.Material;
        break;
      case 1:
        this.currentListPath = this.data.activities;
        this.RefType = ReferenceType.Activity;
        break;
      case 2:
        this.currentListPath = this.data.measures;
        this.RefType = ReferenceType.Measures;
        break;
      case 3:
        this.currentListPath = this.data.generics;
        this.RefType = ReferenceType.Free;
        break;
      case 4:
        this.currentListPath = this.data.fashion_studio;
        this.RefType = ReferenceType.FashionStd;
        break;
      case 5:
        this.currentListPath = this.data.pattern_groups;
        this.RefType = ReferenceType.Pattern;
        break;
      case 6:
        this.currentListPath = this.data.marker_groups;
        this.RefType = ReferenceType.Marker;
        break;
    }
  }

  public SelectedReferenceEmmit(index: number) {
    this.selectedReferenceIndex = index;
    const item = {ReferenceType : this.RefType, ReferenceIndex: index}
    this.selectedReferenceEmmiter.emit(item);
  }

  public onMenuClick(index: number) {
    this.selectedReferenceIndex = index;

    this.targetCopy = this._datasheetService.getDatasheet().listPossibleTargetsToCopy(this.currentListPath![index].uid, this.RefType);
    let moveTo = this._datasheetService.getDatasheet().listPossibleTargetsToMove(this.currentListPath![index].uid, this.RefType);
    
    this.targetMove1 = moveTo.filter(target => target.level === 1) || [];
    this.targetMove2 = moveTo.filter(target => target.level === 2) || [];
    this.targetMove3 = moveTo.filter(target => target.level === 3) || [];
  }

  public async onDeleteClick(index: number) {
    console.log("Delete: ", this.currentListPath![index]);

    const confirmed = await this.confirm("alerts.referenceActions.removing.title", "alerts.referenceActions.removing.confirm");
    if( !confirmed ) {
      return;
    }

    this._datasheetService.removeReference(
      this.currentListPath!,
      this.currentListPath![index],
      this.variantIndexes,
      this.RefType
    );
  }

  public onCopyClick(index: number, target: ReferenceTarget) {
    console.log("Copy: ", this.currentListPath![index], target);
    this._datasheetService.copyReference(
      this.currentListPath![index],
      target,
      this.RefType,
      this.variantIndexes
    );
  }

  public async onMoveClick(index: number, target: ReferenceTarget, event: Event) {
    console.log("Move: ", this.currentListPath![index], target);

    let needConfirm = this.currentListPath![index].hasOwnProperty("variation_name") && this.currentListPath![index].variation_name != '';
    if( needConfirm ) {

      const confirmed = await this.confirm("refList.header", "refList.moveMsg");
      if( confirmed ) {
        this._datasheetService.moveReference(
          this.currentLevel,
          this.currentListPath!,
          this.currentListPath![index],
          target,
          this.RefType,
          this.variantIndexes
        );
      }
      event.stopPropagation();
    } else {
      this._datasheetService.moveReference(
        this.currentLevel,
        this.currentListPath!,
        this.currentListPath![index],
        target,
        this.RefType,
        this.variantIndexes
      );
    }
  }

  public onErpMenuClick( event: MouseEvent ) {
    event.stopPropagation();
  }

  public openModalAddReference(  ) {
    let targetName = "Modelo";

    if( !this.data.hasOwnProperty('variants') ) {
      targetName = this.data!.fields[0]!.value as string;
    }
    this._dialog.displayDialog(AddReferenceDialogComponent, {targetList: this.data, targetName, refType: this.RefType, variantIndexes: this.variantIndexes}, '50%', '90%', 'dialog-responsive');
  }

  public openModalAddFwReference(  ) {
    let targetName = "Modelo";

    if( !this.data.hasOwnProperty('variants') ) {
      targetName = this.data!.fields[0]!.value as string;
    }
    this._dialog.displayDialog(AddFwReferenceComponent, {targetList: this.data, targetName, refType: this.RefType, variantIndexes: this.variantIndexes}, '50%', '90%', 'dialog-responsive');
  }

  public onAddReference() {
    switch (this.RefType) {
      case ReferenceType.Material:
        if( !this.data.materials ) {
          this.data.materials = [];
        }
        this.currentListPath = this.data.materials;
        this._datasheetService.addReference(
          this.currentListPath,
          defaultReferences.material,
          this.variantIndexes,
          this.RefType,
          this._translateService.instant(`models.new.materials`)
        );
        break;
      case ReferenceType.Activity:
        if( !this.data.activities ) {
          this.data.activities = [];
        }
        this.currentListPath = this.data.activities;
        this._datasheetService.addReference(
          this.currentListPath,
          defaultReferences.activity,
          this.variantIndexes,
          this.RefType,
          this._translateService.instant(`models.new.activities`)
        );
        break;
      case ReferenceType.Measures:
        if( !this.data.measures ) {
          this.data.measures = [];
        }
        this.currentListPath = this.data.measures;
        this._datasheetService.addReference(
          this.currentListPath,
          defaultReferences.measure,
          this.variantIndexes,
          this.RefType,
          this._translateService.instant(`models.new.measures`)
        );
        break;
      case ReferenceType.Free:
        if( !this.data.generics ) {
          this.data.generics = [];
        }
        this.currentListPath = this.data.generics;
        this._datasheetService.addReference(
          this.currentListPath,
          defaultReferences.generic,
          this.variantIndexes,
          this.RefType,
          this._translateService.instant(`models.new.generics`)
        );
        break;
    }

    this._analyticsService.trackSingleEvent("createNewReference");
  }

  confirm(title: string, message: string): Promise<boolean> {
    const header = this._translate.instant(title);
    const acceptLabel = this._translate.instant('refList.acceptLabel');
    const rejectLabel = this._translate.instant('refList.rejectLabel');
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message:  this._translate.instant(message),
        header: header,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        rejectButtonStyleClass: 'p-button-text',
        key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

}
