import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-misc',
  templateUrl: './button-misc.component.html',
  styleUrls: ['./button-misc.component.scss']
})
export class ButtonMiscComponent implements OnInit {
  @Output() click = new EventEmitter();
  @Input() height: number = 32;
  @Input() color: string = 'primary';
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() badge?: number;
  constructor() { }

  ngOnInit(): void {
  }

  public onClick(): void {
    this.click.emit();
  }
}
