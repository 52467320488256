<form [formGroup]="form">
  <div class="container" *ngFor="let field of data?.isaModelFields">

    <mat-form-field style="width: 100%;" *ngIf="field.type === 'Text'" appearance="outline">
      <mat-label>{{ field.key | translate }}</mat-label>
      <input matInput [formControlName]="field.key" type="text" />
    </mat-form-field>

    <div class="multiple" *ngIf="field.type === 'Multiple'">
      <label style="color: grey; margin-right: 15px;">{{ field.key | translate }}</label>
      <ng-select
        [items]="field.defaultChoices"
        bindLabel="value"
        [multiple]="true"
        [closeOnSelect]="false"
        [(ngModel)]="field.choices"
        [formControlName]="field.key">
      </ng-select>
    </div>

    <mat-form-field style="width: 100%;" *ngIf="field.type === 'Currency'" appearance="outline">
      <mat-label>{{ field.key | translate }}</mat-label>
      <input [formControlName]="field.key"
        matInput
        formatDecimal
        type="text"

        />
        <span style="color: grey; margin-right: 15px;" matSuffix> {{formatCurrency()}}</span>
    </mat-form-field>

    <div class="boolean" *ngIf="field.type === 'Conditional'">
      <label style="color: grey;">{{ field.key | translate }}</label>
      <mat-radio-group [formControlName]="field.key">
        <mat-radio-button [value]="null">{{'shared.actions.notSelected.label' | translate}}</mat-radio-button>
        <mat-radio-button [value]="true">{{'shared.actions.yes.label' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false">{{'shared.actions.no.label' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="calendar" *ngIf="field.type === 'Date'">
      <mat-form-field class="model-form__field" appearance="outline" *ngIf="!field.properties?.automatic_datetime">
        <mat-label>{{ field.key | translate }}</mat-label>
        <input [formControlName]="field.key" matInput [matDatepicker]="picker3"
           [required]="false">
        <mat-datepicker-toggle matIconSuffix [for]="picker3" style="top: 0;right: 0;"></mat-datepicker-toggle>
        <mat-datepicker-toggle style="color: grey" matSuffix (click)="clearDate(field.key)" style="position: absolute;top: 0;right: 32px;">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-form-field style="width: 100%;" *ngIf="field.type === 'Number'" appearance="outline">
      <mat-label>{{ field.key | translate }}</mat-label>
      <input matInput [formControlName]="field.key" type="number" />
    </mat-form-field>

  </div>
</form>
