import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfigurationDialogComponent } from '../../configuration-dialog/configuration-dialog.component';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';


export enum MessageDialogType {
  SAVE_DATASHEET,
  ERP_NOT_CONFIGURED,
  ERP_BAD_CONFIGURED,
  UPDATE_DS_CONFIGS,
}


@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  private msgType: MessageDialogType = MessageDialogType.SAVE_DATASHEET;
  public dialogTitle: string = "";
  public dialogSubtitle: string = "";
  public dialogConfirmMsg: string = "";
  public labelButtonOne: string = "";
  public labelButtonTwo: string = "";

  shouldUpdateDs: boolean = false;

  public showOptions: boolean = false;

  constructor(
    private _dataStorage: DataStorageService,
    private _datasheetService: DatasheetService,
    private _dialogRef: MatDialogRef<MessageDialogComponent>,
    private _dialog: DialogService,
    private _translate: TranslateService,
    private _confirmationService: ConfirmationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if( data && data.hasOwnProperty("type") ) {
      this.msgType = data.type;
      if(this.msgType == MessageDialogType.UPDATE_DS_CONFIGS){
        this.showOptions = true;
      }
    } else {
      this.msgType = MessageDialogType.SAVE_DATASHEET;
    }
    this.setDialogAttr();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public actionButtonOne() {
    switch (this.msgType) {
      case MessageDialogType.SAVE_DATASHEET:
        this.cleanDsCache();
        break;

      case MessageDialogType.UPDATE_DS_CONFIGS:
        this._dialogRef.close(false);
        break;

      default:
        break;
    }
  }

  public actionButtonTwo() {
    switch (this.msgType) {
      case MessageDialogType.SAVE_DATASHEET:
        this.saveDatasheet();
        break;

      case MessageDialogType.ERP_BAD_CONFIGURED:
      case MessageDialogType.ERP_NOT_CONFIGURED:
        this.openErpDialog();
        break;

        case MessageDialogType.UPDATE_DS_CONFIGS:
          this._dialogRef.close( this.shouldUpdateDs );
          break;

      default:
        break;
    }
  }

  private setDialogAttr() {
    switch (this.msgType) {
      case MessageDialogType.SAVE_DATASHEET:
        this.dialogTitle = "messageDialogComponent.title";
        this.dialogSubtitle = "messageDialogComponent.subtitle";
        this.dialogConfirmMsg = "messageDialogComponent.confirmation";
        this.labelButtonOne = "shared.actions.exitWithoutSave.label";
        this.labelButtonTwo = "shared.actions.save.label";
        break;

      case MessageDialogType.ERP_NOT_CONFIGURED:
        this.dialogTitle = "messageDialogComponent.erp.title";
        this.dialogSubtitle = "messageDialogComponent.erp.subtitle";
        this.dialogConfirmMsg = "messageDialogComponent.erp.confirmation";
        this.labelButtonOne = "shared.actions.cancel.label";
        this.labelButtonTwo = "messageDialogComponent.erp.configure";
        break;

      case MessageDialogType.ERP_BAD_CONFIGURED:
        this.dialogTitle = "messageDialogComponent.erp.title";
        this.dialogSubtitle = "messageDialogComponent.erp.subtitle2";
        this.dialogConfirmMsg = "messageDialogComponent.erp.confirmation";
        this.labelButtonOne = "shared.actions.cancel.label";
        this.labelButtonTwo = "messageDialogComponent.erp.configure";
        break;

      case MessageDialogType.UPDATE_DS_CONFIGS:
        this.dialogTitle = "messageDialogComponent.config.title";
        this.dialogSubtitle = "messageDialogComponent.config.subtitle2";
        this.dialogConfirmMsg = "messageDialogComponent.config.confirmation";
        this.labelButtonOne = "shared.actions.cancel.label";
        this.labelButtonTwo = "messageDialogComponent.config.configure";
        break;

      default:
        break;
    }
  }

  private saveDatasheet() {

    if( !this._datasheetService.getDatasheet().isReadyToSave() ) {
      this._dialogRef.close(false);

      this._confirmationService.confirm({
        message: this._translate.instant('shared.actions.backToIsa.notReady') ,
        header: this._translate.instant('propertyEditorPage.leaveRoute.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'OK',
        rejectLabel: 'none',
        rejectButtonStyleClass: 'hidden',
        key :"myDialog",
        accept: () => {
          this._dialogRef.close(false );
          return;
        }
      });

      return;
    }

    if (!this._datasheetService.getDatasheet().verifyImagesCount()) {
      this._confirmationService.confirm({
        message: this._translate.instant('shared.actions.backToIsa.maxImagesExceeded'),
        header: this._translate.instant('propertyEditorPage.maxImagesDialog.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'OK',
        rejectLabel: 'none',
        rejectButtonStyleClass: 'hidden',
        key: "maxImagesDialog",
        accept: () => {
          this._dialogRef.close(false);
        }
      });
      return;
    }

    this._dataStorage.storeDataSheet(
      () => {
        this._dialogRef.close(true);
      },
      (error: any) => {
        console.log('Erro ao salvar: ', error);
        this._dialogRef.close(false); // fecha o modal com valor false se ocorrer um erro
      }
    );
  }

  public cleanDsCache(){
    this._datasheetService.leaveRouteWithoutSaving();
  }

  private openErpDialog() {
    this._dialog.displayDialog(ConfigurationDialogComponent, { title: 'erp.forms.title', type:"erp" }, '55%', '', 'dialog-responsive');
  }


}
