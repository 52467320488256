import { Injectable } from '@angular/core';
import { DataStorageService } from './data-storage.service';
import { DatasheetService } from './datasheet.service';
import { Router } from '@angular/router';
import { Observable, catchError, of, tap, throwError, switchMap, take, map } from 'rxjs';
import { LoginService } from './login.service';
import { LocalStorageService } from './localStorage.service';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { IsaService } from './isa.service';
import { IsaMetadata } from '../models/isaMetadata';
import { Variation, VariationLevel } from '../models/Variation';
import defaultVariant from '../models/variant';

@Injectable({
  providedIn: 'root'
})
export class dataResolutionServiceService {

  private hasResolutionFailed = false;

  constructor(
    private _dataStorage: DataStorageService,
    private _datasheetService: DatasheetService,
    public _loginService: LoginService,
    private _localStorageService: LocalStorageService,
    private _isaService: IsaService,
  ) {}

  resolveData(uid: string, modelId: string = '', kanbam: string = '', importFrom: string = ''): Observable<any> {

    if (uid !== '') {
      return this._dataStorage.loadDataSheetFromUid(uid).pipe(
        catchError(() => throwError(() => new Error("Resolver Error"))),
        switchMap((loadedDatasheet) => {
          if (loadedDatasheet) {
            this._datasheetService.setDataSheet(loadedDatasheet);

            if (loadedDatasheet.fw?.standalone) {
              if (!modelId) {
                modelId = `${loadedDatasheet.fw?.isa_model_id}`;
              }
              if (!kanbam) {
                kanbam = `${loadedDatasheet.fw?.isa_kanbam}`;
              }

              return this.getIsaMetadata(modelId, kanbam).pipe(
                tap((isaMetadata) => {
                  this._datasheetService.setIsaMeta(isaMetadata, false);
                }),
                map(() => this._datasheetService.getDatasheet())
              );
            }
          }
          return of(null);
        })
      );
    }

    const username = this._loginService.loginResponse?.userData.name;
    const localCpn = JSON.parse(this._localStorageService.get(LocalStorageKeys.USER_CURRENT_COMPANY));
    let isaMeta: IsaMetadata = {
      username,
      companyName: localCpn.name,
      companyid: localCpn.id
    }

    if (modelId !== '') {
      return this.getIsaMetadata(modelId, kanbam).pipe(
        tap((isaMeta) => {
          const datasheet = this._datasheetService.createDatasheet(isaMeta!);

          this._datasheetService.isaMetaChanged.next(isaMeta);
          this._datasheetService.dataSheetChanged.next(datasheet);

          return of(datasheet);
        }),
        catchError((error) => {
          console.error(error.error.errors[0].message);
          return throwError(() => new Error("Isa Item error"));
        })
      );

    } else if( importFrom ) {
      const datasheet = this._datasheetService.getDatasheet();
      if( Object.keys(datasheet).length === 0 ) {
        return of(null);
      }
      this._datasheetService.dataSheetChanged.next(datasheet);
      return of(datasheet);
    } else {
      const datasheet = this._datasheetService.createDatasheet(isaMeta);
      this._datasheetService.dataSheetChanged.next(datasheet);
      return of(datasheet);
    }
  }

  private isaItem(modelId: string): Observable<any> {
    return this._isaService.getIsaItem(this._localStorageService.getAuthorizationToken(), modelId)
      .pipe(
        tap((res) => {
          return res.data.item;
        }),
        catchError((error) => {
          console.error(error.error.errors[0].message);
          return throwError(() => new Error("Isa Item error"));
        })
      );
  }

  private isaCollection(isaCollectionId: string): Observable<any> {
    return this._isaService.getIsaCollection(this._localStorageService.getAuthorizationToken(), isaCollectionId)
      .pipe(
        tap((res) => {
          return res.data;
        }),
        catchError((error) => {
          alert("Isa Collection error");
          console.error(error.error.errors[0].message);
          return throwError(() => new Error("Isa Collection error"));
        })
      );
  }

  private isaShapes(): Observable<any> {
    return this._isaService.getIsaShapes(this._localStorageService.getAuthorizationToken())
      .pipe(
        tap((res) => {
          return res.data;
        }),
        catchError((error) => {
          alert("Isa Shape error");
          console.error(error.error.errors[0].message);
          return throwError(() => new Error("Isa Shape error"));
        })
      );
  }


  private isaAllCollections(): Observable<any> {
    return this._isaService.getIsaAllCollections(this._localStorageService.getAuthorizationToken())
      .pipe(
        tap((res) => {
          return res.data;
        }),
        catchError((error) => {
          alert("Isa Collections error");
          console.error(error.error.errors[0].message);
          return throwError(() => new Error("Isa Collections error"));
        })
      );
  }

  getIsaMetadata(modelId: string, kanbam: string = ''): Observable<IsaMetadata> {
    const localCpn = JSON.parse(this._localStorageService.get(LocalStorageKeys.USER_CURRENT_COMPANY));
    let isaMeta: IsaMetadata = {
      companyName: localCpn.name,
      companyid: localCpn.id,
      modelId: 0,
      modelName: '',
      description: '',
      shape: '',
      kanbam: kanbam,
      images: [],
      collection: '',
      variationNames: [],
      isaModelFields: []
    };

    return this.isaItem(modelId).pipe(
      switchMap((res) => {
        const item = res.data.item;
        console.log("isa item: ", item);
        
        isaMeta.username = item.assignee ? item.assignee.name : "";
        isaMeta.modelId = item.id;
        isaMeta.modelName = this._isaService.getIsaItemName(item);
        isaMeta.description = item.description;
        isaMeta.shape = item.shape;
        isaMeta.images = this._isaService.handleImages(item);

        this._datasheetService.setIsaMeta( isaMeta, false )

        if (!item.steps.find((stp: { name: string }) => stp.name === kanbam)) {
          for (let stp of item.steps) {
            if (stp.status != 'COMPLETE') {
              isaMeta.kanbam = stp.name;
              break;
            }
          }
        }

        return this.isaCollection(item.collectionId).pipe(
          switchMap((collectionRes) => {
            const collection = collectionRes.data.collections;

            if (collection.length) {
              isaMeta.collection = collection[0].name;
            }

            console.log("isa collection: ", collectionRes);

            return this.isaShapes().pipe(
              switchMap((shapesRes) => {
                const shapes = shapesRes.data.shapes;

                for (let shp of shapes || []) {
                  if (shp.id == item.shapeId) {
                    isaMeta.shape = shp.coverReference;
                    break;
                  }
                }

                console.log("isa shapes: ", shapesRes);

                isaMeta.variationNames = [];
                for (const variation of item.variants || []) {
                  isaMeta.variationNames.push(variation.name);
                }

                return this.getIsaFields(modelId).pipe(
                  tap((isaFieldsRes) => {
                    for(let item of isaFieldsRes.data.getCollectionPropertiesForItem){
                      isaMeta.isaModelFields?.push(item)
                    }
                  }),
                  map(() => isaMeta)
                );
              })
            );
          })
        );
      })
    );
  }


  private getIsaFields(modelId: string):Observable<any>{
    return this._isaService.getIsaModelFields(this._localStorageService.getAuthorizationToken(), modelId)
    .pipe(
      tap((res) => {
        return res.data;
      }),
      catchError((error) => {
        console.error(error.error.errors[0].message);
        return throwError(() => new Error("Isa fields error"));
      })
    );
  }

}
