import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrintComponent } from './print.component';
import { DatasheetResolverService } from 'src/app/services/datasheet-resolver.service';

const routes: Routes = [
  { path: '', component: PrintComponent },
  {
    path: ':uid',
    component: PrintComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrintRoutingModule { }
