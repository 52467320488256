import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { HistoryData } from 'src/app/models/history-data';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { FormBuilder } from '@angular/forms';
import { MessageType } from 'src/app/models/enums/message-type';
import { Subject, debounceTime, fromEvent, shareReplay, takeUntil } from 'rxjs';
import { Datasheet } from 'src/app/models/datasheet';

@Component({
  selector: 'app-history-drawer',
  templateUrl: './history-drawer.component.html',
  styleUrls: ['./history-drawer.component.scss']
})
export class HistoryDrawerComponent implements OnChanges, OnDestroy {
  @Input() isDrawerOpened!: boolean;
  @Output() close_drawer = new EventEmitter();

  fieldsDict: any;

  public historyData! :HistoryData[];
  public historyDataFiltered? : HistoryData[];
  private destroy$ = new Subject<boolean>();
  public uniqueUsers! : string[];
  public selectedUser? : string;
  public selectedDateRange: any;
  public startDate? : Date;
  public endDate?: Date;
  public isLoading: boolean = false;
  HttpError?: boolean = false;
  isProcessing!: boolean ;
  private uid!: string;
  MessageType = MessageType;
  public isMobileView: boolean = false;

  constructor(
    private _datasheetService : DatasheetService,
    private _dataStorage: DataStorageService,
    private _fb: FormBuilder) {
      fromEvent(window, 'resize')
        .pipe(
          takeUntil(this.destroy$),
          shareReplay(),
          debounceTime(500))
        .subscribe((size) => {
          const window = size.target as Window;
          this.isMobileView = (window.innerWidth <= 800);
        });
  }

  ngOnChanges(): void {
    if(this.isDrawerOpened === true){
      this.isLoading = true;
      this.selectedUser = undefined;
      this.startDate = undefined
      this.uid = this._datasheetService.getDatasheetUid();
      this.getHistoryList(this.uid);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onStartDateChange(){
    this.endDate = undefined;
  }

  private getHistoryList(uid: string){
    this._dataStorage.
      loadDatasheetHistory(uid)
      .subscribe({
        next: (data : HistoryData[]) => {
          data.reverse();
          this.handleHttpSucess(data);
          this.extractUniqueUsers(data);
          this.fieldsDict = this._datasheetService.getDatasheet().getCategoryDict();
        },
        error: (error) => {
          console.debug(error);
          this.handleHttpError();
        }
      })
  }

  public closeDrawer() {
    this.selectedUser = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.close_drawer.emit();
  }

  public retryFunction(){
    this.isProcessing = true;
    this.getHistoryList(this.uid);
  }

  private handleHttpError() {
    this.historyData = [];
    this.isLoading = false;
    this.HttpError = true;
    this.isProcessing = false
  }

  private handleHttpSucess(data: any){
    this.historyData = data
    this.isLoading = false;
    this.HttpError = false;
    this.isProcessing = false;
  }

  private extractUniqueUsers(data: HistoryData[]): void {
    const userSet: Set<string> = new Set();

    data.forEach(item => {
      userSet.add(item.user);
    });

    this.uniqueUsers = Array.from(userSet);
  }

  public getFieldLabel(key: string): string {
    return this.fieldsDict[key] || key;
  }

  @HostListener("window:scroll", ["$event"])
    onWindowScroll(event:any) {
    event.stopPropagation();
  }
}
