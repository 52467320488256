import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import * as CryptoJS from 'crypto-js'; 

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  private storage: Storage;
  private session: Storage;

  private key = "1978c2cc0dcc3n314505f2b68c4281240cfcf7151lv4";

  constructor() {
    this.storage = window.localStorage;
    this.session = window.sessionStorage;
  }

  private encrypt(text: string): string {
    return CryptoJS.AES.encrypt(text, this.key).toString();
  }

  private decrypt(encryptedText: string): string {
    return CryptoJS.AES.decrypt(encryptedText, this.key).toString(CryptoJS.enc.Utf8);
  }

  set(key: string, value: any, storageType: 'local' | 'session' = 'local', isEncrypt = false): boolean {
    const storage = storageType === 'local' ? this.storage : this.session;

    if (storage) {
      if(isEncrypt) {
        storage.setItem(key, this.encrypt(JSON.stringify(value)) );
      } else {
        storage.setItem(key, JSON.stringify(value));
      }
      return true;
    }
    return false;
  }

  get(key: string, storageType: 'local' | 'session' = 'local', isEncrypt = false): any {
    const storage = storageType === 'local' ? this.storage : this.session;

    if (storage) {
      let item = storage.getItem(key);

      if( item && isEncrypt ) {
        item = this.decrypt(item);
      }

      if (item !== null && item.trim() !== '') {
        return JSON.parse(item);
      }
    }
    return null;
  }

  remove(key: string, storageType: 'local' | 'session' = 'local'): boolean {
    const storage = storageType === 'local' ? this.storage : this.session;

    if (storage) {
      storage.removeItem(key);
      return true;
    }
    return false;
  }

  clear(storageType: 'local' | 'session' = 'local'): boolean {
    const storage = storageType === 'local' ? this.storage : this.session;

    if (storage) {
      storage.clear();
      return true;
    }
    return false;
  }


  getUserInfo(): any {
    if (this.session) {
      const chaveSession = JSON.parse(this.session.getItem("0-ficha_web") || '{}');
      if (chaveSession && chaveSession.userData) {
        return chaveSession.userData;
      }
    }
    return null;
  }

  public getHeadersWithAuthorization(): HttpHeaders {
    const authnResultStr = sessionStorage.getItem('0-ficha_web');
    const authnResult = authnResultStr ? JSON.parse(authnResultStr) : null;
    const accessToken = authnResult.authnResult.access_token || '';
    return new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
  }

  public getCurrentCompanyParam(): number {
      const currentCompanyStr = this.get(LocalStorageKeys.USER_CURRENT_COMPANY);
      const currentCompany = currentCompanyStr ? JSON.parse(currentCompanyStr) : null;
      return currentCompany.id
  }

  public getAuthorizationToken(): string {
    const authnResultStr = sessionStorage.getItem('0-ficha_web');
    const authnResult = authnResultStr ? JSON.parse(authnResultStr) : null;
    try {
      const accessToken = authnResult!.authnResult!.access_token || '';
      return accessToken;
    } catch (error) {
      return '';
    }
  }
}
