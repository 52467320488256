<div class="dialog_container">
  <div class="dialog_container__content_server" [formGroup]="erpCommomForm">
    <div class="dialog_container__content_server_select">
      <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
        <mat-select #select [(ngModel)]="selectedService" formControlName="selectedService"
          panelClass="model-form__panel isa-med-elevation" disableOptionCentering placeholder="{{ 'erp.forms.selectService.label' | translate }}"
          (selectionChange)="select.close()">
          <mat-option *ngFor="let item of erpServices; let i = index" [value]="item">{{item | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="selectedService.length > 0 && selectedService != 'erp.types.none'">
    <div class="dialog_container__content_server_srv" [formGroup]="erpCommomForm">
      <div class="dialog_container__content_server_srv_adress">
        <mat-form-field class="styledForm" style="width: 70%;" appearance="outline">
          <mat-label> {{ 'erp.forms.address.label' | translate }} </mat-label>
          <input matInput formControlName="serverAdress">
        </mat-form-field>
        <mat-form-field class="styledForm" style="width: 30%;" appearance="outline">
          <mat-label> {{ 'erp.forms.port.label' | translate }} </mat-label>
          <input matInput formControlName="serverPort">
        </mat-form-field>
      </div>

      <div class="dialog_container__content_userData_usr" [formGroup]="erpCommomForm">
        <mat-form-field class="styledForm" style="width: 70%;" appearance="outline">
          <mat-label> {{ 'erp.forms.user.label' | translate }} </mat-label>
          <input matInput formControlName="user">
        </mat-form-field>
        <mat-form-field class="styledForm" style="width: 30%;" appearance="outline">
          <mat-label> {{ 'erp.forms.password.label' | translate }} </mat-label>
          <input type="password" matInput formControlName="password">
        </mat-form-field>
      </div>
      
      <div class="dialog_container__content_userData_usr">
        <div style="width: 30%;">
          <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
            <mat-label>{{ 'erp.forms.timeout.label' | translate }}</mat-label>
            <input matInput type="number" formControlName="timeout">
          </mat-form-field>
        </div>

        <div style="width: 70%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-end; gap: 5px;">
          <button-outline [height]="38" [isLoading]="isProcessing" [color]="'primary'" [disabled]="isProcessing"
          (click)="verifyErpConnection()">{{ 'erp.actions.validateConnection' | translate }}</button-outline>

          <ng-container *ngIf="connectionMessage.length">
            <span [ngClass]="connectionError ? 'dialog_container__content_msg_error' : 'dialog_container__content_msg_success'">
              <mat-icon>{{connectionError?'error_outline':'task_alt'}}</mat-icon>
              {{connectionMessage | translate}}
            </span>
          </ng-container>
        </div>
      </div>
    </div>

    <mat-divider style="border-top-color: #324fbe29;"></mat-divider>

    <div class="dialog_container__content_userData"  [formGroup]="customToken" *ngIf="selectedService == 'erp.types.default' || selectedService == 'erp.types.millennium'">
      <mat-form-field style="width: 100%; min-height: 40%;" appearance="outline">
        <mat-label>{{ 'erp.forms.token.label' | translate }}</mat-label>
        <textarea matInput formControlName="token"></textarea>
      </mat-form-field>
    </div>
    
    <div [formGroup]="millenniumErp" *ngIf="selectedService == 'erp.types.millennium'">
      <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
        <mat-label>{{ 'erp.forms.costTable.label' | translate }}</mat-label>
        <mat-select formControlName="costTable" panelClass="model-form__panel isa-med-elevation"
          disableOptionCentering placeholder="{{ 'erp.forms.costTable.label' | translate }}">
          <mat-option *ngFor="let item of userCostTable" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="dialog_container__content_userData" *ngIf="selectedService == 'erp.types.audaces'">
      <div [formGroup]="audacesErp">
        <mat-form-field style="width: 100%; min-height: 40%;" appearance="outline">
          <mat-label>{{ 'erp.forms.credentialFormat.label' | translate }}</mat-label>
          <textarea matInput formControlName="credentialFormat"></textarea>
        </mat-form-field>
      </div>
      <div class="dialog_container__content_userData_dict" [formGroup]="audacesErp">
        <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
          <mat-select formControlName="dictionary" panelClass="model-form__panel isa-med-elevation"
            disableOptionCentering placeholder="{{ 'erp.forms.dictionary.label' | translate }}">
            <mat-option *ngFor="let item of userDictionaries" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        
      </div>
    </div>


  </ng-container>

</div>
<div class="dialog_container__footer">
  <button-text class="cancel-button" mat-button color="primary" [mat-dialog-close]="true">{{ 'shared.actions.cancel.label' | translate }}</button-text>
  <button-filled class="save-button" mat-button color="primary" [disabled]="selectedService == 'erp.type.none' || selectedService == ''" [isLoading]="isSaving" (click)="save()"> {{ 'shared.actions.saveConfigs.label' | translate }}</button-filled>
</div>
