<div>
  <mat-stepper class="stepper" orientation="vertical" *ngIf="datasheet">
    <mat-step>
      <ng-template class="stepper__title" matStepLabel>
        {{ 'models.garment' | translate }}
      </ng-template>
      <app-garment-form [standardValues]="standardValuesGarment" [categoryFields]="datasheet.categories.garment.fields"></app-garment-form>

      <ng-container *ngIf="hasCustomFields(customFieldValues)||datasheet.isStandAlone()">
        <mat-slide-toggle [(ngModel)]="showCustomFields" class="stepper__toggle" color="primary">
          {{ 'shared.inputs.enableCustomFields.label' | translate }}
        </mat-slide-toggle>

          <app-isa-model-fields *ngIf="showCustomFields"></app-isa-model-fields>
      </ng-container>

      <app-references-list *ngIf="datasheet.garment" [data]="datasheet.garment" [currentLevel]="0" [variantIndexes]="[]"
        (selectedReferenceEmmiter)="SelectedReferenceEvent($event, 0)"></app-references-list>

        <ng-container  *ngIf="datasheet.isStandAlone()">
          <div style="margin-top: 15px;">

            <p-splitButton
            [label]="'shared.actions.manageVariation.label' | translate"
            (onClick)="showManager()"
            [model]="items"
            styleClass="p-button-outlined mr-2 mb-2"/>
          </div>
        </ng-container>

    </mat-step>

    <mat-step *ngIf="hasVariation()">
      <ng-template matStepLabel>
        {{ 'models.variant' | translate }}:
        <span class="stepper__subTittle">
          {{datasheet.getFieldValue('code', selectedVariant.fields)}}
        </span>
      </ng-template>
      <div>
        <div class="model-form">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'shared.actions.selectVariant.label' | translate }}
            </mat-label>
            <mat-select disableRipple panelClass="model-form__panel isa-med-elevation" disableOptionCentering
              [value]="datasheet.getFieldValue('code', selectedVariant.fields)">
              <mat-option [value]="datasheet.getFieldValue('code', variant.fields)" (click)="setVariant(i)"
                *ngFor="let variant of datasheet.garment.variants; let i = index">{{ datasheet.getFieldValue('code',
                variant.fields) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-variant-form [values]="standardValuesVariant" [level]="1" [editionMode]="editionMode"></app-variant-form>

        <ng-container *ngIf="hasCustomFields(variantCustomFieldValues)||datasheet.isStandAlone()">
          <mat-slide-toggle [(ngModel)]="showVariantCustomFields" class="stepper__toggle" color="primary">
            {{ 'shared.inputs.enableCustomFields.label' | translate }}
          </mat-slide-toggle>
          <button-outline *ngIf="showVariantCustomFields&&datasheet.isStandAlone()"  (click)="createCustomField('variant')" [height]="30" [color]="'primary'">
            {{'shared.actions.addCustomField.label' | translate}}
          </button-outline>
          <br>
          <app-custom-fields-form *ngIf="showVariantCustomFields"
            [values]="variantCustomFieldValues"
            [valuesWithOptions]="variantCustomFieldWithOptions"
            [parent]="'variant'"
            (editFieldEvent)="onEditField($event)"
            (deleteFieldEvent)="onDeleteField($event)"></app-custom-fields-form>
        </ng-container>

        <app-references-list *ngIf="selectedVariant" [data]="selectedVariant" [currentLevel]="1" [variantIndexes]="[selectedVariantIndex]"
          (selectedReferenceEmmiter)="SelectedReferenceEvent($event, 1 )"></app-references-list>

      </div>

    </mat-step>

    <mat-step *ngIf="selectedSubvariant">
      <ng-template matStepLabel>
        {{ 'models.subVariant' | translate }}:
        <span class="stepper__subTittle">{{datasheet.getFieldValue('code',
          selectedSubvariant.fields)}}</span></ng-template>

      <div class="model-form">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'shared.actions.selectSubVariant.label' | translate }}
          </mat-label>
          <mat-select disableRipple panelClass="model-form__panel isa-med-elevation" disableOptionCentering
            class="model-form__select model-form__input"
            [value]="datasheet.getFieldValue('code', selectedSubvariant.fields)">
            <mat-option [value]="datasheet.getFieldValue('code', subVariant.fields)"
              (click)="setSubvariant(selectedVariantIndex,i)"
              *ngFor="let subVariant of selectedVariant['sub-variants']; let i = index">{{ datasheet.getFieldValue('code',
              subVariant.fields) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-variant-form [values]="standardValuesSubvariant" [level]="2" [editionMode]="editionMode"></app-variant-form>

      <app-references-list *ngIf="selectedSubvariant" [data]="selectedSubvariant" [currentLevel]="2" [variantIndexes]="[selectedVariantIndex, selectedSubVariantIndex]"
        (selectedReferenceEmmiter)="SelectedReferenceEvent($event, 2)"></app-references-list>

    </mat-step>

    <mat-step *ngIf="selectedSubSubvariant">
      <ng-template matStepLabel>
        {{ 'models.subVariants' | translate }}:
        <span class="stepper__subTittle">{{datasheet.getFieldValue('code',
        selectedSubSubvariant.fields)}}</span>
      </ng-template>
      <div class="model-form">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'shared.actions.selectSubVariant.label' | translate }}
          </mat-label>
          <mat-select disableRipple panelClass="model-form__panel isa-med-elevation" disableOptionCentering
            class="model-form__select model-form__input"
            [value]="datasheet.getFieldValue('code', selectedSubSubvariant.fields)">
            <mat-option [value]="datasheet.getFieldValue('code', subSubVariant.fields)"
              (click)="setSubSubvariant(selectedVariantIndex,selectedSubVariantIndex,i)"
              *ngFor="let subSubVariant of selectedSubvariant!['sub-variants']; let i = index">{{
              datasheet.getFieldValue('code', subSubVariant.fields) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-variant-form [values]="standardValuesSubSubvariant" [level]="3" [editionMode]="editionMode"></app-variant-form>

      <app-references-list *ngIf="selectedSubSubvariant" [data]="selectedSubSubvariant" [currentLevel]="3" [variantIndexes]="[selectedVariantIndex, selectedSubVariantIndex, selectedSubSubVariantIndex]"
        (selectedReferenceEmmiter)="SelectedReferenceEvent($event, 3)"></app-references-list>

    </mat-step>

  </mat-stepper>
  <ng-container class="drawer_container">
    <mat-sidenav  #drawer mode="side" position="end" opened="false" fixedInViewport="true" (backdropClick)="closeDrawer()" >
      <app-references-editor-drawer-form [inputData]="ReferenceDataInput" (close_drawer)="closeDrawer()" (totalValueChange)="notifyReferenceTotalChanges()">
      </app-references-editor-drawer-form>
    </mat-sidenav>
    <!-- <mat-sidenav fixedInViewport="true" #drawer mode="side" position="end" opened="false" (backdropClick)="closeDrawer()">
      <app-references-editor-drawer-form [inputData]="ReferenceDataInput" (close_drawer)="closeDrawer()">
      </app-references-editor-drawer-form>
    </mat-sidenav> -->
  </ng-container>
  <footer>
    <div class="stepper__button-container">
      <button-filled [color]="'secondary'" [isLoading]="isProcessing" [disabled]="isProcessing || hasChanges == false" (click)="onFinish()">
        {{ 'shared.actions.save.label' | translate }}
      </button-filled>
    </div>
  </footer>
</div>
