import { ENTER} from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { Variation, VariationItem, VariationUtil } from 'src/app/models/Variation';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditVariationNameComponent } from './variation_name/edit-variation-name/edit-variation-name.component';
import { AdvancedVariantManangerComponent } from '../advanced-variant-mananger/advanced-variant-mananger.component';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-variant-mananger',
  templateUrl: './variant-mananger.component.html',
  styleUrls: ['./variant-mananger.component.scss']
})
export class VariantManangerComponent implements OnInit {

  constructor(
    private _datasheetService: DatasheetService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VariantManangerComponent>,
    private _snack: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: Variation,
    private _confirmationService: ConfirmationService,
    private _translate: TranslateService,
    private _analyticsService: AnalyticsService,
  ){}

  ngOnInit(): void {
    if(this.data){
      this.createdVariation = VariationUtil.createSimilarVariarion( this.data );
      this.updateVariantArrays();
      this.extractCategories(this.createdVariation);
      this.setSelectedLevel(this.data.level);
    } else {
      this.lvlSelected = 0;
      this.createdVariation =  {
        level: this.lvlSelected,
        itens: this.createVariantItems(),
      };
      this.setSelectedLevel(this.lvlSelected);
    }
    this._analyticsService.trackStartEvent("variantManager");
  }

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER] as const;

  variants1: any[] = [];
  variants2: any[] = [];
  variants3: any[] = [];

  levelArrays: string[][] = [];

  lvlSelected: number | null = null;
  createdVariation?: Variation;

  lvl1Category!:string;
  lvl2Category!:string;
  lvl3Category!:string;

  visible: boolean = false;

  openTree = false;
  openVariatioCreator = true;

  hasChild = (_: number, node: VariationItem) => !!node.children && node.children.length > 0;

  private updateVariantArrays(): void {

    const tempVariants1: Set<string> = new Set();
    const tempVariants2: Set<string> = new Set();
    const tempVariants3: Set<string> = new Set();

    const iterateItens = (items: VariationItem[], level: number) => {
      for (const item of items) {
        if (level === 1) {
          tempVariants1.add(item.name);
        } else if (level === 2) {
          tempVariants2.add(item.name);
        } else if (level === 3) {
          tempVariants3.add(item.name);
        }
        if (item.children) {
        iterateItens(item.children, level + 1);
        }
      }
    };

    if (this.createdVariation) {
      iterateItens(this.createdVariation.itens, 1);
    }

    this.variants1 = Array.from(tempVariants1);
    this.variants2 = Array.from(tempVariants2);
    this.variants3 = Array.from(tempVariants3);
  }

  extractCategories(variations: Variation): void {
    variations.itens.forEach(item => {
      this.iterateVariantItems(item, 1);
    });
}

iterateVariantItems(item: VariationItem, level: number): void {
  switch (level) {
    case 1:
      this.lvl1Category = item.category || 
      this._translate.instant('variantManager.level1Category');
      break;
    case 2:
      this.lvl2Category = item.category || 
      this._translate.instant('variantManager.level2Category');
      break;
    case 3:
      this.lvl3Category = item.category || 
      this._translate.instant('variantManager.level3Category');
      break;
    default:
      break;
  }

  for (const child of item.children || []) {
    this.iterateVariantItems(child, level + 1);
  }
}

  showDialog() {
    this.visible = true;
  }

  add(event: MatChipInputEvent, variantLvl: number) {
    const value = (event.value || '').trim();

    if (value) {

      switch (variantLvl) {
        case 0:
          if (this.variants1.includes(value)) {
            this._snack.failMessage(this._translate.instant("variantManager.alreadyHasName"));
            break;
          }

          this.variants1.push(value);

          const childLevel1 = VariationUtil.createVariantItem(value, this.getLabel(0));

          if( this.createdVariation!.itens.length > 0 ) {
            const firstItem = this.createdVariation!.itens[0];
            for (const child of firstItem.children||[]) {
              const childLevel2 = VariationUtil.createVariantItem(child.name, this.getLabel(1) );
              for (const granChild of child.children||[]) {
                const childLevel3 = VariationUtil.createVariantItem(granChild.name, this.getLabel(2) );
                childLevel2.children?.push(childLevel3);
              }
              childLevel1.children?.push(childLevel2);
            }
          }

          this.createdVariation!.itens.push(childLevel1);
          break;
        case 1:
          if (this.variants2.includes(value)) {
            this._snack.failMessage(this._translate.instant("variantManager.alreadyHasName"));
            break;
          }

          this.variants2.push(value);

          for (const item of this.createdVariation!.itens) {
            if (!item.children) {
              item.children = [];
            }

            const childLevel2 = VariationUtil.createVariantItem(value, this.getLabel(1) );

            if( item.children.length > 0 ) {
              const firstChild = item.children[0];
              for (const granChild of firstChild.children||[]) {
                const childLevel3 = VariationUtil.createVariantItem(granChild.name, this.getLabel(2) );
                childLevel2.children?.push(childLevel3);
              }
            }

            item.children.push( childLevel2 );
          }

          break;
        case 2:
          if (this.variants3.includes(value)) {
            this._snack.failMessage(this._translate.instant("variantManager.alreadyHasName"));
            break;
          }

          this.variants3.push(value);

          for (const item of this.createdVariation!.itens) {
            for (const child of item.children||[]) {
              if (!child.children) {
                child.children = [];
              }

              const childLevel3 = VariationUtil.createVariantItem(value, this.getLabel(2) );
              if (!child.children.some(grandChild => grandChild.name === childLevel3.name)) {
                child.children.push(childLevel3);
              }
            }

          }

          break;
        default:
          console.error('Invalid variantLvl value:', variantLvl);
      }

    }

    event.chipInput!.clear();
  }

  edit(variantLvl: number, item: any, event: MatChipEditedEvent): void {
    const value = event.value.trim();

    if (!value) {
      this.remove(variantLvl, item);
      return;
    }

    let array;
    switch (variantLvl) {
      case 1:
        array = this.variants1;
        break;
      case 2:
        array = this.variants2;
        break;
      case 3:
        array = this.variants3;
        break;
      default:
        console.error('Invalid variantLvl value:', variantLvl);
        return;
    }

    if (array.includes(value)) {
      this._snack.warnMessage(this._translate.instant("variantManager.alreadyHasName"));
      return;
    }

    const index = array.indexOf(item);
    if (index >= 0) {
      array[index] = value;

      switch (variantLvl) {
        case 1:
          this.createdVariation!.itens.forEach(variationItem => {
            if (variationItem.name === item) {
              variationItem.name = value;
            }
          });
          break;
        case 2:
          this.createdVariation!.itens.forEach(variationItem => {
            variationItem.children?.forEach(child => {
              if (child.name === item) {
                child.name = value;
              }
            });
          });
          break;
        case 3:
          this.createdVariation!.itens.forEach(variationItem => {
            variationItem.children?.forEach(child => {
              child.children?.forEach(grandchild => {
                if (grandchild.name === item) {
                  grandchild.name = value;
                }
              });
            });
          });
          break;
        default:
          console.error('Invalid variantLvl value:', variantLvl);
      }
    }
    this.updateVariantArrays();
  }

  remove(variantLvl: number, item: any): void {
    let array;
    switch (variantLvl) {
      case 1:
        array = this.variants1;
        break;
      case 2:
        array = this.variants2;
        break;
      case 3:
        array = this.variants3;
        break;
      default:
        console.error('Invalid variantLvl value:', variantLvl);
        return;
    }

    const index = array.indexOf(item);
    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  private handleVariationLevels() {
    const level = this.createdVariation!.level;
    if( level == 0 ) {
      this.createdVariation!.itens = []
    }

    for( let item of this.createdVariation!.itens ) {
      if( level == 1) {
        item.children = [];
      }
      for( let child of item.children || [] ) {
        if( level == 2) {
          child.children = [];
        }
      }
    }

  }

  public async onAddVariant() {
    if( !this.isFormValid() ) {
      this._snack.warnMessage( this._translate.instant("variantManager.incompleteItem") );
      return
    }

    if( this.createdVariation!.level > this.lvlSelected! ) {
      const message = this._translate.instant("variantManager.changeLvlMsg");
      const confirmed = await this.confirm(message);
      if( !confirmed ) {
        return;
      }
    }

    this.createdVariation!.level = this.lvlSelected!;
    this.handleVariationLevels();
    if( this._datasheetService.getDatasheet().hasVariants() ) {
      this._datasheetService.updateVariants(this.createdVariation!);
    } else {
      this._datasheetService.createVariants(this.createdVariation!);
    }

    this._analyticsService.trackEndEvent("variantManager");

    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close(this.createdVariation);
  }

  private createVariantItems(): VariationItem[] {
    const items: VariationItem[] = [];

    for (let i = 1; i <= this.lvlSelected!; i++) {
      if (i === 1) {
        items.push(...this.variants1.map((name) => VariationUtil.createVariantItem(name, this.getLabel(i) )));
      } else if (i === 2) {
        for (const item of items) {
          item.children!.push(...this.variants2.map((name) => VariationUtil.createVariantItem(name, this.getLabel(i) )));
        }
      } else if (i === 3) {
        for (const item of items) {
          for (const child of item.children!) {
            child.children!.push(...this.variants3.map((name) => VariationUtil.createVariantItem(name, this.getLabel(1) )));
          }
        }
      }
    }

    return items;
  }


  setSelectedLevel(level: number) {
    this.lvlSelected = level;
  }

  public createVariant() {
    this.onAddVariant();
  }

  getArrays(): any[][] {
    const arrays: any[][] = [];
    if (this.lvlSelected! >= 1) arrays.push(this.variants1);
    if (this.lvlSelected! >= 2) arrays.push(this.variants2);
    if (this.lvlSelected! >= 3) arrays.push(this.variants3);
    return arrays;
  }

  private updateCategoryName() {
    if( this.createdVariation ) {
      for( let item of this.createdVariation.itens || [] ) {
        item.category = this.lvl1Category;
        for( let child of item.children || [] ) {
          child.category = this.lvl2Category;
          for( let granChild of child.children || [] ) {
            granChild.category = this.lvl3Category;
          }
        }
      }
    }
  }

  editCategory(level: number, category: string){
    switch (level) {
      case 1:
        this.lvl1Category = category!;
        break;
      case 2:
        this.lvl2Category = category!;
        break;
      case 3:
        this.lvl3Category = category!;
        break;
      default:
        console.error('Invalid level value:', level);
        return;
    }
    this.updateCategoryName();
  }

  getLabel(level: number): string {
    let category: string;

    switch (level) {
      case 0:
        category = this.lvl1Category || this._translate.instant('variantManager.level1Category');
        break;
      case 1:
        category = this.lvl2Category || this._translate.instant('variantManager.level2Category');
        break;
      case 2:
        category = this.lvl3Category || this._translate.instant('variantManager.level3Category');
        break;
      default:
        category = 'Categoria Padrão';
    }

    return category;
  }


  async deleteNode(node: any, level: number) {
    let message: string;

    if (level <= 1) {
      message = this._translate.instant("variantManager.deleteMsg1");
    } else {
      message = this._translate.instant("variantManager.deleteMsg2");
    }

    let confirmed = true;
    if( this.createdVariation!.level > 0 ) {
      confirmed = await this.confirm(message);
    }
    if (confirmed) {
      switch (level) {
        case 0:
          this.createdVariation!.itens = this.removeNode(this.createdVariation!.itens, node);
          break;
        case 1:
          this.createdVariation!.itens.forEach(item => {
            item.children = this.removeNode(item.children || [], node);
          });
          break;
        case 2:
          this.createdVariation!.itens.forEach(item => {
            item.children?.forEach(child => {
              child.children = this.removeNode(child.children || [], node);
            });
          });
          break;
        default:
          console.error('Invalid level value:', level);
      }
      this.updateVariantArrays();
    }
  }

  private removeNode(data: any[], nodeNameToDelete: string): any[] {
    return data.reduce((acc: any[], node) => {
      if (node.name === nodeNameToDelete) {
        return acc;
      }
      if (node.children) {
        node.children = this.removeNode(node.children, nodeNameToDelete);
      }
      acc.push(node);
      return acc;
    }, []);
  }


  toggleEditMode(node: any): void {
    node.isEditing = !node.isEditing;
  }

  saveNode(node: any): void {
    node.isEditing = false;
    console.log(this.createdVariation!.itens)
    this.updateVariantArrays();
  }

  editNode(node: any): void {
    node.isEditing = true;
  }

  openDialog(index: any): void {
    const dialogRef = this.dialog.open(EditVariationNameComponent, {
      data: {varIndex: index + 1 }, hasBackdrop: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result != undefined ){
        this.editCategory(index +1, result)
      }
    });
  }

  navigate(){
    this.dialog.open(AdvancedVariantManangerComponent, {data: this.createdVariation, height: '80%', width:'55%'})
  }

  canCreate: boolean = false

  isFormValid(): boolean {
    const arrays = this.getArrays();
    switch (this.lvlSelected) {
      case 0:
        return true;
      case 1:
        return arrays[0].length >= 1;
      case 2:
        return arrays[0].length >= 1 && arrays[1].length >= 1;
      case 3:
        return arrays[0].length >= 1 && arrays[1].length >= 1 && arrays[2].length >= 1;
      default:
        return false;
    }
  }

  confirm(message: any): Promise<boolean> {
    const header = this._translate.instant('variantManager.header');
    const acceptLabel = this._translate.instant('variantManager.acceptLabel');
    const rejectLabel = this._translate.instant('variantManager.rejectLabel');
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: message,
        header: header,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        rejectButtonStyleClass: 'p-button-text',
        key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

}

