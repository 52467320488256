<div class="dialog_container">

  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-header__tittle"></h2>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog-content">
    <div class="alert">

      <span class="material-icons-round ">
        warning_amber
        </span>
    </div>
    <span class="confirmation">{{ dialogTitle | translate }}</span>
    <span class="dialog-content__subtitle1">{{ dialogSubtitle | translate }}  </span>
    <span class="dialog-content__subtitle2">{{ dialogConfirmMsg | translate }}</span>
    <span  *ngIf="showOptions">
      <mat-radio-group class="dialog-content__subtitle3" [(ngModel)]="shouldUpdateDs">
        <mat-radio-button [value]="true">
          {{ 'messageDialogComponent.config.option1' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false">
          {{ 'messageDialogComponent.config.option2' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </span>
  </div>
  <div mat-dialog-actions  class="dialog-actions">
    <button-text class="exit" [mat-dialog-close]="true" (click)="actionButtonOne()">{{ labelButtonOne | translate }}</button-text>
    <button-filled class="save" [mat-dialog-close]="true" (click)="actionButtonTwo()">{{ labelButtonTwo | translate }}</button-filled>
  </div>
</div>
