import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';
import { CompaniesStorageData } from 'src/app/models/isa-company-data';
import { LocalStorageService } from 'src/app/services/localStorage.service';

@Injectable({
  providedIn: 'root',
})

  export class RedirectGuard implements CanActivate {
    constructor(
      private _router: Router,
      private _localStorageService: LocalStorageService
    ) {}

    canActivate(): boolean {
      let callbackRoute = this._localStorageService.get('CALLBACK_ROUTE');
      if (callbackRoute && callbackRoute.trim() !== '') {
        let params = callbackRoute.split('/');
        if (params[0] == '') {
          params.shift();
        }
        if (params.length) {
          const lastPart = params.pop();
          let queryParams: any = {};
          let path = '';

          if (lastPart) {
            const queryStringIndex = lastPart.indexOf('?');
            if (queryStringIndex !== -1) {
              path = decodeURIComponent(lastPart.substring(0, queryStringIndex));
              const queryString = lastPart.substring(queryStringIndex + 1);
              const searchParams = new URLSearchParams(queryString);

              searchParams.forEach((value, key) => {
                queryParams[key] = value;
              });

            } else {
              path = decodeURIComponent(lastPart);
            }
          }

          const finalPath = params.length ? `${params.join('/')}/${path}` : path;

          if (queryParams['cpn']) {
            const id = parseInt(queryParams['cpn']);
            if (!isNaN(id)) {
              const companyStorageData: CompaniesStorageData = { id };
              this._localStorageService.set(
                LocalStorageKeys.USER_CURRENT_COMPANY,
                JSON.stringify(companyStorageData)
              );
            }
          }

          this._router.navigate([finalPath], { queryParams });

          this._localStorageService.remove('CALLBACK_ROUTE');
          return false;
        }
      }
      return true;
    }
}
