<div class="dialog_container" [formGroup]="settingsForm">

  <div class="dialog_container_forms" [formGroup]="settingsForm">

    <div class="dialog_container__language" *ngIf="configType != 'model-configs'">
      <span>{{"settingDialog.language" | translate}}</span>
      <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
        <mat-select formControlName="language" placeholder="Idioma padrão">
          <mat-option *ngFor="let item of languages" [value]="item.code">{{ item.label | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="dialog_container__currency">
      <span>{{"settingDialog.currency" | translate}}</span>
      <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
        <mat-select formControlName="currency" placeholder="Moeda padrão">
          <mat-option *ngFor="let item of currencies" [value]="item.code">{{ item.label | translate }} ({{item.code}}) </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <span>{{"settingDialog.measures" | translate}}</span>
      <div class="dialog_container__measures">
        <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
          <mat-label>{{"settingDialog.length" | translate}}</mat-label>
          <mat-select formControlName="measureUnit" placeholder="Medida 1">
            <mat-option *ngFor="let item of measuresUnit" [value]="item.code">{{ item.label | translate }} ({{item.code}})</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
          <mat-label>{{"settingDialog.area" | translate}}</mat-label>
          <mat-select formControlName="areaUnit" placeholder="Medida 2">
            <mat-option *ngFor="let item of areaUnit" [value]="item.code">{{ item.label | translate }} ({{item.code}})</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
          <mat-label>{{"settingDialog.weight" | translate}}</mat-label>
          <mat-select formControlName="weightUnit" placeholder="Medida 3">
            <mat-option *ngFor="let item of weightUnit" [value]="item.code">{{ item.label | translate }} ({{item.code}})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <span *ngIf="configType != 'model-configs'" class="msg">{{'settingDialog.footer' | translate }}</span>
</div>

<div class="dialog_container__footer">
  <button-text class="cancel-button" mat-button color="primary" [mat-dialog-close]="true">{{
    'shared.actions.cancel.label' | translate }}</button-text>
  <button-filled [disabled]="settingsForm.pristine || isLoading" [isLoading]="isLoading" class="save-button" mat-button color="primary" (click)="onSubmit()"> {{
    'shared.actions.saveConfigs.label' | translate }}</button-filled>
</div>
''
