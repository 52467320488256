import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appRemoveS3Link'
})
export class RemoveS3LinkPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let str = value.replace('s3.sa-east-1.amazonaws.com/', '');
    return str.split('?AWSAccessKeyId')[0];
  }

}
