
import { Directive, Input, SimpleChanges, Renderer2, ElementRef, OnChanges } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {
  @Input() searchedWord!: string;
  @Input() content!: string;
  @Input() setTitle = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.content) {
      return;
    }

    if (this.setTitle) {
      this.renderer.setProperty(this.el.nativeElement, 'title', this.content);
    }

    if (!this.searchedWord || !this.searchedWord.length) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.content);
      return;
    }

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.getFormattedText());
  }

  getFormattedText() {
    if (!this.searchedWord) {
      return this.content;
    }

    const regex = new RegExp(`(${this.searchedWord})`, 'gi');
    return this.content.replace(regex, `<span class='highlight'>$1</span>`);
  }
}
