<div *ngIf="isLoading">
  <app-skeleton-loader [skeletonType]="'properties-editor'"></app-skeleton-loader>
</div>

<ng-container *ngIf="!isLoading && hasError">
    <div>
      <app-mensage [messageType]="MessageType.httpError" [isProcessing]="isProcessing" (retryClicked)="retryFunction()"></app-mensage>
    </div>
</ng-container>

  <mat-drawer-container *ngIf="!hasError && !isLoading" autosize>
    <mat-drawer-content>
      <section class="container">
        <div class="container__image">
          <app-editor-image-container></app-editor-image-container>
        </div>
        <div class="container__stepper">
          <app-datasheet-stepper-container></app-datasheet-stepper-container>
        </div>
      </section>
    </mat-drawer-content>
  </mat-drawer-container>

