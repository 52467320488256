import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfigurationDialogComponent } from '../configuration-dialog/configuration-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErpServiceService } from 'src/app/services/erp-service.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { MessageDialogComponent } from '../message-dialog/message-dialog/message-dialog.component';


@Component({
  selector: 'app-erp-login-dialog',
  templateUrl: './erp-login-form.component.html',
  styleUrls: ['./erp-login-form.component.scss'],
})
export class DialogErpLoginComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  public erpCommomForm: FormGroup;
  isProcessing: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<MessageDialogComponent>,
    private _snack: SnackbarService,
    private _erpService: ErpServiceService,
    private _fb: FormBuilder,
    private _translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.erpCommomForm = this._fb.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public doErpLogin() {
    Object.keys(this.erpCommomForm.controls).forEach(key => {
      this.erpCommomForm.controls[key].markAsTouched();
    });

    if( !this.erpCommomForm.valid ) {
      return;
    }

    let username = this.erpCommomForm.get('user')!.value;
    let password = this.erpCommomForm.get('password')!.value;
    
    this.isProcessing = true;
    this._erpService.doLogin(username, password)
      .then(response => {
        this.isProcessing = false;
        this._dialogRef.close(true);
      })
      .catch(error => {
        this.isProcessing = false;
        console.error(error);
        let msg = this._erpService.erpErrorMessage( error.status||200 );
        this._translate.get(msg).subscribe((trans: string) => {
          this._snack.failMessage( trans );
        });
      });
  }
  



}
