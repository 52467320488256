import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';


import KeenSlider, { KeenSliderInstance, KeenSliderOptions,  } from "keen-slider"
import { LocalStorageService } from 'src/app/services/localStorage.service';


@Component({
  selector: 'app-welcome-banner',
  templateUrl: './welcome-banner.component.html',
  styleUrls: ['./welcome-banner.component.scss'],
})
export class WelcomeBannerComponent {
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  @Input() user: string | "Usuário" = "Usuário";
  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  slider!: KeenSliderInstance;

  constructor(
    public cdr: ChangeDetectorRef,
    ){

  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        // loop: true,
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel;
        },
      },
      // [
      //   (slider) => {
      //     let timeout: any;
      //     let mouseOver = false;
      //     function clearNextTimeout() {
      //       clearTimeout(timeout);
      //     }
      //     function nextTimeout() {
      //       clearTimeout(timeout);
      //       if (mouseOver) {
      //         return;
      //       }
      //       timeout = setTimeout(() => {
      //         slider.next();
      //       }, 10000);
      //     }
      //     slider.on('created', () => {
      //       slider.container.addEventListener('mouseover', () => {
      //         mouseOver = true;
      //         clearNextTimeout();
      //       });
      //       slider.container.addEventListener('mouseout', () => {
      //         mouseOver = false;
      //         nextTimeout();
      //       });
      //       nextTimeout();
      //     });
      //     slider.on('dragStarted', clearNextTimeout);
      //     slider.on('animationEnded', nextTimeout);
      //     slider.on('updated', nextTimeout);
      //   },
      // ]
    );

    this.dotHelper = [...Array(this.slider.track.details.slides.length).keys()];

    this.cdr.detectChanges();


  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }
}
