import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-variant-form',
  templateUrl: './variant-form.component.html',
  styleUrls: ['./variant-form.component.scss']
})
export class VariantFormComponent implements OnInit {

  @Input() values!: FormGroup;
  @Input() level!: number;
  @Input() editionMode!: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  onCodeChange(value: string) {
    const forbiddenCharacters = /[:.;@&]/;
    const sanitizedValue = value.replace(forbiddenCharacters, '');

    this.values.get('code')!.setValue(sanitizedValue, { emitEvent: false });
  }

}
