
<div [ngSwitch]="data.type">
  <div class="snackbar-container container" [ngClass]="{'success': data.type === 'success', 'error': data.type === 'error', 'alert': data.type === 'alert'}">
    <mat-icon>{{ data.icon }}</mat-icon>
    <span class="text">{{ data.message }}</span>

    <button *ngIf="data.callback" mat-button (click)="actionClicked()">{{ data.action }}</button>

    <mat-icon (click)="closeSnackbar()">close</mat-icon>
  </div>
</div>
