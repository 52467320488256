import { Injectable } from '@angular/core';
import {
  Subject,
  forkJoin,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { LocalStorageService } from './localStorage.service';
import { DataStorageService } from './data-storage.service';
import { CompaniesStorageData, IsaCompanyData } from '../models/isa-company-data';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { ErpServiceService } from './erp-service.service';
import { SnackbarService } from '../components/snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { FwConfiguration, UserConfiguration } from '../models/integration-user-data';

@Injectable({ providedIn: 'root' })
export class LoginService {
  loginResponseChanged = new Subject<LoginResponse>();

  loginResponse: LoginResponse | undefined;

  defaultConfigs ={
    language: '',
    currency: "BRL",
    measureUnit: 'mm',
    areaUnit: 'mm²',
    weightUnit: 'mg'
  }

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private _localStorageService: LocalStorageService,
    private _data: DataStorageService,
    private _erpService: ErpServiceService,
    private translateService: TranslateService,
  ) {}

  public checkAuthAndLogin() {
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        tap((loginResponse: LoginResponse) => {
          this.loginResponse = loginResponse;

          if (!this.loginResponse.isAuthenticated) {
            this.SaveCurrentUrlPath();
            this.DoLogin();
          }
        }),
        switchMap((loginResponse: LoginResponse) =>
          forkJoin({
            companies: this._data.getCompaniesFromIsa(),
            loginResponse: of(loginResponse)
          })
        ),
        tap(({ companies, loginResponse }) => {
          this.companiesHandler(companies);
          console.log(companies);
          this.loginResponseChanged.next(loginResponse);
        }),
        tap(() => {
          this._erpService.erpServer.getErpConfigFromServer();
          this.configsHandler();
        })
      )
      .subscribe();
  }

  private configsHandler() {
    const userData = this.loadFromCache(LocalStorageKeys.USER_CONFIGS);

    if (!userData) {
      this._data.getErpConfiguration()
        .pipe(take(1))
        .subscribe(
          (serverData: any) => {
            let configs = serverData.userConfigurations || {};

            configs.language = configs.language || localStorage.getItem(LocalStorageKeys.USER_CURRENT_LANGUAGE) || 'pt-BR';
            configs.currency = configs.currency || 'BRL';
            configs.measureUnit = configs.measureUnit || 'mm';
            configs.areaUnit = configs.areaUnit || 'mm²';
            configs.weightUnit = configs.weightUnit || 'mg';

            this.fwConfigHandler(configs);
            this.setLanguage(configs.language)
          },
          (err: Error) => {
            console.warn(err);
          }
        );
    }
  }

  private fwConfigHandler(data: any){
    this._localStorageService.set(LocalStorageKeys.USER_CONFIGS, data, "local", false)
  }

  setLanguage(userLang: string) {
    const languageCode = userLang.split('-')[0];
    this.translateService.setDefaultLang(languageCode);
    this.translateService.use(languageCode);
    localStorage.setItem(LocalStorageKeys.USER_CURRENT_LANGUAGE, userLang);
  }

  private loadFromCache(key: any) : UserConfiguration | null {
    let data = this._localStorageService.get(key, 'local', true);

    if(data) {
      return data;
    }
    return null;
  }

  public SaveCurrentUrlPath() {
    let callbackRoute = window.location.pathname + window.location.search;
    if (callbackRoute == '/redirect')
      return;
    this._localStorageService.set('CALLBACK_ROUTE', callbackRoute);
  }

  public DoLogin() {
    this.oidcSecurityService.authorize();
  }

  public DoLogout() {
    this.oidcSecurityService
      .logoff()
      .pipe(take(1))
      .subscribe((result: any) => console.log(result));
  }

  private companiesHandler(companies: IsaCompanyData){
    const companiesData : CompaniesStorageData[] = [];
    const { name, id } = companies.company;
    const currentCompanyData :CompaniesStorageData = {name, id}

    companies.positions.forEach((position) => {
      const { name, id } = position.company;
      const companyStorageData: CompaniesStorageData = { name, id };
      companiesData.push(companyStorageData);
    });

    this._localStorageService.set(LocalStorageKeys.USER_CURRENT_COMPANY, JSON.stringify(currentCompanyData))
    this._localStorageService.set(LocalStorageKeys.USER_COMPANIES, JSON.stringify(companiesData));

  }

  public getCurrentUserName() : string {
    return this.loginResponse?.userData.name || "";
  }

  public getCurrentUserMail() : string {
    return this.loginResponse?.userData.email || "";
  }

  public getCurrentUserImage() : string {
    return this.loginResponse?.userData.picture || ""
  }
}
