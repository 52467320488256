import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first, Observable, ReplaySubject, Subject } from 'rxjs';
import { ReferencesHandlerDialogComponent } from '../components/references-handler-dialog/references-handler-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public displayDialog<R = any>(
    dialogComponent: any,
    dialogInputData: any,
    dialogWidth?: string,
    dialogHeight?: string,
    styleClass?: string
  ): Observable<R> {
    const result$ = new Subject<R>();
    const dialogConfig = {
      data: dialogInputData,
      width: dialogWidth,
      height: dialogHeight,
      panelClass: styleClass
    };
    const dialogRef = this.dialog.open(dialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: R) => {
      result$.next(result);
      result$.complete();
    });

    return result$.asObservable().pipe(first());
  }

  private closeDrawerSubject = new Subject<void>();
  closeDrawer$ = this.closeDrawerSubject.asObservable();

  closeDrawer() {
    this.closeDrawerSubject.next();
  }


}

