import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { DatasheetService } from 'src/app/services/datasheet.service';

@Component({
  selector: 'app-update-ideax-modal',
  templateUrl: './update-ideax-modal.component.html',
  styleUrls: ['./update-ideax-modal.component.scss']
})
export class UpdateIdeaxModalComponent implements OnInit {
  isProcessing: boolean = false;
  message1!: string;
  message2!: string;

  constructor(
    private _dataStorageService: DataStorageService,
    private _datasheetService: DatasheetService,
    private _dialogRef: MatDialogRef<UpdateIdeaxModalComponent>,
    private translate: TranslateService,
  ) { }


  ngOnInit(): void {
    this.getMesage();
  }

  public actionButtonOne() {
    this._dialogRef.close();
  }

  public actionButtonTwo() {
    this.isProcessing = true;

    let datasheet = this._datasheetService.getDatasheet();
    if(datasheet.fw!.is_ideax_updated == false){
      this._dataStorageService.updateIdeax()
      .subscribe(
        (response) =>(
          this._dialogRef.close(),
          this.isProcessing = false )
      )
    }else{
      this._dataStorageService.retryUnlockFile(() => {
        this._dialogRef.close()
        this.isProcessing = false;
      });
    }
  }

  getMesage(){
    let datasheet = this._datasheetService.getDatasheet();
    if(datasheet.fw?.is_ideax_updated == false){
      this.message1 = this.translate.instant('updateIdeaxModal.message');
      this.message2 = this.translate.instant('updateIdeaxModal.message2');
    }else{
      this.message1 = this.translate.instant('updateIdeaxModal.isaMessage');
      this.message2 = this.translate.instant('updateIdeaxModal.isaMessage2');
    }
  }


}
