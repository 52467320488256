import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class NoErrorPageGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(): boolean {
    if (this._router.url.includes('/')) {
      return false;
    }
    return true;
  }
}
