import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[formatDecimal]'
})
export class DecimalDirective {
  private regex: RegExp = new RegExp(`^\\d*[.,]?\\d{0,2}$`);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.formatInitialValue();
  }

  private formatInitialValue() {
    let currentValue = this.el.nativeElement.value;
    if (currentValue && !this.regex.test(currentValue)) {
      this.el.nativeElement.value = this.formatValue(currentValue);
    }
  }

  private formatValue(value: string): string {
    let formattedValue = value.replace(',', '.');
    let [integerPart, decimalPart] = formattedValue.split('.');

    if (decimalPart && decimalPart.length > 2) {
      decimalPart = decimalPart.substring(0, 2);
    }

    return integerPart + (decimalPart ? '.' + decimalPart : '');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;

    const start = this.el.nativeElement.selectionStart;
    const end = this.el.nativeElement.selectionEnd;

    if (start === 0 && end === current.length) {
      current = '';
    }

    const newValue: string = current.slice(0, start) + event.key + current.slice(end);

    if (newValue && !String(newValue).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedInput: string = clipboardData.getData('text');

    if (!pastedInput.match(this.regex)) {
      event.preventDefault();
    }
  }
}