import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, Subject, switchMap, take } from 'rxjs';
import { SnackbarService } from '../components/snack-bar/snack-bar-service';
import { ReferenceType } from '../models/enums/reference-type';
import { PossibleTargetsDataModel } from '../models/possibleTargetsDataModel';
import { SelectedReference } from '../models/selectedReference';
import { DataStorageService } from './data-storage.service';
import { DatasheetService } from './datasheet.service';
import { HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ReferencesService {

  private hasChanges = new BehaviorSubject<boolean>(false);

  private referenceType$ = new BehaviorSubject<ReferenceType>(ReferenceType.Material);
  private selectedVariant$ = new Subject<string>();
  private selectedReferences$ = new BehaviorSubject<SelectedReference[]>([]);
  private possiblesTargets! : PossibleTargetsDataModel;

  constructor(private _snack: SnackbarService,
              private _datasheetService: DatasheetService,
              private _referenceService: DataStorageService,
              private _translateService: TranslateService
            ) { }

  public getReferenceType(): Observable<string> {
    return this.referenceType$.asObservable();
  }

  public setReferenceType(referenceType: ReferenceType): void {
    this.referenceType$.next(referenceType);
  }

  public getSelectedVariant(): Observable<string> {
    return this.selectedVariant$.asObservable();
  }

  public setSelectedVariant(variantId: string): void {
    this.selectedVariant$.next(variantId);
  }

  public getSelectedReferences(): Observable<SelectedReference[]> {
    return this.selectedReferences$.asObservable();
  }

  public setSelectedReferences(referenceSeed: SelectedReference[]): void {
    this.selectedReferences$.next(referenceSeed);
  }

  public notifyChanges(changes: boolean):void{
    this.hasChanges.next(changes);
  }

  public verifyChanges(): Observable<boolean>{
    return this.hasChanges.asObservable();
  }

  public removeReferences(): Observable<boolean> {
    return this.getSelectedReferences().pipe(
      take(1),
      map((selectedRef) => ({
        datasheetId: selectedRef[0].datasheetId,
        referenceType: this.referenceType$.value,
        targetIds: selectedRef.map((ref) => ref.referenceId),
      })),
      switchMap(data => this._referenceService.removeReference(data).pipe(
        map((response: HttpResponse<any>) => {
          if (response instanceof HttpResponse) {
            switch (response.status) {
              case 200:
                this._datasheetService.setDataSheet(response.body);
                this._snack.okMessage(this._translateService.instant('alerts.referenceActions.removing.success.text'));
                break;
              case 206:
                const partialResponse = response.body as any;
                this._snack.warnMessage(partialResponse.message);
                this._datasheetService.setDataSheet(partialResponse.datasheet);
                break;
              default:
                this._snack.failMessage('Erro inesperado.');
                console.error('Unexpected status code:', response.status);
                return false;
            }
            this.notifyChanges(true);
            this.setSelectedReferences([]);
            return true;
          } else {
            this._snack.failMessage('Erro inesperado.');
            console.error('Unexpected response:', response);
            return false;
          }
        }),
        catchError((error) => {
          if (error.status === 400) {
            this._snack.failMessage(this._translateService.instant('alerts.referenceActions.removing.failed.text'));
          } else {
            this._snack.failMessage('Erro ao remover.');
          }
          console.error(error);
          return of(false);
        })
      ))
    );
  }


  public moveReferences(selectedTargetsId: string[]): Observable<boolean> {
    return this.getSelectedReferences()
      .pipe(
        take(1),
        map((selectedRef) => {

          const referencedId = selectedRef[0].referenceId;
          return {
            datasheetId: selectedRef[0].datasheetId,
            referenceType: this.referenceType$.value,
            targetIds: selectedTargetsId,
            referencedId: referencedId,
          };
        }),
        switchMap(data => {
          return this._referenceService.moveReference(data.referencedId, data)
        }),
        map((resp) => {
          console.debug(resp);
          if (!resp) {
            this._snack.failMessage(this._translateService.instant('alerts.referenceActions.moving.failed.text'), '');
            return false;
          }
          this._datasheetService.setDataSheet(resp);
          this.notifyChanges(true);
          this._snack.okMessage(this._translateService.instant('alerts.referenceActions.moving.success.text'));
          return true;
        })
      );
  }

  public copyReferences(selectedTargetsId: string[]): Observable<boolean> {
    return this.getSelectedReferences().pipe(
      take(1),
      map((selectedRef) => {
        const referencedId = selectedRef[0].referenceId;
        return {
          datasheetId: selectedRef[0].datasheetId,
          referenceType: this.referenceType$.value,
          targetIds: selectedTargetsId,
          referencedId: referencedId,
        };
      }),
      switchMap(data => this._referenceService.copyReference(data.referencedId, data).pipe(
        map((response: HttpResponse<any>) => {
          if (response instanceof HttpResponse) {
            switch (response.status) {
              case 200:
                this._datasheetService.setDataSheet(response.body);
                this._snack.okMessage(this._translateService.instant('alerts.referenceActions.copying.success.text'));
                break;
              case 206:
                const partialResponse = response.body as any;
                this._snack.warnMessage(partialResponse.message);
                this._datasheetService.setDataSheet(partialResponse.datasheet);
                break;
              default:
                this._snack.failMessage('Erro inesperado.');
                console.error('Unexpected status code:', response.status);
                return false;
            }
            this.notifyChanges(true);
            this.setSelectedReferences([]);
            return true;
          } else {
            this._snack.failMessage('Erro inesperado.');
            console.error('Unexpected response:', response);
            return false;
          }
        }),
        catchError((error) => {
          if (error.status === 400) {
            this._snack.failMessage(this._translateService.instant('alerts.referenceActions.copying.failed.text'));
          } else {
            this._snack.failMessage('Erro ao copiar.');
          }
          console.error(error);
          return of(false);
        })
      ))
    );
  }


  public addReference(
    datasheetId: string,
    selectedTargetsId: string[],
    referenceJson: string,
    referenceType: string,
    variationName: string,
  ): Observable<boolean> {
    return this._referenceService.addReference({
        datasheetId,
        variantUid: selectedTargetsId,
        referenceJson,
        referenceType,
        variationName
      }).pipe(
      take(1),
      map((response: HttpResponse<any>) => {
        if (response instanceof HttpResponse) {
          switch (response.status) {
            case 200:
              this._datasheetService.setDataSheet(response.body);
              this._snack.okMessage(this._translateService.instant('alerts.referenceActions.adding.success.text'));
              break;
            case 206:
              const partialResponse = response.body as any;
              this._snack.warnMessage(partialResponse.message);
              this._datasheetService.setDataSheet(partialResponse.datasheet);
              break;
            default:
              this._snack.failMessage('Erro inesperado.');
              console.error('Unexpected status code:', response.status);
              return false;
          }
          this.notifyChanges(true);
          return true;
        } else {
          this._snack.failMessage('Erro inesperado.');
          console.error('Unexpected response:', response);
          return false;
        }
      }),
      catchError((error) => {
        if (error.status === 400) {
          this._snack.failMessage(this._translateService.instant('alerts.referenceActions.adding.failed.text'));
        } else {
          this._snack.failMessage('Erro ao adicionar referência.');
        }
        console.error(error);
        return of(false);
      })
    );
  }


  public listPossibleTargetsToCopyAReference(): Observable<PossibleTargetsDataModel> {
    return this.getSelectedReferences()
      .pipe(
        take(1),
        switchMap(selectedRef =>
          this._referenceService.getPossibleCopyTargets(
            selectedRef[0].datasheetId,
            selectedRef[0].referenceId,
            this.referenceType$.value
          )
        ),
        map(possibleTargets => {
          this.possiblesTargets = possibleTargets;
          return possibleTargets;
        })
      );
  }

  public listPossibleTargetsToMoveAReference(): Observable<PossibleTargetsDataModel>{
    return this.getSelectedReferences()
    .pipe(
      take(1),
      switchMap(selectedRef =>
        this._referenceService.getPossibleMoveTargets(
          selectedRef[0].datasheetId,
          selectedRef[0].referenceId,
          this.referenceType$.value
        )
      ),
      map(possibleTargets => {
        this.possiblesTargets = possibleTargets;
        return possibleTargets;
      })
    );
  }

}
