<ng-container *ngIf="!isLoading && hasError">
  <div>
    <app-mensage [messageType]="MessageType.httpError" [isProcessing]="isProcessing" (retryClicked)="retryFunction()"></app-mensage>
  </div>
</ng-container>
<ng-container *ngIf="!isLoading && !hasError">
  <mat-sidenav-container  [hasBackdrop]="false">
    <section class="navigation" >
      <span class="navigation__button" [style.height.px]="39" (click)="backToModel(uid)">
        <mat-icon>arrow_back</mat-icon>
        {{ 'shared.actions.backToGarment.label' | translate }}
      </span>
      <div class="navigation__text" *ngIf="!drawerOpenned" (click)="toggleDrawer()">
        {{ 'printPage.title' | translate }}
      </div>
    </section>
    <div class="print-preview">
      <app-print-preview></app-print-preview>
    </div>
    <mat-sidenav fixedInViewport="true" #sidenav mode="side" position="end"  [opened]="drawerOpenned">
      <app-print-config-drawer [erpDiffs]="erpDiffs" (_closeDrawer)="toggleDrawer()"></app-print-config-drawer>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
