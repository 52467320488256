<form class="colorpicker_cont" [formGroup]="colorFormGroup" >
  <mat-form-field class="colorpicker_cont__form" appearance="outline" >
    <mat-label>{{field.label | translate}}</mat-label>
    <input matInput [formControl]="getFormControl('name')" [style.color]="field.read_only ? '#B8B8B8' : 'black'"/>
    <ngx-colors
      matSuffix
      ngx-colors-trigger
      [formControl]="getFormControl('value')"
      [formats]="['rgba','cmyk', 'hex']"
      [acceptLabel]="'shared.actions.save.label' | translate"
      [cancelLabel]="'shared.actions.cancel.label' | translate"
    ></ngx-colors>
  </mat-form-field>
</form>
