<div class="dialog_container">
  <div class="dialog_container__menu">
    <div class="side-menu">
      <ng-container *ngFor="let item of dialogs">
        <button *ngIf="item.type === 'model-configs' ? isModelConfig === 'model-configs' : true"
                class="side-menu__button"
                (click)="changeDialog(item.type)"
                [class.active]="item.active">
          <div class="border"></div>
          {{ item.name }}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="dialog_container__content">
    <div class="dialog_container__content__header">
      <h2 mat-dialog-title class="dialog_container__content__header_tittle"> {{dialogSelected.name | translate}} </h2>
      <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div mat-dialog-content class="dialog-content">
      <app-erp-configuration *ngIf="dialogSelected.type == 'erp'" ></app-erp-configuration>
      <app-fw-configuration *ngIf="dialogSelected.type == 'configs'"></app-fw-configuration>
      <app-fw-configuration *ngIf="dialogSelected.type == 'model-configs'" [configType]="isModelConfig"></app-fw-configuration>
    </div>

  </div>
