<div class="dialog_container">

    <div class="dialog_container__header">
        <h2 mat-dialog-title class="dialog_container__header_tittle"> {{'shared.actions.ideax.title' | translate}} </h2>
        <button mat-icon-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
      </div>

  <div mat-dialog-content>
    <h3>{{'shared.actions.ideax.subtitle' | translate}}</h3>
    
    <div>
        <div class="file-upload" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
            <input type="file" (change)="onFileSelected($event)" accept=".ideax" />
            <div class="file-upload-content">
                <img *ngIf="!imageUrl" class="file-upload__img" src="../../../assets/images/Upload-FW.svg">
                <img *ngIf="imageUrl" class="file-upload__img_preview" src="{{imageUrl}}">
                <br>
                <span *ngIf="!imageUrl">{{'shared.actions.ideax.drag' | translate}} <b>{{'shared.actions.ideax.select' | translate}} </b> </span>
                <span *ngIf="imageUrl">{{imageName}} </span>

            </div>
        </div>
    </div>
        
    <p class="file-upload-tip"> 
        <mat-icon class="file-upload-tip-icon material-icons-outlined">info</mat-icon>
        <span>
            {{'shared.actions.ideax.tip' | translate}} 
        </span>
    </p>
    
  </div>

  <div mat-dialog-actions  class="dialog-actions">
    <button-text class="exit" [mat-dialog-close]="false" >{{ 'shared.actions.cancel.label' | translate }}</button-text>
    <button-filled class="save" [isLoading]="isProcessing" (click)="onUpload()" [disabled]="isProcessing || !selectedFile" >{{ 'shared.actions.ideax.create' | translate }}</button-filled>
    <!-- <button-filled class="save" (click)="doErpLogin()">{{ 'shared.actions.login.label' | translate }}</button-filled> -->
  </div>
</div>
