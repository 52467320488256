import { Injectable } from '@angular/core';
import { PrintData } from '../models/print-data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Datasheet } from '../models/datasheet';
import { IsaMetadata } from '../models/isaMetadata';
import { DatasheetService } from './datasheet.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  rawData?: PrintData;
  printData: BehaviorSubject<PrintData> = new BehaviorSubject<PrintData>( {} as PrintData );

  constructor( private _datasheetService: DatasheetService) {
  }

  printDataParser(dataSheet:Datasheet){
    this.updateData( new PrintData(dataSheet) );
  }

  getPrintData(): BehaviorSubject<PrintData> {
    return this.printData;
  }

  updateData(data: PrintData) {
    this.rawData = data;
    this.printData.next(data);
  }

  updateIsaMetaData(isaMeta: IsaMetadata) {
    if (!this.rawData || !isaMeta.isaModelFields) {
      return;
    }

    for (const isaField of isaMeta.isaModelFields) {
      let value: string;

      if (isaField.type === "Multiple") {
        value = isaField.choices.join(", ");
      } else if (isaField.type === "Currency") {
        const currencySymbol = this._datasheetService.getDatasheet().getCurrencySymbol();
        value = `${isaField.value}  ${currencySymbol} `;
      }else if(isaField.type === "Date"){
        const [year, month, day] = isaField.value.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day));

        value = new Intl.DateTimeFormat(navigator.language).format(date);
      } else {
        value = isaField.value;
      }

      this.rawData.model.header.push(isaField.key);
      this.rawData.model.headerNames.push(isaField.key);
      this.rawData.model.rows[0].row.push(value);
    }

    this.printData.next(this.rawData);
  }
}
