import { Pipe, PipeTransform } from '@angular/core';
import { Datasheet } from 'src/app/models/datasheet';

function normalizeString(str: string): string {
  return str.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
}

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string, fieldName: string = ''): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = normalizeString(searchText.toLocaleLowerCase());

    return items.filter((it) => {
      const datasheet = new Datasheet( it );
      if(typeof it === 'object'){
        if(searchText != "code"){
          // JSON.stringify for convert reference object into string too.
          return normalizeString(JSON.stringify(it).toLocaleLowerCase()).includes(searchText);
        }
        if (fieldName) {
          const fieldValue = datasheet.getFieldValue(fieldName, it['garment']['fields']);
          return fieldValue && normalizeString(String(fieldValue).toLocaleLowerCase()).includes(searchText);
        } else {
          return normalizeString(JSON.stringify(it).toLocaleLowerCase()).includes(searchText);
        }
      }else{
        return normalizeString(it.toLocaleLowerCase()).includes(searchText);
      }

    });
  }
}
