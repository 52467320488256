import { Component, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ErpUpdateRefComponent } from '../erp-update-ref/erp-update-ref.component';
import { ErpDiff } from 'src/app/models/datasheet';
import { ErpReferencesService } from 'src/app/services/erp-references.service';
import { LoginService } from 'src/app/services/login.service';
import { Subject, catchError, finalize, of, take, takeUntil, tap } from 'rxjs';
import { LockParam } from 'src/app/models/lockParam';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { DatasheetStepperComponent } from '../datasheet-stepper/datasheet-stepper.component';
import { UpdateIdeaxModalComponent } from '../update-ideax-modal/update-ideax-modal.component';
import { IsaService } from 'src/app/services/isa.service';
import { ConfirmationService } from 'primeng/api';
import { StatusType } from 'src/app/models/enums/status-type';
import { ImportIdeaxDialogComponent } from '../import-ideax-dialog/import-ideax-dialog.component';

@Component({
  selector: 'app-datasheet-stepper-container',
  templateUrl: './datasheet-stepper-container.component.html',
  styleUrls: ['./datasheet-stepper-container.component.scss']
})
export class DatasheetStepperContainerComponent implements OnInit, OnDestroy {
  @ViewChild('historyDrawer') historyDrawer!: MatSidenav;
  @ViewChild('stepper') stepper!: DatasheetStepperComponent;

  public isDrawerOpened: boolean = false;
  uid: string = '';
  public thumb?: string;
  public kanbam?:string;
  public erpDiffs: ErpDiff[] = [];
  public fetchingErpDiffs: boolean = false;
  public fetchingEditMode: boolean = false;
  private destroy$ = new Subject<boolean>();
  public lockedParam?: LockParam | null;
  public shouldShowButton: boolean = false;
  public isLoadingIsa: boolean = false;
  public hasIsaModelId: boolean = false;
  public showImportIdea: boolean = false;

  constructor(
    private _datasheetService: DatasheetService,
    private route: ActivatedRoute,
    private _dialog: DialogService,
    private _erpReferencesService: ErpReferencesService,
    public _loginService: LoginService,
    private _dataStorage: DataStorageService,
    private _snack: SnackbarService,
    private _translateService: TranslateService,
    private _isaService: IsaService,
    private _confirmationService: ConfirmationService,
    private _translate: TranslateService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.uid = this._datasheetService.getDatasheetUid();
    this.route.params.subscribe(
      (params: Params) => {
        if( params['uid'] !== undefined ) {
          this.uid = params['uid'];
        }
      }
    );
    this.route.queryParams.subscribe(
      (params: Params) => {
        this.showImportIdea = params['model_id'] !== undefined;
      }
    );
    this.getKanbamAndShape();
    this.fetchErpRefsUpdate();

    this._erpReferencesService.getErpDiffs()
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => this.erpDiffs = x);

    this._erpReferencesService.retryChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(retry => {
        if(retry)
          this.fetchErpRefsUpdate();
      });

    if( this._datasheetService.getDatasheet().isStandAlone() ) {
      this.lockedParam = null;
    } else {
      this.lockedParam = this._datasheetService.isLocked( this._loginService.getCurrentUserMail() );
      this._datasheetService.getDatasheet().setReadonly();
    }

    this._datasheetService.dataSheetChanged
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      const datasheet = this._datasheetService.getDatasheet();
      this.shouldShowButton = datasheet.fw?.is_isa_unlocked === true || datasheet.fw?.is_ideax_updated === false;
      if( datasheet.fw && datasheet.fw.isa_model_id ) {
        this.hasIsaModelId = true;
      } else {
        this.hasIsaModelId = false;
      }
    });

    this._isaService.getIsacollection()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      setTimeout(() => {
        this.isLoadingIsa = false;
      }, 1500);
    });

    this._datasheetService.isaMetaChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(isaMeta => {
        this.getKanbamAndShape();
      });

  }

  ngOnDestroy(): void {
    this.unlockOnUnload();
  }

  public historyToggler(){
    setTimeout(() => {
      this.historyDrawer.opened = true;
      this.isDrawerOpened= true;
      document.body.classList.add('no-scroll');
      this.emitResizeEvent();
    }, 50);
  }
  public closeDrawer(){
    this.historyDrawer.opened = false;
    this.isDrawerOpened= false;
    document.body.classList.remove('no-scroll');
  }

  public getKanbamAndShape(){
    const fwValues = this._datasheetService.getKanbamAndShape();
    if (fwValues) {
      this.thumb = fwValues.isa_shape;
      this.kanbam = fwValues.isa_kanbam;
    }else{
      this.thumb = '';
      this.kanbam = '';
    }
  }

  openModal(){
    this._dialog.displayDialog(ErpUpdateRefComponent, {data: this.erpDiffs }, '900px', '', );
  }

  fetchErpRefsUpdate(){
    this.fetchingErpDiffs = true;
    this._erpReferencesService.verifyErpReferences()
    .pipe(takeUntil(this.destroy$), finalize(() => this.fetchingErpDiffs = false))
    .subscribe(x => {
      this.erpDiffs = x
      this.fetchingErpDiffs = false;
    });
  }

  onEnableEtition( showMessage: boolean = false ) {
    this.fetchingEditMode = true;
    this._dataStorage.setEditionMode(this.uid)
    .pipe(
      take(1),
      tap((datasheet) => {
        this.stepper.editionMode = true;
        this.fetchingEditMode = false;
        this.lockedParam = null;
        this._datasheetService.setDataSheet(datasheet);
      }),
      catchError((error) => {
        this.stepper.editionMode = false;
        this.fetchingEditMode = false;
        if (error.status === 423) {
          // 423 Locked
          console.error('Recurso está bloqueado:', error.error);
          this.lockedParam = error.error;

          if( showMessage ) {
            const lockByMsg1 = this._translateService.instant('lock.lockByMsg1');
            const lockByMsg2 = this._translateService.instant('lock.lockByMsg2');
            const lockByMsg3 = this._translateService.instant(`lock.${this.lockedParam!.lockedSystem}`);
            this._snack.failMessage( `${lockByMsg1} ${this.lockedParam!.lockedBy} ${lockByMsg2} ${lockByMsg3}`, '',);
          }

        }
        else if (error.status === 418) {
          this._snack.failMessage( this._translateService.instant("editionMode.isa") +  error.error, '',);
        }
        else {
          let msg = error.error.message || error.error.error || JSON.stringify(error.error||error);

          this._snack.failMessage( this._translateService.instant("editionMode.error") +  msg, '',);
          console.error('Ocorreu um erro:', error);
        }
        return of(null);
      })
    )
    .subscribe();
  }

  private unlockOnUnload() {
      console.info("Leaving Editing mode. Try to unlock datasheet");
      this._dataStorage.lockDatasheet(this.uid, false)
      .pipe(
        take(1),
        tap((data) => {
          console.info('unlock');
        }),
        catchError((error) => {
          console.info('unlock fails', error);
          return of(null);
        })
      )
      .subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: any) {
    if( this.stepper.editionMode && this._datasheetService.getStatus() !== StatusType.SAVED  ) {
      $event.returnValue = true;
    }
    this.unlockOnUnload();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    this.unlockOnUnload();
  }

  emitResizeEvent() {
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  }

  showIdeaxDialog(){
    this._dialog.displayDialog(UpdateIdeaxModalComponent, { title: "erp.forms.title" }, '', 'dialog-responsive');
  }

  getIdeaxStatus(){
    return this._datasheetService.isIdeaxUpdated();
  }

  onLinkIsaClick() {
    this.isLoadingIsa = true;
    this.stepper.isaIntegration();
  }
  
  canLeave() {
    const isSaved = this._datasheetService.getStatus() == StatusType.SAVED;
    const dsUid = this._datasheetService.getDatasheet()?.fw?.datasheet_uid;

    if (!dsUid || !isSaved) {
      this.confirm(this._translate.instant("propertyEditorPage.leaveRoute.message")).then(confirmed => {
        return confirmed;
      });
      return false;
    }

    return true;
  }

  confirm(message: any): Promise<boolean> {
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: message,
        header: this._translate.instant('propertyEditorPage.leaveRoute.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'OK',
        rejectLabel: 'none',
        rejectButtonStyleClass: 'hidden',
        key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }
  navigateToPrint() {
    if (this.canLeave()) {
      this._router.navigate(['/print', this.uid]);
    }
  }

  navigateToAdvancedEditor() {
    if (this.canLeave()) {
      this._router.navigate(['/advanced-editor', this.uid]);
    }
  }

  importIdeax() {
    const dialogRef = this._dialog.displayDialog(ImportIdeaxDialogComponent, null, '560px');
      dialogRef.subscribe(result => {
        console.log(result);
        this.hasIsaModelId = result;
      })
  }

}
