import { Component, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { ImageCookiesService } from 'src/app/services/image-cookies.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  template: '',
})
export class LoginComponent implements OnInit
{
  @Output()
  public readonly onAuthorized = new EventEmitter<boolean>();

  private imageCookiesService = inject(ImageCookiesService);

  public constructor( private _loginService: LoginService ) { }

  ngOnInit() {
    this._loginService.loginResponseChanged
      .pipe(take(1))
      .subscribe(loginResponse => {
        this.onAuthorized.emit(loginResponse.isAuthenticated);
      });

    this._loginService.checkAuthAndLogin();
  }

  logout() {
    this._loginService.DoLogout();
  }

}
