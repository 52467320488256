export interface Property {
  min_value?: number,
  max_value?: number,
  read_only?: boolean,
  measure?: string,
  remove_zeros?: boolean,
  empty_valid?: boolean,
  multi_line?: boolean,
  always_editable?: boolean,
  external_custom?: boolean,
  fractional_digits?: number,
  automatic_datetime?: boolean,
}

export enum DefaultMeasures {
  Area = 'area',
  Length = 'length',
  Weight = 'weight'
}
