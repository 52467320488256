import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ErpDiff } from 'src/app/models/datasheet';
import { MessageType } from 'src/app/models/enums/message-type';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { dataResolutionServiceService } from 'src/app/services/dataResolutionService.service';
import { ErpReferencesService } from 'src/app/services/erp-references.service';

@Component({
  selector: 'app-properties-editor',
  templateUrl: './properties-editor.component.html',
  styleUrls: ['./properties-editor.component.scss']
})
export class PropertiesEditorComponent implements OnInit {
  private uid!: any;
  
  private modelId!: string;
  private kanbam!: string;
  private importFrom!: string;

  MessageType = MessageType;
  public isLoading: boolean = true;
  public hasError : boolean = false;
  isProcessing: boolean = false;
  public erpDiffs: ErpDiff[] = [];

  private destroy$ = new Subject<boolean>();

  constructor(
    private _resolution : dataResolutionServiceService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _erpReferencesService: ErpReferencesService
  ) { }

  ngOnInit(): void {
    this._route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe( (params?: Params) => {
        this.uid = '';
        if (params && params['uid'] !== undefined) {
          this.uid = params['uid'];
        }
      });

    this._route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe( (params?: Params) => {
        if( ! params ) {
          return;
        }
        this.modelId = params['model_id'];
        this.kanbam = params['kanbam'];
        this.importFrom = params['import'];
      });
    this.resolver();
  }

  public retryFunction(){
    this.isProcessing = true;
    this.resolver();
  }

  private handleHttpError() {
    this.isLoading = false;
    this.hasError = true;
    this.isProcessing = false
  }

  private handleHttpSucess(){
    this.isLoading = false;
    this.hasError = false;
    this.isProcessing = false;
  }

  private resolver() {
    this._resolution.resolveData(this.uid!, this.modelId, this.kanbam, this.importFrom).subscribe({
      next: (resolvedData) => {
        if( resolvedData == null ) {
          this._router.navigate(['']);
          return;
        }
        this.handleHttpSucess();
        this._erpReferencesService.verifyErpReferences();
      },
      error: (error) => {
        console.debug('Erro ao resolver dados:', error);
        this.handleHttpError();
      }
    });
  }

}
