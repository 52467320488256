import { SerchBarComponent } from './../../components/serch-bar/serch-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import  {MaterialModule } from '../material/material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FilterPipe } from 'src/app/pipes/filterPipe/filter.pipe';
import { HighlightDirective } from 'src/app/directives/highlight.directive';
import { ToolbarComponentComponent } from 'src/app/components/toolbar-component/toolbar-component.component';
import { ButtonOutlineComponent } from 'src/app/components/shared/button-outline/button-outline.component';
import { ButtonTextComponent } from 'src/app/components/shared/button-text/button-text.component';
import { ButtonFilledComponent } from 'src/app/components/shared/button-filled/button-filled.component';
import { ButtonMiscComponent } from 'src/app/components/shared/button-misc/button-misc.component';
import { LoadingSpinnerComponent } from 'src/app/components/shared/loading-spinner/loading-spinner.component';
import { AppSnackBar } from 'src/app/components/snack-bar/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HistoryDrawerComponent } from 'src/app/components/history-drawer/history-drawer.component';
import { FilterHistoryPipe } from 'src/app/pipes/filter-history.pipe';
import { MensageComponent } from 'src/app/components/mensage/mensage.component';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog/message-dialog.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from 'src/app/components/skeleton-loader/skeleton-loader.component';
import { HighlightPipe } from 'src/app/pipes/highlight.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxColorsModule } from 'ngx-colors';
import { ColorPickerComponent } from 'src/app/components/shared/color-picker/color-picker.component';
import { AddReferenceDialogComponent } from 'src/app/components/add-reference-dialog/add-reference-dialog.component';
import { RemoveS3LinkPipe } from 'src/app/pipes/remove-s3-link.pipe';
import { DialogErpLoginComponent } from 'src/app/components/erp-login-form/erp-login-form.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { AddFwReferenceComponent } from 'src/app/components/add-fw-reference/add-fw-reference.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatChipsModule} from '@angular/material/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [
    HighlightDirective,
    FilterPipe,
    SerchBarComponent,
    ToolbarComponentComponent,
    ButtonFilledComponent,
    ButtonTextComponent,
    ButtonOutlineComponent,
    ButtonMiscComponent,
    LoadingSpinnerComponent,
    AppSnackBar ,
    HistoryDrawerComponent,
    FilterHistoryPipe,
    MensageComponent,
    MessageDialogComponent,
    HighlightPipe,
    SkeletonLoaderComponent,
    ColorPickerComponent,
    AddReferenceDialogComponent,
    AddFwReferenceComponent,
    RemoveS3LinkPipe,
    DialogErpLoginComponent,

  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatToolbarModule,
    NgxSkeletonLoaderModule,
    NgxColorsModule,
    SplitButtonModule,
    DropdownModule,
    NgxPaginationModule,
    MatChipsModule,
    AutoCompleteModule,
    NgSelectModule,
    CalendarModule,
    InputNumberModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    HighlightDirective,
    FilterPipe,
    ReactiveFormsModule,
    SerchBarComponent,
    ToolbarComponentComponent,
    ButtonFilledComponent,
    ButtonTextComponent,
    ButtonOutlineComponent,
    ButtonMiscComponent,
    LoadingSpinnerComponent,
    AppSnackBar,
    HistoryDrawerComponent,
    MensageComponent,
    MessageDialogComponent,
    NgxSkeletonLoaderModule,
    SkeletonLoaderComponent,
    HighlightPipe,
    TranslateModule,
    NgxColorsModule,
    ColorPickerComponent,
    RemoveS3LinkPipe,
    DialogErpLoginComponent,
    ConfirmDialogModule,
    SplitButtonModule,
    DropdownModule,
    MatChipsModule,
    AutoCompleteModule,
    NgSelectModule,
    CalendarModule,
    InputNumberModule
  ]
})
export class SharedModule { }
