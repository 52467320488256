 <div *ngIf="isLoading">
  <app-skeleton-loader [skeletonType]="'advanced-editor'"></app-skeleton-loader>
</div>
<div *ngIf="!isLoading && hasError">
  <div class="error">
    <app-mensage [messageType]="MessageType.httpError" [isProcessing]="isProcessing" (retryClicked)="retryFunction()"></app-mensage>
  </div>
</div>
<div *ngIf="!isLoading && !hasError" class="advanced_cont" >
  <section class="navigation">
    
    <div class="header_locked" *ngIf="lockedParam">
      <div class="locked-container">
        <div class="locked-text-content">
          <mat-icon style="line-height: 28px;">warning</mat-icon> {{ 'lock.by' | translate }} <b>{{ lockedParam.lockedBy }}</b> {{ 'lock.at' | translate }} <b>{{lockedParam.lockedAt | date: 'dd/MM/yyyy'}}</b>
          <p *ngIf="lockedParam.lockedSystem == 'ISA'">{{ 'lock.isaMessage' | translate }}</p>
        </div>
        <button-misc class="locked-button" [isLoading]="fetchingEditMode" (click)="onEnableEtition(true)" style="float: right;">
          <mat-icon *ngIf="!fetchingEditMode">refresh</mat-icon>
        </button-misc>
      </div>
    </div>

    <div class="header_locked" *ngIf="!lockedParam && !editionMode" style="background-color: antiquewhite;">
      <button-misc [isLoading]="fetchingEditMode" (click)="onEnableEtition()" style="float: right;">
        <mat-icon *ngIf="!fetchingEditMode">edit</mat-icon>
        {{ 'lock.enableEdtion' | translate }}
      </button-misc>
    </div>
    
  </section>
    
  <section class="navigation">
    <button-text class="navigation__button" [height]="39"
      [routerLink]="['/properties-editor', uid ]"><mat-icon>arrow_back</mat-icon>
      {{ 'shared.actions.backToGarment.label' | translate }}
    </button-text>
    <div class="navigation__text">
      <p class="navigation__text--gray">
        {{ 'shared.navigation.paths.garmentEditor.label' | translate }}
      </p>
      <mat-icon class="navigation__text--gray navigation__text--icon">chevron_right</mat-icon>
      <p class="navigation__text--blue">
        {{ 'shared.navigation.paths.advancedEditor.label' | translate }}
      </p>
    </div>
    <div style="margin-top: 10px;" >
      <button-misc [isLoading]="fetchingErpDiffs" [disabled]="fetchingErpDiffs || !editionMode"
        (click)="erpDiffs.length > 0 ? openModalErpUpdateRef() : fetchErpRefsUpdate()"
        [badge]="erpDiffs.length > 0 ? erpDiffs.length : undefined">
        <mat-icon *ngIf="!fetchingErpDiffs" style="color: #E5B100;" matPrefix>refresh</mat-icon>
        {{ fetchingErpDiffs ? ("buttonUpdateRef.loading" | translate) : (erpDiffs.length === 0 ?( "buttonUpdateRef.noRefs" | translate) : ("buttonUpdateRef.hasRefs" | translate)) }}
      </button-misc>
    </div>
  </section>
  <section class="editor">
    <div class="select-variant">

    </div>
    <div>
      <section class="toolbar">

        <div class="toolbar__selectCont">
          <app-select-variants class="toolbar__selectCont_select"></app-select-variants>
        </div>
        <div class="toolbar__buttons-container">
          <div class="toolbar__unselect" *ngIf="referencesSelected > 0" (click)="unselect()"
            [ngPlural]='referencesSelected'>
            <div class="toolbar__unselect--button">-</div>
            {{ referencesSelected }}
            <ng-template ngPluralCase="=0"></ng-template>
            <ng-template ngPluralCase="=1">
              {{ 'advancedEditorPage.selectedReference.single' | translate }}
            </ng-template>
            <ng-template ngPluralCase="other">
              {{ 'advancedEditorPage.selectedReference.plural' | translate }}
            </ng-template>
          </div>
          <div  class="search-input-cont">
            <mat-form-field  class="search-input" [ngClass]="{'expanded': isExpanded}" appearance="outline">
              <mat-label>
                {{ 'shared.inputs.search.label' | translate }}
              </mat-label>
              <input matInput [(ngModel)]="searchTerm" (input)="onInput()" (focus)="expandField()"
                (blur)="collapseField()" />
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
          </div>

          <button-misc class="button filter" [matMenuTriggerFor]="filter" (menuOpened)="filterOpened = true"
            (menuClosed)="filterOpened = false" [active]="filterOpened" [ngPlural]='filteredColumns'>
            {{ 'shared.actions.filter.label' | translate }}
            <ng-template ngPluralCase="=0"></ng-template>
            <ng-template ngPluralCase="other">({{filteredColumns}})</ng-template>
            <mat-icon class="toolbar__icon">expand_more</mat-icon>
          </button-misc>
          <mat-menu #filter="matMenu" class="menu menu--checkbox" xPosition="before">
            <button [class.menu__item--active]="value.checked" (click)="filterColumns(value, $event)" class="menu__item"
              mat-menu-item *ngFor="let value of columnNames">
              <mat-checkbox [checked]="value.checked" color="primary"></mat-checkbox>
              {{ value.name=='parentName'?( 'models.variant' | translate) : value.name }}
            </button>
          </mat-menu>
          <button-misc [height]="32" class="button" (click)="openModalAddReference()" [disabled]="!editionMode">
            {{ 'shared.actions.insertReference.label' | translate }}
          </button-misc>
          <button-misc style="display: none;" [height]="32" class="button" [disabled]="true">
            {{ 'shared.actions.replace.label' | translate }}
            <mat-icon
              class="toolbar__icon">expand_more</mat-icon></button-misc>
          <button-misc [height]="32" class="button" (click)="openDialog('Copiar', 1)"
            [disabled]="!editionMode || referencesSelected == 0 || referencesSelected > 1">
            {{ 'shared.actions.copy.label' | translate }}
          </button-misc>
          <button-misc style="display: none;"[height]="32" class="button" (click)="openDialog('Mover', 0)"
            [disabled]="referencesSelected == 0 || referencesSelected > 1">
            {{ 'shared.actions.move.label' | translate }}
          </button-misc>
          <button-misc  [height]="32" class="button" (click)="removeReferences()" [color]="'danger'"
            [disabled]="!editionMode || referencesSelected == 0">
            {{ 'shared.actions.remove.label' | translate }}
          </button-misc>

        </div>
      </section>
    </div>

    <div class="table">
      <div class="table__menu">
        <section class="side-menu">
          <button *ngFor="let item of itens" class="side-menu__button" [class.active]="item.active"
            (click)="updateItemType(item.type)">
            <div class="border"></div>
            {{ item.name | translate }}
          </button>
        </section>
      </div>
      <app-references-table [isLoading]="isLoading" [ngClass]="{'table__content': !isLoading, 'table__content--loading': isLoading}" [searchTerm]="searchTerm">
      </app-references-table>
    </div>
  </section>
</div>
