import { Injectable } from '@angular/core';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Observable, tap, of, throwError, lastValueFrom, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { DatasheetService } from './datasheet.service';
import { Datasheet } from '../models/datasheet';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../components/snack-bar/snack-bar-service';
import { PossibleTargetsDataModel } from '../models/possibleTargetsDataModel';
import { ActivityListDataModel } from '../models/activityListDataModel';
import { MaterialListDataModel } from '../models/materialListDataModel';
import { HistoryData } from '../models/history-data';
import { IsaCompanyData } from '../models/isa-company-data';
import { LocalStorageService } from './localStorage.service';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { ErpConfigData, UserConfiguration } from '../models/integration-user-data';
import { Router } from '@angular/router';
import { IsaService } from './isa.service';

import { timeout } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EditedDatasheetProp } from '../models/editionParams';
import { Image } from '../models/image';


const URL = environment.API_URL + '/api/WebApp';
const ISA_URL = environment.ISA_URL;
const LAMBDA_URL = environment.IDEAX_SERVICE;

@Injectable({ providedIn: 'root' })
export class DataStorageService {

  private destroy$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private _datasheetService: DatasheetService,
    private _snackBarService: SnackbarService,
    private _localStorage: LocalStorageService,
    private _translate: TranslateService,
    private _router: Router,
    private _isaService: IsaService,
  ) {

    this._datasheetService.datasheetPropsChanged
    .pipe(takeUntil(this.destroy$))
    .subscribe(editedProps => {
      console.warn( "editedProps:", editedProps );

      if(!editedProps.length) {
        return;
      }

      const datasheet = this._datasheetService.getDatasheet();

      if(editedProps[0]._action == 'moveReference') {
        this._datasheetService.notifyDatasheetPropsSaved(true);
        this.putDatasheet(
          datasheet,
          () => {
            this._datasheetService.setStatus(true);
            this._datasheetService.notifyDatasheetPropsSaved(false);
          },
          (error: any) => {
            console.error('Erro ao salvar: ', error);
            this._datasheetService.notifyDatasheetPropsSaved(false, true);
          },
          false
        );
        return
      }

      this.putDatasheetProp(
        datasheet.fw!.datasheet_uid,
        editedProps,
        () => {},
        (error: any) => {
          console.error('Erro ao salvar: ', error);
        }
      );
    });


    this._datasheetService.sendIsaMetaChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(isaMeta => {
        this.syncIsaMeta(`${isaMeta.modelId}`, null, isaMeta.modelName, isaMeta.description, null);
      });

  }

  storeDataSheet(callback: any = () => {}, errorCallback: any = () => {}) {
    const datasheet = this._datasheetService.getDatasheet();
    const params = this.getCurrentCompanyParam();
    const headers = this._localStorage.getHeadersWithAuthorization();

    if (Object.keys(datasheet).length === 0) {
      return;
    }

    if( !datasheet.fw?.hasOwnProperty('datasheet_uid') || datasheet.fw?.datasheet_uid == '' ) {
      this.postDatasheet( datasheet, callback, errorCallback );
    } else {
      this.putDatasheet( datasheet, callback, errorCallback );
    }
  }

  private async postDatasheet(
    datasheet: Datasheet,
    callback: () => void = () => {},
    errorCallback: (error: any) => void = () => {}
  ): Promise<void> {
    try {
      const headers = this._localStorage.getHeadersWithAuthorization();
      const params = this.getIsaParam(datasheet);
      const images2delete = datasheet.getImages2Delete();
      const images2upload = datasheet.getImages2Upload();

      datasheet.setLastModificationDate2Now();
      datasheet.setupLists();

      if (images2upload.length > 0) {
        await this.uploadImages(datasheet, images2upload);
      }

      const fwRes: string = await lastValueFrom(
        this.http.post<string>(`${URL}/datasheet`, datasheet, { headers, params, observe: 'body', responseType: 'text' as 'json' }).pipe(take(1))
      )
      .catch(error => {
        throw new Error("[FW] "+error.message);
      });

      console.log('Saved datasheet with ID:', fwRes);

      const datasheetUid = fwRes || '';
      const isaModelId = `${datasheet.fw?.isa_model_id}` || '';
      const modelName = datasheet.garment.fields[0].value as string;
      const modelDesc = datasheet.garment.fields[2].value as string;

      await this.syncIsaMeta(isaModelId, datasheetUid, modelName, modelDesc, datasheet.getModelImages());

      // const isaRes = await lastValueFrom(
      //   this._isaService.postIsaItem(this._localStorage.getAuthorizationToken(), isaModelId, datasheetUid, modelName, modelDesc, imgList).pipe(take(1))
      // )
      // .catch(error => {
      //   throw new Error("[ISA] "+error.message);
      // });

      // console.log('Datasheetuid linked to isa item:', isaRes);

      /* TODO: change this isa behavior: NEVER delete images
      if (images2delete.length > 0) {
        const lambdaRes = await lastValueFrom(
          //this.http.post(`${URL}/datasheet`, datasheet, { headers, params, observe: 'response', responseType: 'text' }).pipe(take(1))
          this.http.get(`${URL}/health-check`).pipe(take(1))
        )
        .catch(error => {
          throw new Error("[DELIMG] "+error.message);
        });
        console.log(`${images2delete.length} image(s) deleted!`);
      }
      */

      this.flawlessVictory(datasheetUid, callback);
    } catch (error) {

      if( (error as Error).message.startsWith("[UPIMG]") ) {
        this.finishHim('Erro ao salvar as imagens da Ficha Técnica.', error, errorCallback);
      }

      if( (error as Error).message.startsWith("[FW]") ) {
        this.finishHim('Erro ao salvar Ficha Técnica.', error, errorCallback);
      }

      if( (error as Error).message.startsWith("[ISA]") ) {
        this.finishHim('Erro ao associar modelo à ISA.', error, errorCallback);
      }

      if( (error as Error).message.startsWith("[DELIMG]") ) {
        this.finishHim('Erro excluir imagens.', error, errorCallback);
      }

    }
  }

  private flawlessVictory( datasheetUid: string, callback: any = () => {} ) {
    this._snackBarService.okMessage( this._translate.instant('general.savedSuccessfully') );
    this._datasheetService.setStatus(true);
    callback();
    this._router.navigate(['/properties-editor', datasheetUid]);
  }

  private finishHim( msg: string, error: any, errorCallback: any = () => {} ) {
    this._snackBarService.failMessage(msg);
    console.log(error);
    errorCallback(false);
  }

  private putDatasheet( datasheet: Datasheet, callback: any = () => {}, errorCallback: any = () => {}, showMsg: boolean = true ) {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    this.http
      .put<Datasheet>(`${URL}/datasheet`, datasheet, { headers, params, observe: 'response' })
      .pipe(
        timeout(600000),  // 10 minutos
        catchError(err => {
          return throwError(err);
        })
      )
      .subscribe(
        (response) => {
          if (response instanceof HttpResponse) {
            if( !showMsg ) {
              callback();
              return;
            }
            switch (response.status) {
              case 200:
                this._snackBarService.okMessage( this._translate.instant('general.savedSuccessfully') );
                this._datasheetService.setStatus(true);
                this._datasheetService.updateDatasheetStatus(response.body);
                callback();
                break;
              case 204:
                console.log('Nenhum dado foi alterado.');
                this._datasheetService.updateDatasheetStatus(response.body);
                this._datasheetService.setStatus(true);
                callback();
                break;
              case 206:
                const partialResponse = response.body as any;
                this._snackBarService.warnMessage(partialResponse.message);
                this._datasheetService.updateDatasheetStatus(response.body);
                this._datasheetService.setStatus(true);
                callback();
                break;
              case 418:
                const resp = response.body as any;
                this._snackBarService.warnUnlockMessage(
                    resp.message,
                    'Retry',
                    15000,
                    () => {
                        this.retryUnlockFile();
                    }
                );
                this._datasheetService.updateDatasheetStatus(resp.body);
                this._datasheetService.setStatus(true);
                break;
              default:
                this._snackBarService.failMessage( this._translate.instant('general.unexpectedError') );
                console.error('Unexpected status code:', response.status);
                this._datasheetService.updateDatasheetStatus(response.body);
                errorCallback(false);
            }
          } else {
            if( showMsg ) {
              this._snackBarService.failMessage( this._translate.instant('general.unexpectedError') );
              console.error('Unexpected response:', response);
            }
            errorCallback(false);
          }
        },
        (error) => {
          if( !showMsg ) {
            errorCallback(false);
            return;
          }

          if (error.status == 418) {
            let retry = this._translate.instant('retry.button');
            this._snackBarService.warnUnlockMessage(
                error.error.message,
                retry,
                15000,
                () => {
                    this.retryUnlockFile();
                }
            );
            this._datasheetService.setStatus(true);
            errorCallback(true);
            this._datasheetService.updateDatasheetStatus(error.error.status);
            return;
        }

          let msg = 'Erro ao salvar. ';
          if ( error.name && error.name === 'TimeoutError' ) {
            msg += "Detalhes: " + error.message;
          } else  {
            let errResMsg = error.error.message || error.error.error || JSON.stringify(error.error||error);
            msg += "Detalhes: " + errResMsg;
          }

          this._snackBarService.failMessage(msg);

          console.log(error);
          errorCallback(false);
        }
      );
  }

  private putDatasheetProp( datasheetUid: string, edtParams: EditedDatasheetProp[], callback: any = () => {}, errorCallback: any = () => {} ) {

    this._datasheetService.notifyDatasheetPropsSaved(true);

    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();
    const action = edtParams[0]._action; // edit, addReference, removeReference

    this.http
      .put<Datasheet>(`${URL}/datasheet/${datasheetUid}/${action}`, edtParams, { headers, params, observe: 'response' })
      .pipe(
        timeout(600000),  // 10 minutos
        catchError(err => {
          this._datasheetService.notifyDatasheetPropsSaved(false, true);
          return throwError(err);
        })
      )
      .subscribe(
        (response) => {
          if (response instanceof HttpResponse) {
            switch (response.status) {
              case 200:
                this._datasheetService.setStatus(true);
                this._datasheetService.notifyDatasheetPropsSaved(false);
                callback();
                break;
              default:
                console.error('Unexpected status code:', response.status);
                this._datasheetService.updateDatasheetStatus(response.body);
                this._datasheetService.notifyDatasheetPropsSaved(false, true);
                errorCallback(false);
            }
          } else {
            console.error('Unexpected response:', response);
            this._datasheetService.notifyDatasheetPropsSaved(false, true);
            errorCallback(false);
          }
        },
        (error) => {

          let msg = 'Erro ao salvar. ';
          if ( error.name && error.name === 'TimeoutError' ) {
            msg += "Detalhes: " + error.message;
          } else  {
            let errResMsg = error.error.message || error.error.error || JSON.stringify(error.error||error);
            msg += "Detalhes: " + errResMsg;
          }

          this._datasheetService.notifyDatasheetPropsSaved(false, true);

          console.log(error);
          errorCallback(false);
        }
      );
  }

  // this request will be resolved on `resolve` method at datasheet-resolver.service.ts
  // because we need the `uid` param from route
  loadDataSheetFromUid(uid: string) {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    console.log('>>> loadDataSheetFromUid: ' + uid);
    return this.http.get<Datasheet>(`${URL}/datasheet/${uid}`, {
      headers,
      params,
    });
  }

  public getMaterialsList(
    datasheetId: string,
    variantId: string
  ): Observable<MaterialListDataModel[]> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.get<MaterialListDataModel[]>(
      `${URL}/materialsList?datasheetId=${datasheetId}&variantId=${variantId}`,
      { headers, params }
    );
  }

  public getActivitiesList(
    datasheetId: string,
    variantId: string
  ): Observable<ActivityListDataModel[]> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.get<ActivityListDataModel[]>(
      `${URL}/activitiesList?datasheetId=${datasheetId}&variantId=${variantId}`,
      { headers, params }
    );
  }

  public getPossibleCopyTargets(
    datasheetId: string,
    seedId: string,
    referenceType: string
  ): Observable<PossibleTargetsDataModel> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.get<PossibleTargetsDataModel>(
      `${URL}/copiableTargets?datasheetId=${datasheetId}&referenceId=${seedId}&referenceType=${referenceType}`,
      { headers, params }
    );
  }

  public getPossibleMoveTargets(
    datasheetId: string,
    seedId: string,
    referenceType: string
  ): Observable<PossibleTargetsDataModel> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.get<PossibleTargetsDataModel>(
      `${URL}/movableTargets?datasheetId=${datasheetId}&referenceId=${seedId}&referenceType=${referenceType}`,
      { headers, params }
    );
  }

  public moveReference(referenceId: string, data: any): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.put(
      `${URL}/moveReference?referenceId=${referenceId}`,
      data,
      { headers, params,  observe: 'response' }
    );
  }

  public copyReference(referenceId: string, data: any): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.put(
      `${URL}/copyReference?referenceId=${referenceId}`,
      data,
      { headers, params,  observe: 'response' }
    );
  }

  public addReference(data: any): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.put(
      `${URL}/addReference`,
      data,
      { headers, params,  observe: 'response' }
    );
  }

  public removeReference(data: any): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    const teste = this.http.put(`${URL}/removeReference`, data, { headers, params, observe: 'response' });
    console.log(teste);
    return teste
  }

  // this request will be resolved on `resolve` method at datasheet-resolver.service.ts
  // because we need the `uid` param from route
  loadAllDataSheets() {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    console.log('>>> loadAllDataSheets');
    return this.http.get<Datasheet[]>(`${URL}/datasheet`, { headers, params });
  }

  public loadDatasheetHistory(datasheetId: string): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    const params = this.getCurrentCompanyParam();

    return this.http.get<HistoryData[]>(
      `${URL}/history?DatasheetId=${datasheetId}`,
      { headers, params }
    );
  }

  public getCompaniesFromIsa(): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    return this.http.get<IsaCompanyData>(ISA_URL, { headers });
  }

  private getCurrentCompanyParam(): HttpParams {
    const currentCompany = JSON.parse(
      this._localStorage.get(LocalStorageKeys.USER_CURRENT_COMPANY)
    );
    const companyId = currentCompany.id;
    const params = new HttpParams().set('cpn', companyId);
    return params;
  }

  private getIsaParam( datasheet: Datasheet ): HttpParams {
    const currentCompany = JSON.parse(
      this._localStorage.get(LocalStorageKeys.USER_CURRENT_COMPANY)
    );
    const companyId = currentCompany.id;
    const params = new HttpParams()
                          .set('IsaModelID', datasheet.fw!.isa_model_id)
                          .set('isaCompany', currentCompany.name)
                          .set('isaCompanyId', currentCompany.id)
                          .set('isaCompanyCardCode', datasheet.fw?.isa_company_cardcode || '.')
                          .set('isaShape', datasheet.fw?.isa_shape || '.')
                          .set('isaOwner', datasheet.fw?.isa_owner_user || '.')
                          .set('isaKanbam', datasheet.fw?.isa_kanbam || '.')
                          .set('cpn', currentCompany.id);
    return params;
  }

  private getLambdaParam( datasheet: Datasheet ): HttpParams {
    const currentCompany = JSON.parse(
      this._localStorage.get(LocalStorageKeys.USER_CURRENT_COMPANY)
    );
    const companyId = currentCompany.id;
    const params = new HttpParams()
                          .set('datasheet_uid', datasheet.fw?.datasheet_uid || 0)
                          .set('cpn', companyId);
    return params;
  }

  public getErpConfiguration(): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    let params = this.getCurrentCompanyParam();
    const currentUserInfo = this._localStorage.get('0-ficha_web', 'session', false);
    params = params.set('email', currentUserInfo.userData.email);

    return this.http.get<ErpConfigData>(`${URL}/configuration/erp`, { headers, params });
  }

  public setErpConfiguration(userData: UserConfiguration): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization();
    let params = this.getCurrentCompanyParam();

    return this.http.post<ErpConfigData>(`${URL}/configuration/erp`, userData, { headers, params });
  }

  public lockDatasheet(uid: string, lock: boolean): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization()
                                      .set('Content-Type', 'application/json')
                                      .set('Accept', '*/*');
    let params = this.getCurrentCompanyParam();

    const currentUserInfo = this._localStorage.get('0-ficha_web', 'session', false);
    const userdata = {
      username : currentUserInfo.userData.name,
      usermail : currentUserInfo.userData.email,
      system: "FW"
    }

    const endpoint: string = lock ? 'lock' : 'unlock';

    return this.http.post(`${URL}/datasheet/${endpoint}/${uid}`, JSON.stringify(userdata), { headers, params, observe: 'response' });
  }

  public setEditionMode( uid: string ): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization()
                                      .set('Content-Type', 'application/json')
                                      .set('Accept', '*/*');
    let params = this.getCurrentCompanyParam();

    return this.http.post(`${URL}/datasheet/editionMode/${uid}`, {}, { headers, params });
  }

  public updateIdeax(): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization()
                                      .set('Content-Type', 'application/json')
                                      .set('Accept', '*/*');

    const datasheet = this._datasheetService.getDatasheet();
    const cpn = JSON.parse(this._localStorage.get(LocalStorageKeys.USER_CURRENT_COMPANY));

    let params = new HttpParams();
    params = params.set('datasheetUid', datasheet.fw!.datasheet_uid.toString())
                   .set('cpn', cpn.id.toString());

    return this.http.post(`${URL}/updateIdeax`, {}, { headers, params, observe: 'response' })
      .pipe(
        tap((response: HttpResponse<any>) => {
          if (response instanceof HttpResponse) {
            switch (response.status) {
              case 200:
                this._snackBarService.okMessage( this._translate.instant('general.updatedOK') );
                this._datasheetService.updateDatasheetStatus(response.body?.status);
                break;
              case 206:
                const partialResponse = response.body as any;
                this._snackBarService.warnMessage(partialResponse.message);
                this._datasheetService.updateDatasheetStatus(partialResponse.status);
                break;
              case 418:
                const resp = response.body as any;
                this._snackBarService.warnMessage(resp.message);
                this._datasheetService.updateDatasheetStatus(resp.status);
                break;
              default:
                this._snackBarService.failMessage( this._translate.instant('general.unexpectedError') );
                console.error('Unexpected status code:', response.status);
                this._datasheetService.updateDatasheetStatus(response.body?.status);
            }
          } else {
            this._snackBarService.failMessage( this._translate.instant('general.unexpectedError') );
            console.error('Unexpected response:', response);
          }
        }),
        catchError((error) => {
          if (error.status == 418) {
            this._snackBarService.warnMessage(error.error.message);
            this._datasheetService.updateDatasheetStatus(error.error.status);
          } else {
            let msg = 'Erro ao atualizar. ';
            if (error.error && error.error.message) {
              msg += "Detalhes: " + error.error.message;
            } else if (error.error) {
              msg += "Detalhes: " + error.error;
            }

            this._snackBarService.failMessage(msg);
            console.log(error);
          }
          return of(null);
        })
      );
  }

  public retryUnlockFile(callback?: () => void) {
    const datasheetUid = this._datasheetService.getDatasheet().fw!.datasheet_uid;
    this.lockDatasheet(datasheetUid, false ).subscribe(
        success => {
            if (success) {
                let msg = this._translate.instant('retry.success')
                this._snackBarService.okMessage(msg);
                this._datasheetService.setUnlockState();
                this._datasheetService.setDatasheetReadonly();
                if (callback) {
                    callback();
                }
            } else {
                let msg = this._translate.instant("retry.fail")
                this._snackBarService.warnMessage(msg);
                if (callback) {
                    callback();
                }
            }
        },
        error => {
            let msg = this._translate.instant("retry.error")
            this._snackBarService.failMessage(msg);
            if (callback) {
                callback();
            }
        }
    );
  }

  public async uploadImages( datasheet: Datasheet, images2upload: Image[] = [] ) : Promise<number> {
    const lambdaParam = this.getLambdaParam(datasheet);

    const fwBckEnd = URL.indexOf("localhost") >= 0 ? "fw360-dev.azurewebsites.net" : environment.API_URL
    const headers = this._localStorage.getHeadersWithAuthorization();
    const lambdaHeader = headers.set('X-FW', fwBckEnd);
    if( images2upload.length == 0) {
      images2upload = datasheet.getImages2Upload();
    }
    if( images2upload.length == 0) {
      return 0;
    }

    let images2up = [];
    for( let img of images2upload ) {
      images2up.push( img.url! );
    }
    const uploadRes = await lastValueFrom(
      this.http.post<string[]>(`${LAMBDA_URL}/api/images2bucket`, images2up, { headers: lambdaHeader, params: lambdaParam, observe: 'body', responseType: 'json' }).pipe(take(1))
    )
    .catch(error => {
      throw new Error("[UPIMG] "+error.message);
    });

    console.log(uploadRes);
    for( let i = 0; i < images2upload.length; i++ ) {
      images2upload[i].url = uploadRes[i];
    }

    console.log(`${images2upload.length} image(s) uploaded!`);

    return uploadRes.length;
  }

  public async syncIsaMeta( isaModelId: string, datasheetUid?: string|null, modelName?: string|null, modelDesc?: string|null, imgList?:any[]|null ) {
    let isaImgList = [];
    for( const img of imgList || [] ) {
      isaImgList.push({
        "previewReference" : img.url,
        "thumbReference" : img.url,
      });
    }
    const isaRes = await lastValueFrom(
      this._isaService.postIsaItem(this._localStorage.getAuthorizationToken(), isaModelId, datasheetUid, modelName, modelDesc, imgList?isaImgList:null).pipe(take(1))
    )
    .catch(error => {
      throw new Error("[ISA] "+error.message);
    });

    console.log('Datasheetuid linked to isa item:', isaRes);
  }

  public updateDatasheetConfigs(configs: any, shouldConvertUm: boolean): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization()
                                      .set('Content-Type', 'application/json')
                                      .set('Accept', '*/*');

    const datasheet = this._datasheetService.getDatasheet();
    const cpn = JSON.parse(this._localStorage.get(LocalStorageKeys.USER_CURRENT_COMPANY));
    const currentUserInfo = this._localStorage.get('0-ficha_web', 'session', false);
    const userEmail = currentUserInfo.userData.email;


    let params = new HttpParams();

    params = params.set('datasheetUid', datasheet.fw!.datasheet_uid.toString())
                  .set('cpn', cpn.id.toString())
                  .set('userEmail', userEmail)
                  .set('shouldConvertUm', shouldConvertUm.toString());

    return this.http.put(`${URL}/configuration/global`, configs, { headers, params, observe: 'response' });

  }

  public getAllReferencesFromCompany(
    refType: string,
    referenceName:string = '',
    page: number = 1,
    pageSize: number = 20,
    includeErpRefs: boolean = false
  ): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization()
                                      .set('Content-Type', 'application/json')
                                      .set('Accept', '*/*');
    let params = this.getCurrentCompanyParam();
    params = params.set('refType', refType)
                   .set('page', page)
                   .set('pageSize', pageSize)
                   .set('includeErpRefs', includeErpRefs)
                   .set('referenceName', referenceName);

    return this.http.get(`${URL}/references`, { headers, params });
  }

  public updateUserConfig(configs: any): Observable<any> {
    const headers = this._localStorage.getHeadersWithAuthorization()
    .set('Content-Type', 'application/json')
    .set('Accept', '*/*');

    const cpn = JSON.parse(this._localStorage.get(LocalStorageKeys.USER_CURRENT_COMPANY));
    const currentUserInfo = this._localStorage.get('0-ficha_web', 'session', false);
    const userEmail = currentUserInfo.userData.email

    let params = new HttpParams();
    params = params.set('userEmail', userEmail)
    .set('cpn', cpn.id.toString());;

    return this.http.put(`${URL}/configuration/user`, configs, { headers, params, observe: 'response' });
  }

  public async putIsaFields(isaModelId: string, property: any[]): Promise<any> {
    try {
      const isaRes = await lastValueFrom(
        this._isaService.putIsaModelFields(this._localStorage.getAuthorizationToken(), isaModelId, property).pipe(take(1))
      );
      return isaRes;
    } catch (error:any) {
      throw new Error("[ISA] " + error.message);
    }
  }

}
