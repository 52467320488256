import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,

} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { CategoryType, ReferenceType } from 'src/app/models/enums/reference-type';
import { Reference } from 'src/app/models/reference';
import { Field } from 'src/app/models/field';
import { CategoryField } from 'src/app/models/category';
import { Image } from 'src/app/models/image';
import * as math from 'mathjs';
import { Datasheet } from 'src/app/models/datasheet';
import { Group } from 'src/app/models/groups';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldCreatorComponent, CustomFieldCreatorData, GeneretedCustomField } from '../custom-field-creator/custom-field-creator.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '../snack-bar/snack-bar-service';

@Component({
  selector: 'app-references-editor-drawer-form',
  templateUrl: './references-editor-drawer-form.component.html',
  styleUrls: ['./references-editor-drawer-form.component.scss'],
})
export class ReferencesEditorDrawerFormComponent implements OnChanges {
  @Output() close_drawer = new EventEmitter();
  @Output() totalValueChange = new EventEmitter();
  @Input() inputData?: any;
  @Input() inputImages?: any;

  public ReferenceData!: any;
  public ReferenceDataPath!: string;
  public ReferenceDataName!: string;

  private destroy$ = new Subject<boolean>();

  formFields!: FormGroup;
  formCustomFields!: FormGroup;
  formGroupFields!: FormGroup;
  formImages!: FormGroup;

  defaultFields!: Field[];
  customFields!: Field[];
  groupFields!: Field[];
  formulaDefaultFields!: Field[];
  formulaCustomFields!: Field[];
  imageList!: Image[];

  orderFields: string[] = []

  showCustomFields: boolean = false;
  datasheet!: Datasheet;

  refType!:CategoryType;

  constructor(
    private _fb: FormBuilder,
    private _datasheetService: DatasheetService,
    private _translateService: TranslateService,
    private _snack: SnackbarService,
    public _dialog: MatDialog,
  ) {
    this.datasheet = this._datasheetService.getDatasheet();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges() {
    console.log(this.inputData);
    if( this.inputData === undefined ) {
      return
    }

    let datasheet = this._datasheetService.getDatasheet();
    let categoryFields: CategoryField[] = [];
    switch (this.inputData.referenceData.ReferenceType) {
      case ReferenceType.Material:
        this.refType = CategoryType.materials;
        this.ReferenceData = this._datasheetService.getMaterial(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.Material,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        console.log(this.ReferenceData)
        categoryFields = datasheet.categories.material.fields;
        this.orderFields = ["code", "group", "desc", "supplier", "color", "obs", "cost", "amount", "um", "total"];
        break;
      case ReferenceType.Activity:
        this.refType = CategoryType.activities;
        this.ReferenceData = this._datasheetService.getActivity(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.Activity,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        categoryFields = datasheet.categories.activity.fields;
        this.orderFields = ["code", "desc", "sector", "machine", "obs", "cost", "amount", "um", "total"]
        break;
      case ReferenceType.Measures:
        this.refType = CategoryType.measures;
        this.ReferenceData = this._datasheetService.getMeasure(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.Measures,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        categoryFields = datasheet.categories.measures.fields;
        this.orderFields = ["code", "measure", "obs"];
        break;
      case ReferenceType.Free:
        this.refType = CategoryType.generics;
        this.ReferenceData = this._datasheetService.getGeneric(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.Free,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        categoryFields = datasheet.categories.free.fields;
        this.orderFields = ["code", "desc"];
        break;
      case ReferenceType.Pattern:
        this.ReferenceData = this._datasheetService.getPattern(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.Pattern,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        categoryFields = datasheet.categories.pattern!.fields;
        this.orderFields = ["code", "desc"];
        break;
      case ReferenceType.Marker:
        this.ReferenceData = this._datasheetService.getMarker(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.Marker,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        categoryFields = datasheet.categories.marker!.fields;
        this.orderFields = ["code", "desc"];
        break;
      case ReferenceType.FashionStd:
        this.ReferenceData = this._datasheetService.getFashionStd(
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        [this.ReferenceDataPath, this.ReferenceDataName] = this._datasheetService.getReferencePath(
          ReferenceType.FashionStd,
          this.inputData.referenceData.ReferenceIndex,
          this.inputData.variantIndex,
          this.inputData.subVariantIndex,
          this.inputData.subSubVariantIndex
        );
        categoryFields = datasheet.categories.fashion_studio!.fields;
        this.orderFields = ["code", "desc"];
        break;

      default:
        break;
    }
    this.createReferenceStandardValuesForm(this.ReferenceData, categoryFields);
    this.createReferenceImages(this.ReferenceData);
  }


  public hasOptions(field: Field): boolean {
    if(field.hasOwnProperty('options') && field.options?.length)
      return true
    return false;
  }

  private hasFormula(field: Field): boolean {
    return (field.hasOwnProperty('formula') && !!field.formula);
  }

  public closeDrawer() {
    this.close_drawer.emit();
  }

  private sort( fields: Field[] ) {
    let sorted:Field[] = [];

    for( let k of this.orderFields ) {
      const field = fields.find(x => x.name === k);
      if( field ) {
        sorted.push(field);
      }
    }

    for( let f of fields ) {
      const exists = sorted.find(x => x.name === f.name);
      if( !exists ) {
        sorted.push(f);
      }
    }
    return sorted;
  }

private createReferenceStandardValuesForm(myRef: Reference, categoryFields: CategoryField[]): void {
  this.customFields = this.datasheet.getCustomFields(myRef.fields, categoryFields);
  this.defaultFields = this.sort( this.datasheet.getDefaultFields(myRef.fields, categoryFields) );

  this.groupFields = [];
  const group = (myRef as Group).patterns || (myRef as Group).markers || [];
  if( group.length ) {
    for( let p of group ) {

      let fields = this.datasheet.getDefaultFields(p.fields, categoryFields)

      this.groupFields.push(...fields);
    }
    this.initGroupForm();
  }

  this.formulaDefaultFields = this.datasheet.getFieldsWithFormula(this.defaultFields);
  this.formulaCustomFields = this.datasheet.getFieldsWithFormula(this.customFields);

  this.initDefaultForm();
  this.initCustomForm();
}

private initDefaultForm(): void {
  const formControls: { [key: string]: any } = {};
  this.defaultFields.forEach((field) => {
    formControls[field.name] = this.createFieldControl(field);
  });
  this.formFields = new FormGroup(formControls);

  let previousValues: { [key: string]: any } = { ...this.formFields.value };
  this.notifyFormulaChanges(this.formFields, this.formulaDefaultFields, this.defaultFields);

  this.formFields.valueChanges
  .pipe(takeUntil(this.destroy$))
  .subscribe((changes) => {
    const changedFields: { [key: string]: any } = {};
    for (const key in changes) {
      if (changes.hasOwnProperty(key) && changes[key] !== previousValues[key]) {
        changedFields[key] = changes[key];
      }
    }
    this.updateFieldsOnChange(changedFields, this.defaultFields);
    previousValues = { ...changes };
  });
}

private initCustomForm(): void {
  const formControls: { [key: string]: any } = {};
  this.customFields.forEach((field) => {
    formControls[field.name] = this.createFieldControl(field);
  });

  this.showCustomFields = Object.keys(formControls).length > 0;
  this.formCustomFields = new FormGroup(formControls);

  let previousValues: { [key: string]: any } = { ...this.formCustomFields.value };

  this.formCustomFields.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
    const changedFields: { [key: string]: any } = {};
    for (const key in changes) {
      if (changes.hasOwnProperty(key) && changes[key] !== previousValues[key]) {
        changedFields[key] = changes[key];
      }
    }
    this.notifyFormulaChanges(this.formCustomFields, this.formulaCustomFields, this.customFields);
    this.updateFieldsOnChange(changedFields, this.customFields);
    previousValues = { ...changes };
  });
}

private initGroupForm(): void {
  const formControls: { [key: string]: any } = {};
  this.groupFields.forEach((field) => {

    if( field.type == "Boolean" ) {
      field.value = this._translateService.instant(field.value! as string);
    }

    formControls[field.name+'-'+field.value] = this.createFieldControl(field);
  });
  this.formGroupFields = new FormGroup(formControls);

}

private createFieldControl(field: Field): any {
  if (field.type === 'Color') {
    let fgC = new FormGroup({
      name: new FormControl(field.color?.name, { updateOn: 'blur' }),
      value: new FormControl(field.color?.value, { updateOn: 'blur' }),
      alpha: new FormControl(field.color?.alpha, { updateOn: 'blur' }),
      type: new FormControl(field.color?.type, { updateOn: 'blur' })
    });
    if (field.read_only) {
      fgC.disable();
    }
    return fgC;
  } else {
    return new FormControl({
      value: field.value,
      disabled: field.read_only
    }, { updateOn: 'blur' });
  }
}

private updateFieldsOnChange(changes: any, fields: Field[]): void {
  fields.forEach((field) => {
    const changedValue = changes[field.name];
    if (changedValue !== undefined  ) {
      this._datasheetService.setReference(this.ReferenceData, changes, this.ReferenceDataPath, this.ReferenceDataName);
      if (field.name === 'total') {
        this.totalValueChange.emit();
      }
    }
  });
}

  private notifyFormulaChanges(form: FormGroup, formulaFields: Field[], fields: Field[]): void {
    formulaFields.forEach((formulaField: Field) => {
      const variableFields = this.extractVariablesFromFormula(formulaField.formula!, fields);
      variableFields.forEach((variableField: Field) => {
        form.get(variableField.name)?.valueChanges
          .pipe(takeUntil(this.destroy$))
          .subscribe((change: any) => {
            variableField.value = change;
            const result = this.evaluateFormula(formulaField.formula!, variableFields);
            form.patchValue({
              [formulaField.name]: result
            });
            fields.forEach((field: Field) => {
              if(field.name == formulaField.name) {
                field.value = result;
              }
            });
            const changes = {
              [formulaField.name]: result.toString()
            }  ;
            this._datasheetService.setReference(
              this.ReferenceData,
              changes,
              this.ReferenceDataPath,
              this.ReferenceDataName
            );
            if(formulaField.name === 'total') {
              this.totalValueChange.emit();
            }
          })
        })
    });
  }

  private createReferenceImages(myRef: Reference | Group) {
    this.imageList = this.datasheet.getImageFields(myRef);

    if(this.imageList){
      const formControls: any = {};
      this.imageList.forEach((img) => {
        formControls[img.id] = new FormControl({
          id: img.id,
          location: img.location
        });
      });

      this.formImages = new FormGroup(formControls);
    }
    else{
      this.imageList = [];
    }
  }

  public getFieldType(ref: Field): string | undefined {
    return ref?.type === 'DateTime' ? 'datetime-local' : ref?.type;
  }

  public handleColorObj( colorObj: any) : string {

    if(colorObj.hasOwnProperty('color') && colorObj.color) {
      if( this.formFields.get(colorObj.name) && this.formFields.get(colorObj.name)?.value == "" ) {
        this.formFields.get(colorObj.name)!.patchValue(colorObj.color!.value, {emitEvent: false});
      }

      return colorObj.color!.value;
    }
    return colorObj!.value;
  }

  private extractVariablesFromFormula(expression: string, fields: Field[]): Field[] {
    const regex = /\{\.(.*?)\}/g;
    const fieldsList = [];
    let match: any;

    while ((match = regex.exec(expression)) !== null) {
      const found = fields.find((field: any) => {
        return field.label == match[1];
      });
      if(!!found) {
        fieldsList.push(found);
      }
    }
    return fieldsList;
  }

  //{nome da referencia.etiqueta do campo@variant}
  //apenas calculos simples
  //TODO: revisar depois
  private evaluateFormula(formula: string, fields: Field[]) {
    fields.forEach((variable) => {
      formula = formula.replace(new RegExp(`{\\.${variable.label}}`, 'g'), variable.value!.toString().replace(",", "."));
    });

    try {
      return Math.round( math.evaluate(formula) * 100) / 100; ;
    } catch (error) {
      throw new Error(`Erro ao calcular a expressão: + ${error}`);
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event: { stopPropagation: () => void; }) {
    event.stopPropagation();
  }

  public createCustomField() {
    this.openCustom().subscribe((field: GeneretedCustomField) => {
      if (field) {
        const customFieldData = this.createCustomFieldCreatorData(field);
        const data = this._datasheetService.createCustomField(customFieldData);
        if(data) {
          this._datasheetService.notifyCustomFieldAdded( [data] );
          this.createReferenceStandardValuesForm(this.ReferenceData, this.datasheet.categories[this.refType]!.fields);
          this._dialog.closeAll();
        } else{
          this._snack.warnMessage(this._translateService .instant('propertyEditorPage.duplicatedCustomField'));
        }
      }
    });
  }

  private createCustomFieldCreatorData( field: GeneretedCustomField): CustomFieldCreatorData {
    let parentFields = this.ReferenceData.fields ;
    const parentCategoryField = this.datasheet.categories[this.refType]!.fields;
    const parentCategoryFieldPath = `categories.${this.refType}.fields`;
    const parentName = this.ReferenceData.fields[0].value;

    const customFieldCreatorData: CustomFieldCreatorData = {
      parentFields,
      parentCategoryField,
      field,
      parentFieldsPath: this.ReferenceDataPath,
      parentCategoryFieldPath,
      parentName
    };

    return customFieldCreatorData;
  }

  onEditField(event: { field: Field, parent: string }): void {
    this.openCustom(event.field).subscribe((editedField: any) => {
      if (editedField) {
        let created = this.createCustomFieldCreatorData(editedField)
        this._datasheetService.editCustomField(created)
        this.createReferenceStandardValuesForm(this.ReferenceData, this.datasheet.categories[this.refType]!.fields);
        this._dialog.closeAll();
      }
    });
  }

  onDeleteField(event: { name: string, parent: string }): void {
    let parentFields = this.ReferenceData.fields ;
    let parentCategoryField = this.datasheet.categories[this.refType]!.fields;

    const parentFieldsPath = this.ReferenceDataPath;
    const parentCategoryFieldPath = `categories.${this.refType}.fields`;
    const parentName = this.ReferenceData.fields[0].value;

    const result = this._datasheetService.removeCustomField(
      parentFields,
      parentCategoryField,
      event.name,
      parentFieldsPath,
      parentCategoryFieldPath,
      parentName
    );

    if (result) {
      this.createReferenceStandardValuesForm(this.ReferenceData, this.datasheet.categories[this.refType]!.fields);

    } else {
      this._snack.warnMessage("Não foi possível remover o campo personalizado.");
    }
  }

  private openCustom(data?: any): Observable<GeneretedCustomField> {
    const dialogRef = this._dialog.open(CustomFieldCreatorComponent, {
      width: '35%',
      data: data
    });

    return new Observable(observer => {
      const instance = dialogRef.componentInstance;
      instance.fieldCreated.subscribe((field: GeneretedCustomField) => {
        observer.next(field);
      });

      dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }

  getCurrency(){
    return this._datasheetService.getDatasheet().getCurrencySymbol();
  }

  getMeasureSymbol(measure: string){
    return this._datasheetService.getDatasheet().getMeasure(measure);
  }

}
