
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-serch-bar',
  templateUrl: './serch-bar.component.html',
  styleUrls: ['./serch-bar.component.scss']
})
export class SerchBarComponent implements OnInit {

  @Output() searchTermEmmit = new EventEmitter<string>();
  public searchTerm!: string;

  constructor() { }

  ngOnInit(): void {
  }

  sendSearchValue(event:any){
    this.searchTermEmmit.emit(this.searchTerm)
  }

}
