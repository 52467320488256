import { PropertiesEditorModule } from './routes/properties-editor/properties-editor.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient , HttpClientModule } from '@angular/common/http';
import { AdvancedEditorModule } from './routes/advanced-editor/advanced-editor.module';
import { AuthModule, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './routes/home/home.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { WelcomeBannerComponent } from './components/welcome-banner/welcome-banner.component';
import { RedirectComponent } from './routes/redirect/redirect.component';
import { GraphQLModule } from './graphql/graphql.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { PrintComponent } from './routes/print/print.component';
import { PrintPreviewComponent } from './components/print-preview/print-preview.component';
import { PrintModule } from './routes/print/print.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { translateHttpLoaderFactory } from 'src/assets/i18n/config/http.loader';


import { ConfigurationDialogComponent } from './components/configuration-dialog/configuration-dialog.component';
import { ErpConfigurationComponent } from './components/erp-configuration/erp-configuration.component';
import { RemoveS3LinkPipe } from './pipes/remove-s3-link.pipe';
import { ErpUpdateRefComponent } from './components/erp-update-ref/erp-update-ref.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfirmRefUpdateComponent } from './components/confirm-ref-update/confirm-ref-update.component';
import { UpdateIdeaxModalComponent } from './components/update-ideax-modal/update-ideax-modal.component';
import { VariantManangerComponent } from './components/variant-mananger/variant-mananger.component';
import { TreeModule } from 'primeng/tree';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { EditVariationNameComponent } from './components/variant-mananger/variation_name/edit-variation-name/edit-variation-name.component';
import { AdvancedVariantManangerComponent } from './components/advanced-variant-mananger/advanced-variant-mananger.component';
import { IsaCollectionsDialogComponent } from './components/isa-collections-dialog/isa-collections-dialog.component';
import { CustomFieldCreatorComponent } from './components/custom-field-creator/custom-field-creator.component';
import { ImportIdeaxDialogComponent } from './components/import-ideax-dialog/import-ideax-dialog.component';
import { FwConfigurationComponent } from './components/fw-configuration/fw-configuration.component';

export const httpLoaderFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<any>(environment.openidConfigUrl).pipe(
    map((envCfg: any) => {
      return {
        authority: envCfg.authority,
        redirectUrl: envCfg.redirectUrl,
        postLogoutRedirectUri: envCfg.postLogoutRedirectUri,
        clientId: envCfg.clientId,
        scope: envCfg.scope,
        responseType: envCfg.responseType,
        silentRenew: envCfg.silentRenew,
        useRefreshToken: envCfg.useRefreshToken,
        logLevel: envCfg.logLevel,
      };
    })
  );

  return new StsConfigHttpLoader(config$);
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    WelcomeBannerComponent,
    RedirectComponent,
    ConfigurationDialogComponent,
    ErpConfigurationComponent,
    ErpUpdateRefComponent,
    ConfirmRefUpdateComponent,
    UpdateIdeaxModalComponent,
    VariantManangerComponent,
    EditVariationNameComponent,
    AdvancedVariantManangerComponent,
    IsaCollectionsDialogComponent,
    CustomFieldCreatorComponent,
    ImportIdeaxDialogComponent,
    FwConfigurationComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    PropertiesEditorModule,
    AdvancedEditorModule,
    PrintModule,
    MatCardModule,
    GraphQLModule,
    MatFormFieldModule,
    NgxPaginationModule,
    TreeModule,
    ConfirmDialogModule,
  ],
  providers: [
    HttpClient,
    ConfirmationService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
  exports: [
    AuthModule,
    ConfirmDialogModule,
  ],
})
export class AppModule {}
