import { Observable, catchError, defer, filter, from, map, mergeMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageCookiesService {

  constructor(private _oidcService: OidcSecurityService,
    private http: HttpClient) { }

  public createSignedTokens(): Observable<string> {   
    let accessToken = '';

    this._oidcService.getAccessToken()
    .pipe(
      take(1)
    ).subscribe(
      (token) => {
        accessToken = token;
      }
    );

    return this.http.get(
      environment.ISA_COOKIE_TOKEN,
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    ).pipe(
      mergeMap((data: any) => this.requestSignedCookies(data['token'])),
      take(1),
      catchError(error => {
        throw new Error(error);
      })
    );
  }
  
  private requestSignedCookies(jwtToken: string): Observable<string> {
    const headers = {
      'token': jwtToken,
      'isa-hostname': window.location.origin
    };
  
    return this.http.get(environment.ISA_COOKIE_AUTH, { headers, withCredentials: true }).pipe(
      map((result: any) => result['resources']),
      catchError(error => {
        throw new Error(error);
      })
    );
  }
}
