import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of, take, tap } from 'rxjs';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { MatStepper } from '@angular/material/stepper';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { ReferencesService } from 'src/app/services/references.service';
import { Reference } from 'src/app/models/reference';
import { FindReferences, ReferencesData } from 'src/app/models/findReference';
import { ReferenceType } from 'src/app/models/enums/reference-type';
import { ReferenceErpUtil } from 'src/app/models/referenceErpUtil';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { v4 as uuidv4 } from 'uuid';
import { CategoryField } from 'src/app/models/category';




@Component({
  selector: 'app-add-fw-reference',
  templateUrl: './add-fw-reference.component.html',
  styleUrls: ['./add-fw-reference.component.scss']
})
export class AddFwReferenceComponent {
  @ViewChild(MatStepper) stepper!: MatStepper;
  public selectedReferenceType: string = 'materials';
  public referenceType: string[] = [ 'materials', 'activities', 'measures', 'generics' ];

  public currentPage: number = 1;
  public itemsPerPage: number = 20;
  public totalItems: number = 0;

  public keepWindowOpened: boolean = false;
  public referenceAmount: number = 1;
  

  public searchRefForm: FormGroup;
  public referenceData?: FindReferences;
  public isloading: boolean = false;
  public searchRefs: boolean = false;

  selectedIndex: number | null = null;
  selectedRef?: ReferencesData;

  applyAtLeast1x = false;

  targetList?: any;
  targetName?: string;
  refType?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _datasheetService: DatasheetService,
    private _dataStorage: DataStorageService,
    private _snack: SnackbarService,
    private translate: TranslateService,
    private _localStorage: LocalStorageService,
    private _referencesServices: ReferencesService,
    private _dialogRef: MatDialogRef<AddFwReferenceComponent>,
    private _analyticsService: AnalyticsService,
  ) {
    this.targetList = this.data.targetList || null;
    this.targetName = data.targetName || null;
    this.refType = data.refType || null;

    if(this.refType) {
      switch (this.refType) {
        case ReferenceType.Material:
          this.selectedReferenceType = 'materials';
          break;
        case ReferenceType.Activity:
          this.selectedReferenceType = 'activities';
          break;
        case ReferenceType.Measures:
          this.selectedReferenceType = 'measures';
          break;
        case ReferenceType.Free:
          this.selectedReferenceType = 'generics';
          break;
      }
    }

    this.searchRefForm = this._fb.group({
      referenceType: ['', [Validators.required]],
      referenceName: ['']
    });

  }

  ngOnInit(): void {
    let tp = this._localStorage.get('fw_current_ref_type');
    if(tp && !this.refType) {
      this.selectedReferenceType = tp;
    }

  }

  onChangeRefType(item: string) {
    this.selectedReferenceType = item;
    this._localStorage.set('fw_current_ref_type', this.selectedReferenceType);
  }

  selectReference(i: number, ref: any) {
    this.selectedIndex = i;
    this.selectedRef = ref;
  }

  public getReferences( page?: number) {

    if( page ) {
      this.currentPage = page;
    }

    if( !this.searchRefForm.valid ) {
      Object.keys(this.searchRefForm.controls).forEach(key => {
        this.searchRefForm.controls[key].markAsTouched();
      });

      this.translate.get('alerts.verifyFields.text').subscribe((trans: string) => {
        this._snack.failMessage( trans );
      });
      return;
    }

    let refType = this.searchRefForm.get('referenceType')!.value;
    let referenceName = this.searchRefForm.get('referenceName')!.value;

    this._analyticsService.trackStartEvent("searchRefFromFW");

    this.searchRefs = true;


    this._dataStorage.getAllReferencesFromCompany(refType, referenceName, this.currentPage, this.itemsPerPage)
    .pipe(
      take(1),
      tap((findRefs) => {
        this.referenceData = findRefs;
        this.currentPage = findRefs.currentPage;
        this.totalItems = findRefs.total;

        this.searchRefs = false;
        
        console.log( findRefs );
      }),
      catchError((error) => {
        this.searchRefs = false;

        let msg = error.error.message || error.error.error || JSON.stringify(error.error||error);
        this._snack.failMessage( this.translate.instant("editionMode.error") +  msg, '',);
        console.error('Ocorreu um erro:', error);
        return of(null);
      })
    )
    .subscribe();
  }

  private insertReference(keepInserting = false) {

    if( !this.selectedRef ) {
      this.translate.get('erp.messages.selectAReference').subscribe((trans: string) => {
        this._snack.failMessage( trans );
      });
      return;
    }
    
    this.prepareReferenceToInsert();

    if (this.targetList) {
      this.insertSingleReference(this.selectedRef, this.selectedReferenceType, keepInserting);

      this.handleKeepEditing( keepInserting );
    }

  }

  private insertSingleReference(referenceData: ReferencesData, catType: string, keepInserting: boolean) {
    
    let target = this.getTargetListByCategoryType(catType);

    let usedCatFld: CategoryField[] = [];
    for( const fld of referenceData.reference.fields ) {
      const found = referenceData.categoryFields.find( cf => cf.name == fld.name );
      if( found ) 
        usedCatFld.push( found );
    }
    this._datasheetService.addReference(
      target,
      referenceData.reference,
      this.data.variantIndexes,
      ReferenceErpUtil.referenceType(this.refType),
      "",
      this.getCategoryPath(catType),
      usedCatFld
    );
  }

  private getTargetListByCategoryType(catType: string): any[] {
    switch (catType) {
      case 'measures':
        return this.targetList!.measures;
      case 'materials':
        return this.targetList!.materials;
      case 'activities':
        return this.targetList!.activities;
      case 'generics':
        return this.targetList!.generics;
      default:
        return [];
    }
  }

  private getCategoryPath(catType: string): string {
    switch (catType) {
      case 'measures':
        return 'categories.measures.fields';
      case 'materials':
        return 'categories.material.fields';
      case 'activities':
        return 'categories.activity.fields';
      case 'generics':
        return 'categories.free.fields';
      default:
        return '';
    }
  }

  insertAndRestartClick() {
    this._analyticsService.trackStartEvent("insertFromFW");
    
    this.insertReference(this.keepWindowOpened);
    this._analyticsService.trackEndEvent("insertFromFW");
  }

  private handleKeepEditing( keepInserting: boolean ) {
    if(!keepInserting) {
      this.selectedRef = undefined;
      this.selectedIndex = null;
      this._dialogRef.close();
    }
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.getReferences();
  }

  prepareReferenceToInsert() {
    if( !this.selectedRef ) {
      return;
    } 

    this.selectedRef = JSON.parse( JSON.stringify(this.selectedRef) ) as ReferencesData;
    this.selectedRef.reference.uid = uuidv4();
    this.selectedRef.reference.variation_name='';
    this.selectedRef.reference.id_idea='FW-CP';
    for( const f of this.selectedRef.reference.fields ) {
      f.read_only = false;
    }
    
    this.selectedRef.reference.variation_name = `${this.refType}-${this.selectedRef.reference.uid}`;

    let total = this.selectedRef.reference.fields.find( r => r.name === "total" );
    let cost = this.selectedRef.reference.fields.find( r => r.name === "cost" );
    let time = this.selectedRef.reference.fields.find( r => r.name === "time" );
    let amount = this.selectedRef.reference.fields.find( r => r.name === "amount" );

    if( amount ) {
      amount.value = this.referenceAmount;

      if(cost && total) {
        total.value = this.referenceAmount * (cost.value as number);
      }
    }
    
    if( time ) {
      time.value = this.referenceAmount;

      if(cost && total) {
        total.value = this.referenceAmount * (cost.value as number);
      }
    }
  }

  refererenceDetail( reference: Reference ) : string[] {

    let line1 = '';
    let line2 = '';

    let desc = reference.fields.find( r => r.name === "desc" )?.value as string || '';
    switch (this.refType) {
      case ReferenceType.Material:
        let group = reference.fields.find( r => r.name === "group" )?.value || '';
        let supplier = reference.fields.find( r => r.name === "supplier" )?.value || '';
        line1 = desc;
        line2 = [group, supplier].filter(val => val).join(' / ');
        break
      case ReferenceType.Activity:
        let machine = reference.fields.find( r => r.name === "machine" )?.value || '';
        let sector = reference.fields.find( r => r.name === "sector" )?.value || '';
        line1 = desc;
        line2 = [sector, machine].filter(val => val).join(' / ');
        break;
      case ReferenceType.Measures:
        line1 = reference.fields.find( r => r.name === "obs" )?.value as string || '';
        line2 = reference.fields.find( r => r.name === "measure" )?.value as string || '';
        break;
      case ReferenceType.Free:
        line1 = desc;
        this.selectedReferenceType = 'generics';
        break;
    }
    return [line1, line2];
  }


}
