import { Component, OnInit, AfterContentInit } from '@angular/core';
import { take } from 'rxjs';
import { Datasheet } from 'src/app/models/datasheet';

import { DatasheetService } from 'src/app/services/datasheet.service';
import { ReferencesService } from 'src/app/services/references.service';


@Component({
  selector: 'app-select-variants',
  templateUrl: './select-variants.component.html',
  styleUrls: ['./select-variants.component.scss'],
})
export class SelectVariantsComponent implements AfterContentInit {
  public variants?: any[];
  public selectedVariant: number = 0;

  constructor(
    private _datasheetService: DatasheetService,
    private _referencesService: ReferencesService

  ) {}

  ngAfterViewInit(): void {

    this._datasheetService.dataSheetChanged
      .pipe(take(1))
      .subscribe(dt => {
        const datasheet = new Datasheet( dt );
        const variants = datasheet.garment.variants;
        this.selectedVariant = 0;
        this.variants = variants.map((variant, index) => {
          return { name: datasheet.getFieldValue('code', variant.fields), index: index };
        });
      });
  }

  ngAfterContentInit() {
    this.variants = this._datasheetService.getDatasheet().garment.variants;
    this.onSelectVariant({index:0});
  }

  onSelectVariant(myVariant: any) {
    let variant = this._datasheetService.getVariant(myVariant.index);
    this.selectedVariant = myVariant.index;
    this._referencesService.setSelectedVariant(variant?.uid || '');
    this._referencesService.setSelectedReferences([]);
  }
}
