import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';
import { IsaMetadata } from 'src/app/models/isaMetadata';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { DatasheetService } from 'src/app/services/datasheet.service';

@Component({
  selector: 'app-isa-model-fields',
  templateUrl: './isa-model-fields.component.html',
  styleUrls: ['./isa-model-fields.component.scss']
})
export class IsaModelFieldsComponent implements OnInit {
  public form!: FormGroup;
  public fieldsData: any[] = [];
  public data!: IsaMetadata
  private destroy$ = new Subject<boolean>();
  isFormInitialized: boolean = false


  constructor(
    private fb: FormBuilder,
    private _datasheetService: DatasheetService,
    private _dataStorage: DataStorageService
  ){}

  ngOnInit(): void {
    this._datasheetService.isaMetaChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(isaMeta => {
        this.data = isaMeta;
        if (!this.isFormInitialized) {
          this.createForm();
          this.subscribeFormChanges();
        }
      });
  }


  createForm() {
    const formControls: { [key: string]: any } = {};

    this.data!.isaModelFields!.forEach((field) => {
      if (field.type === 'Date') {
        const dateParts = field.value ? field.value.split('-') : null;
        const dateValue = dateParts
          ? new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))
          : null;

        formControls[field.key] = new FormControl(
          { value: dateValue, disabled: field.read_only },
          { updateOn: 'blur' }
        );

      } else if (field.type === 'Multiple') {
        formControls[field.key] = new FormControl(
          { value: field.choices || [], disabled: field.read_only },
          { updateOn: 'blur' }
        );
      } else if (field.type === 'Currency') {
        formControls[field.key] = new FormControl(
          { value: field.value || '', disabled: field.read_only },
          { updateOn: 'blur' }
        );
      }  else if (field.type === 'Conditional') {
        let value = field.value === 'true' ? true : field.value === 'false' ? false : null;
        formControls[field.key] = new FormControl(value, { updateOn: 'blur' }
        );
      } else if (field.type === 'Number') {
        formControls[field.key] = new FormControl(
          { value: field.value || '', disabled: field.read_only },
          { updateOn: 'blur' }
        );
      } else {
        formControls[field.key] = new FormControl(
          { value: field.value || '', disabled: field.read_only },
          { updateOn: 'blur' }
        );
      }
    });

    this.form = new FormGroup(formControls);
    this.isFormInitialized = true;
  }

  subscribeFormChanges() {
    Object.keys(this.form.controls).forEach((controlName) => {
      const control = this.form.get(controlName);

      control?.statusChanges
        .pipe(
          takeUntil(this.destroy$),
          filter(status => status === 'VALID')
        )
        .subscribe(() => {
          const field = this.data!.isaModelFields!.find(f => f.key === controlName);

          if (field) {
            let changedValue = control?.value;
            let originalValue = field.value;

            if (field.type === 'Number') {
              originalValue = originalValue !== null && originalValue !== undefined ? Number(originalValue) : null;
            } else if (field.type === 'Date') {
              originalValue = originalValue ? new Date(originalValue) : null;
              originalValue = originalValue ? this.formatDate(new Date(originalValue)) : null;
              changedValue = changedValue ? this.formatDate(changedValue) : null;
            } else if (field.type === 'Multiple') {
              originalValue = field.choices || [];
              changedValue = changedValue || [];

              originalValue = originalValue.sort();
              changedValue = changedValue.sort();
            }
            if (JSON.stringify(originalValue) !== JSON.stringify(changedValue)) {
              this.valuesChanged([{
                key: field.key,
                value: changedValue
              }]);
            }
          }
        });
    });
  }

  formatDate(date:any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  async valuesChanged(changedProperties: any[]) {
    this._datasheetService.isaMetaChanged.next(this.data!);

    const properties = changedProperties.map(change => {
      const field = this.data!.isaModelFields!.find(field => field.key === change.key);

      if (change.choices) {
        return {
          key: change.key,
          choices: change.choices
        };
      } else if (field?.type === 'Date') {
        const dateValue = new Date(change.value).toISOString().split('T')[0];
        return {
          key: change.key,
          value: dateValue
        };
      } else if (field?.type === 'Currency') {
        const value = change.value !== null && change.value !== undefined
          ? parseFloat(change.value.toString().replace(',', '.')).toFixed(2)
          : '';

        return {
          key: change.key,
          value: value
        };
      }
        else if (field?.type === 'Conditional') {
        const conditionalValue = change.value === true ? 'true' : change.value === false ? 'false' : null;
        return {
          key: change.key,
          value: conditionalValue
        };
      }else if (field?.type === 'Number') {
        return {
          key: change.key,
          value: change.value !== null && change.value !== undefined
            ? parseFloat(change.value.toString().replace(',', '.')).toString()
            : ''
        };
      } else if( field?.type === 'Multiple' ){
        return {
          key: change.key,
          choices: change.value
        }
      }
      else {
        return {
          key: change.key,
          value: change.value
        };
      }
    });

    try {
      let modelId = this.data.modelId!.toString();
      const response = await this._dataStorage.putIsaFields(modelId, properties);
    } catch (error) {
      console.error(error);
    }
  }



  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  formatCurrency(): string {
    return this._datasheetService.getDatasheet().getCurrencySymbol()
  }

  public clearDate(name: string) {
    this.form.get(name)?.setValue("");
  }

}


