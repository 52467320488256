<div class="dialog_container">

  <div class="dialog_container__header">
    <h2 mat-dialog-title class="dialog_container__header_tittle">{{ "advancedVariantEditor.title" | translate }}</h2>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider style="width: 95%; align-self: center; margin-top: 10px;" ></mat-divider>

  <div mat-dialog-content class="dialog_container__content">
    <p-tree [value]="editedVariation!.itens" selectionMode="single">
      <ng-template let-node pTemplate="default">
         <div class="node-container">
            <span *ngIf="!node.isEditing" (click)="toggleEditMode(node)" class="node-name">{{ node.name }}</span>
            <input *ngIf="node.isEditing" #inputField
            [value]="node.name" (blur)="saveNode(node, inputField.value)"
            (keyup.enter)="saveNode(node, inputField.value)"
            (keydown.space)="$event.stopPropagation()"
            (keydown.arrowRight)="$event.stopPropagation()"
            (keydown.arrowLeft)="$event.stopPropagation()"
            class="node-input" autofocus>
            <span style="margin-right: 15px; margin-top: 4px; display: flex; flex-direction: row;">
               <button mat-menu-item (click)="editNode(node)">
                  <span matTooltip="{{ 'advancedVariantEditor.editVariant' | translate }}" class="material-icons-round">
                  mode_edit_outline
                  </span>
               </button>
               <button mat-menu-item (click)="deleteNode(node)">
                  <span matTooltip="{{ 'advancedVariantEditor.removeVariant' | translate }}" class="material-icons-round">
                  delete_outline
                  </span>
               </button>
               <button mat-menu-item (click)="addChild(node)">
                  <span matTooltip="{{ 'advancedVariantEditor.duplicateVariant' | translate }}" class="material-icons-round">
                     playlist_add
                  </span>
               </button>
            </span>
         </div>
      </ng-template>
   </p-tree>
  </div>

  <div mat-dialog-actions class="dialog_container__actions action_bottom">
    <button-text class="exit" [mat-dialog-close]="true">{{ 'advancedVariantEditor.cancelButton' | translate }}</button-text>
    <button-filled class="save" (click)="saveEdit()">{{ 'advancedVariantEditor.applyButton' | translate }}</button-filled>
  </div>
</div>
