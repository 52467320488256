import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-variation-name',
  templateUrl: './edit-variation-name.component.html',
  styleUrls: ['./edit-variation-name.component.scss']
})
export class EditVariationNameComponent {

  constructor(
    public dialogRef: MatDialogRef<EditVariationNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEnter(): void {
    this.dialogRef.close(this.data.variationName);
  }
}
