<div class="dialog_container">
    <div class="dialog_container__header">
        <h2 mat-dialog-title class="dialog_container__header_tittle"> {{'erp.addRefForm.title' | translate}} </h2>
        <button mat-icon-button [mat-dialog-close]="applyAtLeast1x">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    
    <div mat-dialog-content class="dialog_container_content">
        <ng-template matStepLabel> {{'erp.addRefForm.step1.title' | translate}}</ng-template>

        <div class="stepper_1">

            <div class="stepper_1_searchForm" [formGroup]="searchRefForm">
                <mat-form-field class="styledForm" appearance="outline">
                    <mat-label>{{'erp.addRefForm.step1.category' | translate}}</mat-label>
                    <mat-select formControlName="referenceType" panelClass="model-form__panel isa-med-elevation"
                        disableOptionCentering [(ngModel)]="selectedReferenceType">
                        <mat-option *ngFor="let item of referenceType" [value]="item" (click)="onChangeRefType(item)">
                            {{item | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="styledForm" appearance="outline">
                    <mat-label>{{'erp.addRefForm.step1.name' | translate}}</mat-label>
                    <input matInput formControlName="referenceName" (keydown.enter)="getReferences(1)">
                </mat-form-field>

                <div class="stepper_1_search">
                    <button-filled [color]="'secondary'" [isLoading]="searchRefs" (click)="getReferences(1)">
                        {{'erp.addRefForm.step1.search' | translate}}</button-filled>
                </div>
            </div>

            <span class="stepper_1_content_title" *ngIf="referenceData?.references">{{'erp.addRefForm.step1.selectAReference' | translate}}*</span>

            <div class="stepper_1_content" *ngIf="referenceData?.references">
                <div class="stepper_1_content_item" *ngFor="let item of referenceData?.references! | paginate: { itemsPerPage, currentPage, totalItems }; let i = index"
                    [ngClass]="{ 'selected': selectedIndex === i }" (click)="selectReference(i, item)">
                    <span class="stepper_1_content_item_thumb"> <mat-icon>cloud_download</mat-icon></span>
                    <span class="stepper_1_content_item_name" title="{{ refererenceDetail(item.reference)[0] }}">
                        {{item.reference.fields[0].value}} <br>
                        <i style="color: #12419b96;" >
                            {{ refererenceDetail(item.reference)[1] }}
                        </i>
                    </span>
                </div>

                <div style="text-align: center;" *ngIf="referenceData?.references!.length == 0">
                    <img src="../../../assets/images/notFound.svg">
                    <p class="message">
                        {{ 'alerts.notFound.text' | translate }}
                    </p>
                </div>

            </div>
            <div class="paginator" *ngIf="referenceData?.references!.length > 1">
                <pagination-controls (pageChange)="onPageChange($event)" nextLabel="" previousLabel=""></pagination-controls>
            </div>

            <mat-form-field class="styledForm" *ngIf="referenceData?.references!.length > 1" appearance="outline">
                <mat-label>{{'erp.addRefForm.step2.amount' | translate}}</mat-label>
                <input matInput  [(ngModel)]="referenceAmount">
            </mat-form-field>

            <div class="stepper_1_controls stepper_bottom">
                <div class="left">
                    <mat-checkbox color="primary" [(ngModel)]="keepWindowOpened"></mat-checkbox>
                    <span> {{'erp.addRefForm.step3.keepWindowOpened' | translate}}</span>
                </div>
                <button-filled [isLoading]="isloading" (click)="insertAndRestartClick()"> {{'general.apply' | translate}} </button-filled>
            </div>

        </div>

    </div>