import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageType } from 'src/app/models/enums/message-type';

@Component({
  selector: 'app-mensage',
  templateUrl: './mensage.component.html',
  styleUrls: ['./mensage.component.scss'],
})
export class MensageComponent implements OnInit {
  MessageType = MessageType;
  @Input() isProcessing: boolean = false;

  @Input() messageType!: MessageType;
  @Output() retryClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onRetryClick(): void {
    this.isProcessing = true;
    this.retryClicked.emit();
  }
}
